import {Model} from "./";
import {Api} from "../api";

class SiteModel extends Model {

    constructor() {
        super(new Api("/bitrix-24"));
    }

    postMessageEmail(ob, thenFunction, errorFunction ) {
        this.q.setDopUrl("/live");
        this.q.create({
            name: ob.name,
            phone: ob.phone,
            message: ob.message,
            siteId: ob.siteId,
        },thenFunction, errorFunction);
    }


}

export default new SiteModel();