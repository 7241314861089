import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import 'antd/dist/antd.css';
import {StoreProvider} from './contexts';
import {RootStore} from "./stores";

const RootStoreModel = new RootStore();

ReactDOM.render(
    <React.StrictMode>
        <StoreProvider store={RootStoreModel}>
            <App/>
        </StoreProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals(console.log);
