import {RootStore} from '../index';
import {action, makeAutoObservable} from "mobx"
import {ISectionMenuL1, ISectionMenuL2} from "../../interface/ISection";
import {HelperSvg} from "../../helper/HelperSvg";

interface ISectionLineData {
    code1: string
    code2: string | null
    name: string
}

export class ComCatalogSectionStore {
    private rootStore: RootStore | undefined;

    public arSection = [];
    public arSectionDATA: ISectionLineData[] = [];
    public error: string = '';

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    @action
    setData = (result: any) => {
        if (Array.isArray(result?.data?.rows)) {
            localStorage.setItem("getAllSection",JSON.stringify(result));
            result.data.rows.map((item: ISectionMenuL1) => {
                item.svgSectionLeft = HelperSvg.getSidebarLeft(item.ex_id);
                item.svgSectionTop = HelperSvg.getMenuTop(item.ex_id);
                this.arSectionDATA.push({
                    code1: item.code,
                    code2: null,
                    name: item.name
                });
                item.SectionChild?.map((item2: ISectionMenuL2) => {
                    this.arSectionDATA.push({
                        code1: item.code,
                        code2: item2.code,
                        name: item2.name
                    });
                    return item2;
                });
                return item;
            });
            this.arSection = result.data.rows;
        }
    }

    @action
    setError = (error: any) => {
        console.log(error);
    }

    @action
    getSectionFindInDATA = (code1: string | null, code2: string | null) => {
        for (let item of this.arSectionDATA) {
            if (code1 === item.code1 && code2 === item.code2) {
                return item;
            }
        }
        return null;
    }


}
