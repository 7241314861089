import {RootStore} from './index';
import {makeAutoObservable, action} from "mobx"
import Token from "../helper/Token";
import {EnumUserType} from "../enum/EnumUserType";
import UserModel from "../model/UserModel";
import AuthLoginModel from "../model/AuthLoginModel";
import moment from "moment";

export class MainStore {
    private rootStore: RootStore | undefined;
    templateTp = false;
    isAuth = false;
    user: any;
    clientId: number = 79;
    redirectUrl = '';
    userInfo: any;
    indexPoint = null;
    firstName: any;
    lastName: any;
    secondName: any;
    id: any;
    phone: any;
    userIds: any;
    name: any;
    org_type: any;


    fullNameForOrderInfo: any;
    phoneeForOrderInfo: any;
    producername: any = '';
    discount: any = 0;
    userType: any ='';
    parentId: any = '';
    parentEmail: any = '';
    email: any = '';

    goodMode: string = '';

    TradePoint = {
        value: {
            value: '',
            label: ''
        },
        option: [],
        detail: {}
    };

    showPopupNotIsAuth = false;
    showPopupThankYouApplication = false;
    url = '';

    openConfirmConsent = false
    showPrivacyPolicy = false
    disableConfirmButton = true
    firstConfirmInfo = false
    secondConfirmInfo = false
    dateconfirmpersonaldata = null
    includeUserLoyaltyProgram = false

    loginUser = ""


    scoresAmount = 0
    scoresFrozen = 0
    arrayScoresDate = []
    closeUnfreezeDate = "0001-01-01"
    closeScoresFrozen = 0

    burnDate = []
    loyaltyLevel = ""
    isPopupShowBonus: boolean = false;
    showCookies: boolean = false;

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
        this.init();
    }

    @action
    init() {
        let token = localStorage.getItem("token");
        if (token?.length) {
            let jwt = Token.getTokenData(token);
            if (jwt.exp > Date.now() / 1000) {
                this.isAuth = true;
                this.setDataUser(jwt);
                UserModel.getInfo(this.clientId, this.setInfoUser, this.setError)
            } else {
               //this.rootStore?.authStore.logout();
            }
        }



    }


    @action
    setPopupShowBonus = () => {
        this.isPopupShowBonus = false
        localStorage.setItem("showBonus", "true")
    }

    @action
    checkPopupShowBonus = (org_type: string) => {
        if (localStorage.getItem("showBonus") && localStorage.getItem("showBonus")==="true" && org_type !== "fizlico"){
            this.isPopupShowBonus = false
        }else if (this.isAuth && !localStorage.getItem("showBonus") && org_type === "fizlico") {
            this.isPopupShowBonus = true
        }
    }



    @action
    getLoyaltyUpdateInfo() {
        UserModel.getInfo(this.clientId, this.setLoyaltyUpdateInfo, this.setError)
    }

    setLoyaltyUpdateInfo = (result: any) => {
        this.includeUserLoyaltyProgram = false
        this.scoresAmount = 0
        this.scoresFrozen = 0
        this.closeUnfreezeDate = "0001-01-01"
        this.closeScoresFrozen = 0
        if (result) {
            if (result.data[0].loyalty[0].loyalty_program_report_by_client.loyalty_programs.length){
                this.includeUserLoyaltyProgram = true
                this.scoresAmount =result.data[0].loyalty[0].loyalty_program_report_by_client.scoresAmount
                this.scoresFrozen = result.data[0].loyalty[0].loyalty_program_report_by_client.scoresFrozen
                this.closeUnfreezeDate = result.data[0].loyalty[0].loyalty_program_report_by_client.closeUnfreezeDate
                this.closeScoresFrozen = result.data[0].loyalty[0].loyalty_program_report_by_client.closeScoresFrozen
            }
        }
    }


    @action
    getLoyalty() {
        UserModel.getInfoLoyalty(this.clientId, this.setInfoUserLoyalty, this.setError)
    }


    @action
    setInfoUserLoyalty = (result: any) => {
        this.arrayScoresDate = []
        if (result.data && result.data.row.length){
            this.arrayScoresDate = result.data.row
        }
    }

    setInfoUser = (result: any) => {

        this.includeUserLoyaltyProgram = false
        this.scoresAmount = 0
        this.scoresFrozen = 0
        this.closeUnfreezeDate = "0001-01-01"
        this.closeScoresFrozen = 0

        if (result) {
            this.loginUser = result.data[0].login
            this.org_type = result.data[0].org_type
            this.userInfo = result?.data[0];
            if (result.data[0].loyalty && result.data[0].loyalty[0] && result.data[0].loyalty[0].loyalty_program_report_by_client.loyalty_programs.length){
                this.includeUserLoyaltyProgram = true
                this.scoresAmount =result.data[0].loyalty[0].loyalty_program_report_by_client.scoresAmount
                this.scoresFrozen = result.data[0].loyalty[0].loyalty_program_report_by_client.scoresFrozen
                this.closeUnfreezeDate = result.data[0].loyalty[0].loyalty_program_report_by_client.closeUnfreezeDate
                this.closeScoresFrozen = result.data[0].loyalty[0].loyalty_program_report_by_client.closeScoresFrozen
            }
            // eslint-disable-next-line array-callback-return
            result?.data[0]?.tradepoints.map((item: any) => {
                // @ts-ignore
                this.TradePoint.option.push({   value: item.tradepointid,   label: item.tradepointname,});
            });
            this.setPoint(result?.data[0]?.tradepoints[0]?.tradepointid);
            this.TradePoint.value = {
                // @ts-ignore
                value: result?.data[0]?.tradepoints[0]?.tradepointid,
                // @ts-ignore
                label: result?.data[0]?.tradepoints[0]?.tradepointname,
            };
            this.phone = this.userInfo?.userphone;
            const arName = (this.userInfo?.username).split(" ");
            this.firstName = arName[1];
            this.lastName = arName[0];
            this.secondName = arName[2];
            this.fullNameForOrderInfo = this.lastName + " " + this.firstName + " " + this.secondName ;
            this.phoneeForOrderInfo = this.userInfo?.userphone;


            this.name =  arName[0] + ' ' + arName[1][0] + '. ' + arName[2][0] + '.';
            if(arName[2][0] === undefined){
                this.name =  arName[0] + ' ' + arName[1][0] + '.'  ;
            }
            if(arName[1][0] === undefined){
                // eslint-disable-next-line no-useless-concat
                this.name =  arName[0] + ' ' + '. ' + arName[2][0] + '.';
            }
            if(arName[1][0] === undefined && arName[2][0] === undefined){
                // eslint-disable-next-line no-useless-concat
                this.name =  arName[0] + ' ' + '. ' + '.';
            }


            // localStorage.setItem('setInfoUser', JSON.stringify(result));
            if (this.getUserType() === EnumUserType.ourUser || this.getUserType() === EnumUserType.ourAdmin) {
                this.templateTp = true;
            } else {
                console.log('')
            }
            this.checkPopupShowBonus(this.org_type)

        }
        if (result?.data[0]?.dateconfirmpersonaldata === null){
            this.openConfirmConsent = true
        }else {
            this.dateconfirmpersonaldata = result?.data[0]?.dateconfirmpersonaldata
        }

        if (result?.data[0]?.dateconfirmcookies === null){
            this.showCookies = true
        }else {
            this.showCookies = false
        }

    }


    private setPoint = (value: string) => {

        this.TradePoint.detail = this.userInfo.tradepoints.filter((item: any) => item.tradepointid === value);
    }

    @action
    setDataUser(jwt: any) {
        this.userType = jwt.type;
        if (jwt.parentId){
            this.parentId = jwt.parentId;
        }else {
            this.parentId =''
        }
        this.parentEmail = jwt.parentEmail;
        this.email = jwt.email;
        this.clientId = jwt.clientId;
        this.id = jwt.id;


    }

    decodeBase64(str: string) {
        return decodeURIComponent(escape(window.atob(str)));
    }

    getClientId(){
        return this.clientId;
    }
    getUserId(){
        return this.id;
    }
    getDateConfirmPersonalData(){
        return this.dateconfirmpersonaldata;
    }
    setOpenConfirmConsent(){
        return this.openConfirmConsent = true
    }

    getUserType() {

        if (this.userType) {
            return this.userType;
        }
        return EnumUserType.clientUser;
    }

    @action
    setClients = (result: any) => {

        if (result.data && result.data.discountsByProducer && result.data.discountsByProducer.length){
            this.discount = result.data.discountsByProducer[0]?.discount;
            this.producername = result.data.discountsByProducer[0].producername;
        }
    }


    @action
    templateTpActive() {
        this.templateTp = true;
    }

    @action
    templateTpDeactivate() {
        this.templateTp = false;
    }

    @action
    templateTpToggle() {
        if (this.templateTp) {
            this.templateTpDeactivate()
        } else {
            this.templateTpActive()
        }
    }

    @action
    setUrl(url:string){
        this.url = url;
// this.rootStore?.appStore.closeMenuAction()
    }



    @action
    getUrl(){
        return this.url
    }

    @action
    getParamUrl (){
        var params = window
            .location
            .search
            .replace('?','')
            .split('&')
            .reduce(
                function(p,e){
                    var a = e.split('=');
                    // @ts-ignore
                    p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
                    return p;
                },
                {}
            );

        return params
    }

    @action
    popupNotIsAuth(){
        this.setShowPopupNotIsAuth(false);
        setTimeout(function (){
            let NotIsAuth = document.getElementById('popupNotIsAuth');
            // @ts-ignore
            NotIsAuth.click()
        } , 10);
    }

    @action
    getParamsUrl(){
        let get_param = {};
        let params = window.location.search;
        params = params.substring(1);
        let arParams = this.explode('&', params);
        for(let key in arParams){
            let item = this.explode('=', arParams[key]);
            // @ts-ignore
            get_param[item[0]] = item[1];
        }
        return get_param;
    }

    @action
    explode( del:string, str:string )
    {
        return str.split(del);
    }

    @action
    setShowPopupNotIsAuth(bul:boolean)
    {
        this.showPopupNotIsAuth = bul;
    }
    @action
    setShowPopupThankYouApplication(bul:boolean)
    {
        this.showPopupThankYouApplication = bul;
    }

    @action
    setRedirectUrl = (url: string) => {
        this.redirectUrl = url;
    }

    @action
    setError = (error: any) => {
        console.log(error)
    }

    @action
    onLoginRoot = (id: number) => {
        if (window.confirm('авторизоваться под пользователем?')) {
            this.removeData();
            AuthLoginModel.getTokenRoot(
                id,
                this.removeData2,
                this.setError
            );

        }
    }

    @action
    removeData() {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("setInfoUser");
        localStorage.removeItem("getAllSection");
        localStorage.removeItem("activeClient");
        localStorage.removeItem("beautyId");
    }
    @action
    removeData2(result: any) {

        if (result){
            localStorage.setItem("token", result.data.token);
            localStorage.setItem("refreshToken", result.data.refreshToken);
            // @ts-ignore
            window.history.pushState(null, null, '/catalog');
            window.location.reload()
        }

    }
    @action changeGoodMode = () => {
        if (this.goodMode === 'DEV') {
            this.goodMode = 'PROD';
            localStorage.setItem('goodMode', 'PROD');
        } else {
            this.goodMode = 'DEV';
            localStorage.setItem('goodMode', 'DEV');
        }
    }


    @action handleCloseConfirmConsent = () => this.openConfirmConsent = false;
    @action handleCloseShowPrivacyPolicy = () => this.showPrivacyPolicy = false;
    @action handleOpenShowPrivacyPolicy = () => this.showPrivacyPolicy = true;
    @action handleChangeConfirmInfo = (item: string) => {
        if (item === "firstConfirm"){
            this.firstConfirmInfo = !this.firstConfirmInfo
        }else {
            this.secondConfirmInfo = !this.secondConfirmInfo
        }
        this.checkConfirm(this.firstConfirmInfo, this.secondConfirmInfo )
    }
    @action checkConfirm = (firstConfirm: boolean , secondConfirm: boolean) =>{
        if (firstConfirm && secondConfirm){
            this.disableConfirmButton = false
        }else {
            this.disableConfirmButton = true
        }
    }
    @action updateConfirmInfo = () =>{

        let date = moment(Date()).utc().format("YYYY-MM-DD HH:mm")
        let ob = {dateConfirmPersonalData: date}
        UserModel.update(this.id, ob,this.setUpdateConfirmInfo, this.setError)
        this.openConfirmConsent = false
    }
    @action updateCookies = () =>{
        let date = moment(Date()).utc().format("YYYY-MM-DD HH:mm")
        let ob = {dateConfirmCookies: date}
        UserModel.update(this.id, ob,this.setUpdateConfirmInfo, this.setError)
    }
    @action removeConfirmInfo = () =>{
        let ob = {dateConfirmPersonalData: null}
        UserModel.update(this.id, ob,this.setUpdateConfirmInfo, this.setError)
        this.openConfirmConsent = true
    }
    @action setUpdateConfirmInfo = (result: any) =>{
            this.init()
    }


}
