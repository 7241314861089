import {action, makeAutoObservable} from "mobx"
import {RootStore} from "../index";
import UserModel from "../../model/UserModel";
import LogModel from "../../model/LogModel";
import MessageViberModel from "../../model/MessageViberModel";

export class PageAdminAuthStore {
    private rootStore: RootStore | undefined;

    public arUsers = [];
    public arLog = [];
    public pagination = {
        countPage: 1,
        countRow: 1,
        currentPage: 1
    }
    public limitRow = 10;
    public search = '';
    public searchLog = '';
    public error: string = '';
    public arrMessageViber: any = []

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    init = () => {
        this.arUsers = [];
        this.reload();
    }

    @action
    reload() {
        UserModel.getAdminAll(this.setData, this.setError);
    }

    @action
    getLog() {
        LogModel.getAll(this.getModelData(),this.setLogData, this.setError);
    }

    @action
    getAllMessageViber() {
        MessageViberModel.getAllMessageViber(this.setMessageData, this.setError);
    }

    @action
    setMessageData = (result: any) => {
        this.arrMessageViber = result.data
        this.arrMessageViber.sort((a: any,b: any)=> Number(a.id) - Number(b.id))
    }

    @action
    onChangeArrayDataMessage = (id: string, value: string, position: string) => {
        if (this.arrMessageViber && this.arrMessageViber.length > 0){
            for (let i=0; i< this.arrMessageViber.length; i++){
                if (this.arrMessageViber[i].id === id ){
                    this.arrMessageViber[i].json[position] = value
                }
            }
        }
    }

    @action
    saveDataArrayMessage = (id: string) => {
        if (this.arrMessageViber && this.arrMessageViber.length > 0){
            for (let i=0; i< this.arrMessageViber.length; i++){
                if (this.arrMessageViber[i].id === id ){
                    MessageViberModel.updateMessageViber(Number(id), this.arrMessageViber[i],  this.setSaveResultAfterSave, this.setError)
                }
            }
        }
    }

    @action
    setSaveResultAfterSave = (result: any) => {
        this.getAllMessageViber()
        alert("Изменения сохранены")
    }

    getModelData() {
        return {
            json: {
                limit: this.limitRow,
                filter: {},
            },
            page: this.pagination.currentPage
        };
    }

    @action
    setData = (result: any) => {
        console.log(result)
        result.data.row.map((item: any) => {
            item.updatedAt = new Date(item.updatedAt);
            return item;
        });
        this.arUsers = result.data.row;
    }

    @action
    setLogData = (result: any) => {
        this.pagination = {
            countPage: 1,
            countRow: 1,
            currentPage: 1
        }
        this.arLog = [];
        this.pagination = result.data.pagination
        this.arLog = result.data.rows
    }
    onCurrentPage = (page: number) => {
        this.pagination.currentPage = page;
        this.getLog();
    }

    @action setError = (error: any) => {
        alert("Произошла ошибка...")
        console.log(error);
    }

    @action onFilterUser = (e: any) => {
        this.search = e.target.value;
    }
    @action onFilterLog = (e: any) => {
        this.searchLog = e.target.value;
    }

}
