import React from 'react';
import {withStore} from "../../../hocs";
import {observer} from "mobx-react";

interface Props {
    store: any,
    itemFilter:any
    filterActive: any
    onFilterChecked: any
    onBrandChecked: any
    onChecks: any
}

// @ts-ignore
@withStore
@observer
export class FilterItemBrend extends React.Component<Props, {}> {

    render() {

        const {itemFilter, filterActive, onFilterChecked, onBrandChecked, onChecks}=this.props;

        // @ts-ignore
        return (
            <>
                <li className="filter__list-item" key={'filter-brend'}>

                    <button className={filterActive['brand'] ? 'filter__list-title js--accordion-toggle is-active' : 'filter__list-title js--accordion-toggle'}
                            onClick={() => onFilterChecked('brand')}
                            data-filter_but_cpde={'brand'}
                            type="button">Бренд
                        <svg className="filter__list-icon" width="7" height="12">
                            <use xlinkHref="/images/sprite.svg#icon--arrow-left" />
                        </svg>
                    </button>

                    <div data-filter_cont_cpde={'brand'}
                         className={filterActive['brand'] ? 'filter__list-content js--accordion-content is-open' : 'filter__list-content js--accordion-content'}
                    >
                        <ul className="filter__options">
                            {itemFilter.map((e: any, index: any) => (
                                <div key={index}>
                                    <li className="filter__options-item"
                                        onClick={() => onBrandChecked(e.id, 'brand')}
                                        key={e.id}>
                                        <input className="visually-hidden"
                                               type="checkbox"
                                               defaultValue = {e.id}
                                               readOnly
                                               checked={onChecks.includes(e.id)}
                                               onChange={() => {}} // Добавлен пустой обработчик для избежания предупреждения
                                               name={'brend-'+e.id}
                                        />
                                        <label key={e.id + 6666}
                                               className="filter__options-label checkbox-field"
                                               htmlFor={'opt-'+e.id}
                                        >
                                            {e.value}
                                        </label>
                                    </li>
                                </div>
                            ))}
                        </ul>
                    </div>
                </li>
            </>
        );
    }
}
