import React from 'react';
import {Link} from 'react-router-dom';
import Dialog from '@mui/material/Dialog';

interface Props {
    store: any
    isPopupShowBonus: boolean

}

export class BannerLoyalty extends React.Component<Props, {}> {
    render() {
        const {store} = this.props;
        const {isPopupShowBonus, setPopupShowBonus} = store.mainStore
        let open = isPopupShowBonus
        const handleClose = () => {
            open = false
            setPopupShowBonus()
        };

        return (
            <>
                <Dialog onClose={handleClose} open={isPopupShowBonus}>
                    <div className="modal__close js--modal-close" onClick={() => setPopupShowBonus()}>
                        <span className="visually-hidden">Закрыть модальное окно</span>
                        <span className="modal__close-icon" />
                    </div>
                    <a
                        href="/loyalty-program"
                        onClick={() => setPopupShowBonus()}>
                        <img src="/images/bonusnye.jpg"/>
                    </a>
                </Dialog>
                {/*<div className={isPopupShowBonus ? "modal modal--success is-open" : "modal modal--success"}  data-modal="isModalOpen">*/}
                {/*      */}

                    {/*<div className="modal__close js--modal-close" onClick={() => setPopupShowBonus()}>*/}
                    {/*    <span className="visually-hidden">Закрыть модальное окно</span>*/}
                    {/*    <span className="modal__close-icon" />*/}
                    {/*</div>*/}
                {/*</div>*/}
            </>
        )
    }
}
