import {RootStore} from './index';
import {makeAutoObservable, action} from "mobx"
import BlockPropertyModel from "../model/BlockPropertyModel";
export const YOUR_TRACKING_ID = "G-JZ11RM5WH9";

export class AppStore {
    private rootStore: RootStore | undefined;
    menuHeader = [];
    menuFooter = [];
    menuPersonal = [];
    arFilterCatalogEmun = [];
    arFilter: any = {};
    listMenuCatalogNew: any = [];
    listMenuCatalogNewForMobile: any = [];
    listMenuCatalog: any = [];
    selectedValueId: any = '';
    selectedName: any = ''
    onChecks: number[] = [];
    filterActive: any = {};
    activeFilterId: any;
    typeId: number = 0;
    sideId: any = 4;

    isOpenMenuCatalog = false;
    isPopupAuthorization = false;
    isPopupSendOrder = false;
    isPopupPasswordRecovery = false;
    isPopupAccessDenied = false;
    isPopupThankYouApplication = false;
    isPopupAddDeliveryAddress = false;
    isPopupAddDeliveryAddressSuccessful = false;
    isPopupThankYouSendOrder = false;
    isPopupExistCount = false;
    isPopupSubmitRemove = false;
    isPopupSubmitRemoveFavorite = false;

    closeMenu: boolean = false;
    isOpenSubMenuCatalog: boolean = false;
    showSkeleton: boolean = false
    newObj: any = []



    showArray = []

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    init() {
        if (this.rootStore?.mainStore.isAuth) {
            let clientId = this.rootStore?.mainStore.getClientId()
            BlockPropertyModel.getBrandFromDB(clientId, this.setInfoBrands, this.setErrorFilter);
        }
        // @ts-ignore
        this.menuHeader = this.getMenuHeader();
        // @ts-ignore
        this.menuFooter = this.getMenuFooter();
        // @ts-ignore
        this.menuPersonal = this.getPersonalMenu();
    }


    @action
    setInfoBrands = (result: any) => {
        this.newObj = result.data
        for (let i=0; i< this.newObj.length; i++){
            for (let a =0; a < this.newObj[i].data.length; a++){
                if (this.newObj[i].data[a].code === "brand"){
                    const fromIndex = a; // индекс перемещаемого элемента
                    this.newObj[i].data.push(this.newObj[i].data[a]); // добавляем элемент с нужным индексом в конец массива
                    this.newObj[i].data = this.newObj[i].data.filter((num: any, index: any) => index !== fromIndex);
                }
                if (this.newObj[i].data[a].code === "pallet"){
                    const fromIndex = a; // индекс перемещаемого элемента
                    this.newObj[i].data.splice(0, 0, this.newObj[i].data.splice(fromIndex, 1)[0]);
                }
            }
        }
    }

    @action
    setCatalogFilterForBeautyHouseCategory = () => {
        this.showSkeleton =true
    }

    @action setError = (error: any) => {
        console.log(error);
    }

    @action
    setErrorFilter = (error: any) => {
        console.log(error);
    }

    @action
    setPopupSendOrder(t: boolean) {
        this.isPopupSendOrder = t;
    }

    closeMenuAction = () => {
        this.closeMenu = !this.closeMenu;
    }

    @action
    setPopupAddDeliveryAddressSuccessful(t: boolean) {
        this.isPopupAddDeliveryAddressSuccessful = t;
    }

    @action
    setPopupAddDeliveryAddress(t: boolean) {
        this.isPopupAddDeliveryAddress = t;
    }

    @action
    setPopupAuthorization=(t: boolean)=> {
        this.isPopupAuthorization = t;
    }

    @action
    setPopupPasswordRecovery(t: boolean) {
        this.isPopupPasswordRecovery = t;
    }

    @action
    setPopupAccessDenied=(t: boolean)=> {
        this.isPopupAccessDenied = t;
    }

    @action
    setPopupThankYouApplication(t: boolean) {
        this.isPopupThankYouApplication = t;
    }

    @action
    setPopupThankYouSendOrder(t: boolean) {
        this.isPopupThankYouSendOrder = t;
    }

    @action
    setPopupExistCount(t: boolean) {
        this.isPopupExistCount = t;
    }

    @action
    setPopupSubmitRemove(t: boolean) {
        this.isPopupSubmitRemove = t;
    }

    @action
    setPopupSubmitRemoveFavorite(t: boolean) {
        this.isPopupSubmitRemoveFavorite = t;
    }

    @action
    setOpenMenuCatalog(t: boolean) {
        this.rootStore?.componentFilterCatalogStore.removeUrl()
        if (this.isOpenMenuCatalog === true) {
            this.isOpenMenuCatalog = false;
        } else {
            if (this.newObj.length && this.newObj[0]){
                let firstShow = this.newObj[0]
                this.getFilterSection(firstShow.sectionId, 'beautyHouseCategory')
            }
            this.isOpenMenuCatalog = true;
        }
    }

    @action
    setOpenSubMenuCatalog = (name: any) => {
        if (this.selectedName !== name) {
            this.isOpenSubMenuCatalog = false
        }
        if (this.isOpenSubMenuCatalog === true) {
            this.isOpenSubMenuCatalog = false
        } else {
            this.isOpenSubMenuCatalog = true
        }
        for (let i=0; i< this.listMenuCatalogNew.length; i++){
            if (name === this.listMenuCatalogNew[i].name){
                this.listMenuCatalogNew[i].show = !this.listMenuCatalogNew[i].show
            }
        }
        this.showArray = this.listMenuCatalogNew
    }

    @action
    geOpenMenuCatalogt() {
        return this.isOpenMenuCatalog;
    }

    @action
    getFilterSection = (id: number, code: string) => {
        this.listMenuCatalogNew = []
            for (let i = 0; i < this.newObj.length; i++){
                if (this.newObj[i].sectionId === id){
                    this.listMenuCatalogNew= this.newObj[i].data
            }}

        this.selectedValueId = id
        this.rootStore?.componentFilterCatalogStore.setUrlPage1(id, code)
        this.filterActive = {};
        this.filterActive[id] = true;
    }

    @action
    setFilterSectionSub = (result: any) => {
        console.log(result)
    }

    @action
    setErrorSection = (error: any) => {
        console.log(error);
    }


    setFilter() {
        return {"props": this.arFilter}
    }

    @action
    getMenuHeader() {
        return [
            {
                id: 0,
                name: "Каталог",
                url: "/catalog",
                type: "top",
                enum: true,
            },
            {
                id: 1,
                name: "Главная",
                url: "/",
                type: "top",
            },
            {
                id: 2,
                name: "Бренды",
                url: "/brands",
                type: "top",
            },
            {
                id: 3,
                name: "Палитры",
                url: "/pallet",
                type: "top",
            },
            // {
            //     id: 3,
            //     name: "Акции",
            //     url: "/sale",
            //     type: "top",
            // },
            // {
            //     id: 7,
            //     name: "Бонусы",
            //     url: "/catalog/filter/isIncludeLoyaltyProgram-is-true?1",
            //     type: "top",
            // },
            {
                id: 4,
                name: "Сотрудничество",
                url: "/cooperation",
                type: "top",
            },
            {
                id: 5,
                name: "Обучение",
                url: "/training",
                type: "top",
            },
            {
                id: 6,
                name: "О нас",
                url: "/about",
                type: "top",
            },
        ];
    }

    @action
    getMenuFooter() {
        return [
            // ---------------------company--------------------------
            {
                id: 1,
                name: "О нас",
                url: "/about",
                type: "company",
            },
            // {
            //     id: 2,
            //     name: "Новости",
            //     url: "/news",
            //     type: "company",
            // },
            {
                id: 3,
                name: "Сотрудничество",
                url: "/cooperation",
                type: "company",
            },
            {
                id: 4,
                name: "Контакты",
                url: "/contacts",
                type: "company",
            },
            {
                id: 8,
                name: "Доставка",
                url: "/delivery",
                type: "service",
            },
            {
                id: 9,
                name: "Способы оплаты",
                url: "/payment",
                type: "service",
            },
            {
                id: 10,
                name: "Возврат",
                url: "/refund",
                type: "service",
            },
            // ---------------------information--------------------------
            {
                id: 11,
                name: "Каталог",
                url: "/catalog",
                type: "information",
            },
            {
                id: 12,
                name: "Бренды",
                url: "/brands",
                type: "information",
            },
            {
                id: 13,
                name: "Новинки",
                url: "/catalog/filter/isNovelty-is-true",
                type: "information",
            },
            {
                id: 14,
                name: "Прайс-лист",
                url: "/personal/price-list",
                type: "information",
            },
            {
                id: 15,
                name: "Как оформить заказ",
                url: "/how-order",
                type: "service",
            },
            {
                id: 16,
                name: "Политика обработки персональных данных ",
                url: "/privacy-policy",
                type: "policy",
            },
            {
                id: 17,
                name: "Политика обработки персональных данных ",
                url: "/privacy-policy",
                type: "information",
            },
            {
                id: 18,
                name: "Программа лояльности",
                url: "/loyalty-program",
                type: "information",
            },
        ];
    }

    @action
    getPersonalMenu() {
        return [
            {
                id: 1,
                name: "Личные данные",
                url: "/personal/data",
                type: "personal",
            },
            {
                id: 2,
                name: "История заказов",
                url: "/personal/order-history",
                type: "personal",
            },
            {
                id: 3,
                name: "Адреса доставки",
                url: "/personal/delivery-address",
                type: "personal",
            },
            {
                id: 4,
                name: "Договоры",
                url: "/personal/contracts",
                type: "personal",
            },
            {
                id: 5,
                name: "Прайс-лист",
                url: "/personal/price-list",
                type: "personal",
            },
            {
                id: 6,
                name: "Акции",
                url: "/personal/sale",
                type: "personal",
            },
            {
                id: 7,
                name: "Программа лояльности",
                url: "/personal/loyalty",
                type: "personal",
            },
            {
                id: 8,
                name: "Сообщения",
                url: "/personal/news",
                type: "personal",
            },


        ];

    }
}
