import {RootStore} from './index';
import {makeAutoObservable, action} from "mobx"
import AuthLoginModel from "../model/AuthLoginModel";

export class AuthStore {
    private rootStore: RootStore | undefined;

    public email: string = '';
    public password: string = '';
    public error: string = '';

    public emailRecovery: string = '';
    public errorRecovery: string = '';
    public messageRecovery: string = '';
    public okRecovery: boolean = false;

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;

        if (window.location.host === "localhost:3000") {
            this.email = 'live77sport@gmail.com';
            this.password = '123456789';
        }

    }

    @action
    logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("permission");
        localStorage.removeItem("baseUrl");
        localStorage.removeItem("index");
        localStorage.removeItem("prevViewProduct");
        window.location.href = "/";
    }

    @action
    onSubmitFormLogin = (e: { preventDefault: () => void; }) => {
        e.preventDefault()
        AuthLoginModel.getToken(
            {
                siteId:4,
                email: this.email,
                password: this.password
            },
            this.setIndex,
            this.setError
        );

        return false;
    }

    @action
    setError = (error: any) => {

        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            error.response.data.massage !== undefined
        ) {
            this.error = String(error.response.data.massage);
        } else if (String(error).indexOf("401")) {
            this.error = "Неверный Email или Пароль";
        }else {
            this.error = String(error);
        }
    }


    @action
    setIndex = (result: any) => {

        this.error = '';
        localStorage.setItem("token", result.data.token);
        localStorage.setItem("refreshToken", result.data.refreshToken);
        localStorage.setItem('index', '0')

        if (window.history.length > 2){
            window.history.back()
        }else {
            window.location.href = '/';
        }
        window.location.reload()
    }

    @action
    onChangePassword = (e: { target: { value: string; }; }) => {
        this.password = e.target.value;
    }


    @action
    onChangeEmailRecovery = (e: { target: { value: string; }; }) => {
        if (e.target.value ==="+375 (__) ___-__-__"){
            this.emailRecovery = ''
        }else {
            this.emailRecovery = e.target.value;
        }
    }

    @action
    onSubmitFormRecovery = () => {
        AuthLoginModel.getRecovery(
            {
                siteId:4,
                email: this.emailRecovery,
            },
            this.setRecovery,
            this.setErrorRecovery
        );
    }

    @action
    SetOkRecovery=(t: boolean) =>{

        if(!t){
            setTimeout(function (){
                let authorization = document.getElementById('authorization');
                // @ts-ignore
                authorization.classList.remove("is-open");
            } , 10);
        }

        // @ts-ignore
        this.rootStore.appStore.isPopupAuthorization = false;
        // @ts-ignore
        this.rootStore.appStore.isPopupPasswordRecovery = true;

        this.okRecovery = t;
    }

    @action
    setRecovery = (result: any) => {
        this.errorRecovery = '';
        // @ts-ignore
        this.okRecovery = true;
    }

    @action
    setErrorRecovery = (error: any) => {
        this.errorRecovery = String(error.response.data.message);
    }

    @action
    onChangeEmail = (e: { target: { value: string; }; }) => {
        this.email = e.target.value;
    }



}
