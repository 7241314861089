import {Model} from "./";
import {Api} from "../api";

class BHContentModel extends Model {

    constructor() {
        super(new Api("/bh-content"));
    }

    getPallet(data, thenFunction, errorFunction) {
        this.q.setDopUrl('/palette/');
        this.q.read(data, thenFunction, errorFunction);
    }
    getPalletPage({}, thenFunction, errorFunction) {
        this.q.setDopUrl('/palette-page/');
        this.q.read({}, thenFunction, errorFunction);
    }

}

export default new BHContentModel();
