
import {Model} from "./";
import {Api} from "../api";

class BlockPropertyModel extends Model {

    constructor() {
        super(new Api("/property"));
    }

    getProperty(id, thenFunction, errorFunction) {
        this.q.setDopUrl('/' + id);
        this.q.read({}, thenFunction, errorFunction);
    }

    getPropertyCatalogFilterCode(code, data, thenFunction, errorFunction) {
        this.q.setDopUrl('/filter/catalog/' + code);
        this.q.read(data, thenFunction, errorFunction);
    }

    getFilter(data, thenFunction, errorFunction) {
        this.q.setDopUrl('/filter/catalog');
        this.q.read(data, thenFunction, errorFunction);
    }
    getAllSubFilter(code, thenFunction, errorFunction) {

        this.q.setDopUrl('/filter/all/' + code);
        this.q.read( thenFunction, errorFunction);
    }
    getBrand(filter, thenFunction, errorFunction) {
        this.getPropertyCatalogFilterCode('brand', {json: filter}, thenFunction, errorFunction);
    }
    getBrandFromDB(id, thenFunction, errorFunction) {
        this.q.setDopUrl('/brands-for-user/' + id);
        this.q.read({} ,thenFunction, errorFunction);
    }

    getItemType(filter, thenFunction, errorFunction) {
        this.getPropertyCatalogFilterCode('itemType', {json: filter}, thenFunction, errorFunction);
    }
    getPalletImg(thenFunction, errorFunction) {
        this.q.setDopUrl('/list/pallet-img');
        //this.q.read( thenFunction, errorFunction);
    } 

}

export default new BlockPropertyModel();



