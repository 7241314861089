import {RootStore} from './index';
import {makeAutoObservable} from "mobx"

export class UserStore {
    private rootStore: RootStore | undefined;
    public user: {};

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
        this.user = {};
        this.init();
    }

    init() {
        if (localStorage.getItem('users') !== null) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            this.user = JSON.parse(<string>localStorage.getItem('users'));
        }
    }

}

