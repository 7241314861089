import {RootStore} from '../index';
import {action, makeAutoObservable} from "mobx";
import ContentModel from "../../model/ContentModel";


export class BrandsStore {

    rowsBrands = [];
    singlesBrand = [
        {
            "id": 50055,
            "name": "MAJIREL GLOW L`Oreal Professionnel",
            "img": "https://charme-shop.ru/upload/iblock/00c/00c22b0d7272d06fcd489fa5b90e3109.jpg",
            "text": "Новое поколение прохладных полупрозрачных оттенков Форма выпуска: крем-краска",
            "linkId": "11538"
        },
        {
            "id": 50056,
            "name": "MAJIREL L`Oreal Professionnel",
            "img": "https://charme-shop.ru/upload/iblock/da0/da061d2e0d8d6c6137ed08cd0f107dfc.jpg",
            "text": "Осветление до 3х уровней тона 100% покрытия седины Форма выпуска: крем-краска, оксидант",
            "linkId": "11538"
        },
        {
            "id": 50056,
            "name": "MAJIREL ULTRA L`Oreal Professionnel",
            "img": "https://charme-shop.ru/upload/iblock/c06/c066078f48509c30aa96127b8e5ecb16.jpg",
            "text": "Для чистых холодных или сияющих теплых оттенков блон Форма выпуска:крем-краска",
            "linkId": "11538"
        },
        {
            "id": 50056,
            "name": "MAJIREL ULTRA L`Oreal Professionnel",
            "img": "https://charme-shop.ru/upload/iblock/c06/c066078f48509c30aa96127b8e5ecb16.jpg",
            "text": "Для чистых холодных или сияющих теплых оттенков блон Форма выпуска:крем-краска",
            "linkId": "11538"
        },
        {
            "id": 50056,
            "name": "MAJIREL ULTRA L`Oreal Professionnel",
            "img": "https://charme-shop.ru/upload/iblock/c06/c066078f48509c30aa96127b8e5ecb16.jpg",
            "text": "Для чистых холодных или сияющих теплых оттенков блон Форма выпуска:крем-краска",
            "linkId": "11538"
        },
        {
            "id": 50056,
            "name": "MAJIREL ULTRA L`Oreal Professionnel",
            "img": "https://charme-shop.ru/upload/iblock/c06/c066078f48509c30aa96127b8e5ecb16.jpg",
            "text": "Для чистых холодных или сияющих теплых оттенков блон Форма выпуска:крем-краска",
            "linkId": "11538"
        }
    ];

    singlesBrandAfterSort = []

    private rootStore: RootStore | undefined;

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    init() {
        ContentModel.getBrandsFromAdmin(this.setData, this.setError)
    }

    @action
    setData = (result: any) => {
        if (result.data) {
            this.rowsBrands = result.data.data
        }
    }

    @action
    setError = (error: any) => {
        console.log(error);
    }

    setDetailBrand(id: number) {
        this.init()
        ContentModel.getDetailBrands(id, this.setDataDetail, this.setError)
    }

    @action
    setDataDetail = (result: any) => {
        this.singlesBrandAfterSort = []
        if (result.data) {
            this.singlesBrand = result.data.data
            this.singlesBrandAfterSort = result.data.data.link
        }
    }

}
