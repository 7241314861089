import React from 'react';
import {withStore} from "../../../hocs";
import {observer} from "mobx-react";
import {FilterItem} from "./FilterItem";
import {FilterItemBrend} from "./FilterItemBrend";
import {FilterItemDop} from "./FilterItemDop";
import {SearchTopForm} from "../../block/SearchTopForm";
//import {FilterBrandAlphabet} from "./FilterBrandAlphabet";
import {WIDTH} from "../../../const/root";

// @ts-ignore
@withStore
@observer
export class FilterCatalog extends React.Component {


    componentDidMount() {
    }

    render() {
        // @ts-ignore
        const {store} = this.props;
        let {
            arBrand,
            listFilters,
            onFilterChecked,
            onBrandChecked,
            onChecks,
            resetFilter,
            listFilterCategory,
            listFiltersHairType,
            listFiltersSubLine,
            listFiltersLineGamma,
            showFilterMobile,
            showFilterDestop,
            filterActive,
        } = store?.componentFilterCatalogStore;
        let {
          includeUserLoyaltyProgram
        } = store?.mainStore;

        let Width = window.innerWidth

        return (
            <>
                <aside className="page__aside">
                    <div className="filter">
                        <button style={Width < WIDTH ? { width: 110, fontSize: 16, left: 110,marginTop: 30, height: 40}: {}} className={showFilterMobile || showFilterDestop ? 'filter__toggle button button--accent-dark js--show-filter is-open' : 'filter__toggle button button--accent-dark js--show-filter'}
                                onClick={() => store.componentFilterCatalogStore.showFilter()}
                                type="button">Фильтр
                            <svg className="filter__toggle-icon" width="24" height="19">
                                <use xlinkHref="/images/sprite.svg#icon--filter" />
                            </svg>
                        </button>
                        <div className={showFilterMobile || showFilterDestop ? "filter__wrapper js--filter-wrapper js--show-filter is-open " : "filter__wrapper js--filter-wrapper"}>
                            <button className="filter__wrapper-close js--filter-close" type="button"
                                    onClick={() => store.componentFilterCatalogStore.closeFilter()}
                            ><span
                                className="visually-hidden">Закрыть фильтр</span>

                                <span className="filter__wrapper-close-icon" />
                            </button>
                            <h2 className="filter__title">Фильтр <span className="filter__title-last-words">по параметрам</span>
                                <svg className="filter__title-icon" width="24" height="19">
                                    <use xlinkHref="images/sprite.svg#icon--filter" />
                                </svg>
                            </h2>


                            {/*<div className="search filter__search">*/}
                            {/*    <SearchTopForm/>*/}
                            {/*</div>*/}

                            <SearchTopForm  />


                            <div className="filter__body  js--show-filter is-open">
                                <ul className="filter__list js--accordion">

                                    {/*категория*/}

                                    {listFilterCategory.map((itemFilter: any, index: any) => (
                                        <>
                                            <FilterItem
                                                key={index + 1}
                                                store = {store}
                                                itemFilter = {itemFilter}
                                                filterActive = {filterActive}
                                                onFilterChecked ={onFilterChecked}
                                                onChecks={onChecks}
                                                onBrandChecked={onBrandChecked}
                                                type={"filter"}
                                            />
                                        </>
                                    ))}


                                    {/*наши предложения*/}



                                    <FilterItemDop
                                        store = {store}
                                        itemFilter = {arBrand}
                                        filterActive = {filterActive}
                                        onFilterChecked={onFilterChecked}
                                        onChecks={onChecks}
                                        onBrandChecked={onBrandChecked}
                                        includeUserLoyaltyProgram={includeUserLoyaltyProgram}
                                    />

                                    {/*бренды*/}

                                    <FilterItemBrend
                                        store = {store}
                                        itemFilter = {arBrand}
                                        filterActive = {filterActive}
                                        onFilterChecked={onFilterChecked}
                                        onChecks={onChecks}
                                        onBrandChecked={onBrandChecked}
                                    />

                                    {/*линия бренда*/}

                                    {listFiltersLineGamma.map((itemFilter: any, index: any) => (
                                        <>
                                            <FilterItem
                                                key={index}
                                                store = {store}
                                                itemFilter = {itemFilter}
                                                filterActive = {filterActive}
                                                onFilterChecked ={onFilterChecked}
                                                onChecks={onChecks}
                                                onBrandChecked={onBrandChecked}
                                                type={"filter"}
                                            />
                                        </>
                                    ))}

                                    {/*подлиния*/}

                                    {listFiltersSubLine.map((itemFilter: any, index: any) => (
                                        <>
                                            <FilterItem
                                                key={index}
                                                store = {store}
                                                itemFilter = {itemFilter}
                                                filterActive = {filterActive}
                                                onFilterChecked ={onFilterChecked}
                                                onChecks={onChecks}
                                                onBrandChecked={onBrandChecked}
                                                type={"filter"}
                                            />
                                        </>
                                    ))}


                                    {/* палитра , тип продукта , тип волос*/}

                                    {listFilters.map((itemFilter: any, index: any) => (
                                        <>
                                            <FilterItem
                                                key={index}
                                                store = {store}
                                                itemFilter = {itemFilter}
                                                filterActive = {filterActive}
                                                onFilterChecked ={onFilterChecked}
                                                onChecks={onChecks}
                                                onBrandChecked={onBrandChecked}
                                                type={"filter"}
                                            />
                                        </>
                                    ))}

                                    {listFiltersHairType.map((itemFilter: any, index: any) => (
                                        <>
                                            <FilterItem
                                                key={index}
                                                store = {store}
                                                itemFilter = {itemFilter}
                                                filterActive = {filterActive}
                                                onFilterChecked ={onFilterChecked}
                                                onChecks={onChecks}
                                                onBrandChecked={onBrandChecked}
                                                type={"filter"}
                                            />
                                        </>
                                    ))}



                                </ul>
                            </div>
                            <div className="filter__footer">
                                <button className="button button--accent-dark filter__button"
                                        type="submit"  onClick={() => store.componentFilterCatalogStore.closeFilter()}>Показать
                                </button>
                                <button className="button filter__button"
                                        onClick={() => resetFilter()}
                                        type="reset">Сбросить
                                    <span className="filter__button-last-word">фильтр</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </aside>
            </>
        );
    }
}
