export enum enumOrderStatus {
    'canceled'='canceled',
    'blocked'='blocked',
    'harmonization'='harmonization',
    'accepted'='accepted',
    'assembling'='assembling',
    'assembled'='assembled',
    'loaded'='loaded',
    'shipped'='shipped',
    'created'='created',
    'draft'='draft',
    'passed_courier'='passed_courier'
}
