import {Model} from "./";
import {Api} from "../api";

class ExcelModel extends Model {

    constructor() {
        super(new Api("/excel"));
    }

    getOrder(id, thenFunction, errorFunction) {
        this.q.setDopUrl('/order/' + id);
        this.q.read({}, thenFunction, errorFunction);
    }

    getDownloadLink(id) {
        this.q.setBaseUrl();
        return this.q.baseURL + '/excel/order/' + id;
    }

    uploadOrderBeautyhouse(formData ,thenFunction, errorFunction){
        this.q.setBaseUrl();
        this.q.setDopUrl('/uploadBeautyhouse');
        this.q.uploadFileBeautyhouse(formData, thenFunction, errorFunction)
    }

}

export default new ExcelModel();