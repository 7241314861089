import React from 'react';
import {Link} from "react-router-dom";
import {SubHeaderMenu} from "./SubHeaderMenu";
import {WIDTH} from "../../const/root";
import SceletonForm from "../Skeleton";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';


interface Props {
    store: {};
    isAuth:any;
    menu: [];
    url: string;
    arFilterCatalogEmun: []
    listMenuCatalog: []
    filterActive: []
    onChecks: []
    getFilterSection: any
    isOpenMenuCatalog: any
    isOpenSubMenuSubCatalog: any
    onPageBrandCheckedMobile: any
    includeUserLoyaltyProgram: boolean
    showSkeleton: boolean
    listMenuCatalogNew: any
    newObj: any
    showArray: any
}

export class HeaderMenu extends React.Component<Props, {}> {
    render() {
        // @ts-ignore
        const store = this.props.store.appStore;
        // @ts-ignore
        let mainStore = this.props.store.mainStore;
        // @ts-ignore
        let componentFilterCatalogStore = this.props.store.componentFilterCatalogStore;
        let menu = this.props.menu;
        let onPageBrandCheckedMobile = this.props.onPageBrandCheckedMobile;
        let listMenuCatalogNew = this.props.listMenuCatalogNew;
        let filterActive = this.props.filterActive;
        let url = this.props.url;
        let isAuth = this.props.isAuth;
        let isOpenMenuCatalog = this.props.isOpenMenuCatalog;
        let showSkeleton = this.props.showSkeleton
        let showArray = this.props.showArray
        let newObj = this.props.newObj
        let Width = window.innerWidth;

        return (
            <>
                    <ul className="main-menu ">
                        {menu.map((item: any) =>
                            <>
                                {item.type === 'top' ?
                                    <>
                                        {item.url === '/catalog' ?
                                            <>
                                                {isAuth ?
                                                    <li className="main-menu__item" key='header-menu-catalog' >
                                                        <div className={isOpenMenuCatalog ?  "main-menu__link main-menu__link--catalog  is-active":"main-menu__link main-menu__link--catalog "}
                                                           onClick={()=>store.setOpenMenuCatalog()}
                                                        >Каталог
                                                        </div>
                                                        <div className={isOpenMenuCatalog ? "main-menu__sub-catalog-wrapper  is-open":"main-menu__sub-catalog-wrapper "}>
                                                            <div className="main-menu__back js--close-catalog-wrapper" onClick={()=> store.setOpenMenuCatalog(false)}>Назад</div>
                                                            <ul onMouseLeave={()=>store.setOpenMenuCatalog()}  className="sub-catalog">
                                                                {Width>WIDTH ?  <>
                                                                    {newObj.map(((en:any, index: number )=>
                                                                            <li key={index} className="sub-catalog__item"
                                                                                style={{cursor: "pointer"}}
                                                                                onMouseEnter={()=>store.getFilterSection(en['sectionId'], 'beautyHouseCategory')} >
                                                                                <span className={filterActive[en['sectionId']] ? "sub-catalog__link js--sub-catalog-toggle is-active" : "sub-catalog__link js--sub-catalog-toggle"}
                                                                                      onClick={() => onPageBrandCheckedMobile(en['id'], 'beautyHouseCategory')}>{en['name']}</span>
                                                                                <div  className={filterActive[en['sectionId']] ? "sub-catalog__item-content is-open" : "sub-catalog__item-content"}>
                                                                                    {listMenuCatalogNew && listMenuCatalogNew.length && !showSkeleton ? listMenuCatalogNew.map(((item:any, index: number) =>
                                                                                            <SubHeaderMenu
                                                                                                           store={store}
                                                                                                           item={item}
                                                                                                           showArray={showArray}
                                                                                                           filterActive={filterActive}
                                                                                                           index={index}
                                                                                            />
                                                                                    )) :  <SceletonForm/>

                                                                                    }
                                                                                </div>
                                                                            </li>
                                                                    ))}
                                                                    <li className="sub-catalog__item">
                                                                        <a href="/catalog?1">
                                                                            <span className="sub-catalog__link js--sub-catalog-toggle"
                                                                            >Весь каталог</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="sub-catalog__item" onClick={()=> componentFilterCatalogStore.resetFilter()}>
                                                                        <a href="/catalog/filter/isIncludeLoyaltyProgram-is-true?1">
                                                                            <span className="sub-catalog__link js--sub-catalog-toggle"
                                                                            >Бонусные товары</span>
                                                                        </a>
                                                                    </li>
                                                                </> :  <>
                                                                    {newObj.map(((en: any, index: number) =>
                                                                            <li key={index} className="sub-catalog__item">
                                                                                <div style={{display: "flex", alignItems: "center", margin: "auto", justifyContent: "space-between"}}>
                                                                                    <Link style={{
                                                                                        width: "80%",
                                                                                        textDecoration: "underline",
                                                                                        color: "#b17882"
                                                                                    }} to={`/catalog/filter/beautyHouseCategory-in-${en['sectionId']}?1`} >
                                                                                        <div
                                                                                             className={filterActive[en['sectionId']] ? "sub-catalog__link js--sub-catalog-toggle is-active" : "sub-catalog__link js--sub-catalog-toggle"}
                                                                                             onClick={()=> {store.closeMenuAction(); store.setOpenMenuCatalog(false)}}
                                                                                        >{en['name']}</div>
                                                                                    </Link>

                                                                                    <div style={{
                                                                                        width: "25%",
                                                                                        borderRadius: 10,
                                                                                        margin: "auto",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        justifyContent: "center",
                                                                                        background: "#f4f0f1",
                                                                                    }} onClick={() => {onPageBrandCheckedMobile(en['sectionId'], 'beautyHouseCategory' ); store.getFilterSection(en['sectionId'], 'beautyHouseCategory')}} >{filterActive[en['sectionId']] ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> } </div>
                                                                                </div>


                                                                                <div className={filterActive[en['sectionId']] ? "sub-catalog__item-content is-open" : "sub-catalog__item-content"}>
                                                                                    {listMenuCatalogNew && listMenuCatalogNew.length && !showSkeleton ? listMenuCatalogNew.map(((item:any, index: number) =>
                                                                                            <SubHeaderMenu
                                                                                                           showArray={showArray}
                                                                                                           store={store}
                                                                                                           item={item}
                                                                                                           filterActive={filterActive}
                                                                                                           index={index}
                                                                                            />)) : null}
                                                                                </div>
                                                                            </li>
                                                                    ))}
                                                                    <li className="sub-catalog__item">
                                                                        <Link to={"/catalog?1"}
                                                                              style={{textDecoration: "underline", color: '#b17882'}}
                                                                        >
                                                                            <span onClick={()=> {store.closeMenuAction(); store.setOpenMenuCatalog(false)}} className="sub-catalog__link js--sub-catalog-toggle"
                                                                            >Весь каталог</span>
                                                                        </Link>
                                                                    </li>
                                                                    <li className="sub-catalog__item">
                                                                        <Link to={"/catalog/filter/isIncludeLoyaltyProgram-is-true?1"}
                                                                              style={{textDecoration: "underline", color: '#b17882'}}
                                                                        >
                                                                            <span onClick={()=> {componentFilterCatalogStore.resetFilter(); store.closeMenuAction(); store.setOpenMenuCatalog(false)}} className="sub-catalog__link js--sub-catalog-toggle"
                                                                            >Бонусные товары</span>
                                                                        </Link>
                                                                    </li>
                                                                </> }

                                                            </ul>
                                                        </div>
                                                    </li>
                                                    : null}
                                            </>
                                            :
                                            <li className="main-menu__item" key={'header-menu-'+item.id}>
                                                    <Link className={item.url === url ? "main-menu__link is-current" : "main-menu__link" }
                                                          to={item.url}
                                                          replace
                                                          onClick={()=> {mainStore.setUrl(item.url) ; store.closeMenuAction()}}>
                                                        {item.name}
                                                    </Link>
                                            </li>
                                        }
                                    </>
                                    : null
                                }
                            </>
                        )}
                    </ul>
            </>
        );
    }
}


