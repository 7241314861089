import React from 'react';
import {withStore} from "../../hocs";
import {observer} from "mobx-react";

// @ts-ignore
@withStore
@observer
export class MainWorkBrands extends React.Component {
    componentDidMount() {
        // @ts-ignore
        // const {store} = this.props;
            // store?.componentFilterCatalogStore.reload2(true);
    }
    render() {
        // @ts-ignore
        const {store} = this.props;
        const {
            arBrand,
            arBrandForMainPageKerastase,
            arBrandForMainPageMatrix,
            arBrandForMainPageRedken,
            arBrandForMainPageProfessionnel
        } = store?.componentFilterCatalogStore;

        return (
            <>
                <div className="container">
                    <section className="section brands">
                        <h2 className="section__title">Работаем с брендами</h2>
                        <ul className="brands__list">
                            {arBrand.map((item: any, index: any)=> (
                                <li className="brands__item" key={index}>
                                    <>{item.value === "L'Oreal Professionnel"  ?
                                            <a href={'/catalog/filter/brand_' + item.id + '_'}>
                                                <img alt='' className="brands__thumbnail"
                                                     src="/images/pic--brand-1.png" />
                                            </a>
                                        : null}</>
                                    <>{item.value === "Redken"  ?
                                            <a href={'/catalog/filter/brand_' + item.id + '_'}>
                                                <img alt='' className="brands__thumbnail"
                                                     src="/images/pic--brand-2.png" />
                                            </a>
                                        : null}</>
                                    <>{item.value === "Matrix"  ?
                                            <a href={'/catalog/filter/brand_' + item.id + '_'}>
                                                <img alt='' className="brands__thumbnail"
                                                     src="/images/pic--brand-3.png" />
                                            </a>
                                        : null}</>


                                    <>{item.value === "Kerastase"  ?
                                            <a href={'/catalog/filter/brand_' + item.id + '_'}>
                                                <img alt='' className="brands__thumbnail"
                                                     src="/images/pic--brand-4.png" />
                                            </a>
                                        : null}</>
                                </li>

                            ))}
                            {!arBrandForMainPageProfessionnel ?  <li className="brands__item"><img alt='' className="brands__thumbnail"
                                                                                                  src="/images/pic--brand-1.png" /></li> : null }
                            {!arBrandForMainPageRedken ?  <li className="brands__item"><img alt='' className="brands__thumbnail"
                                                                                           src="/images/pic--brand-2.png" /></li>: null}
                            {!arBrandForMainPageMatrix ?  <li className="brands__item"><img alt='' className="brands__thumbnail"
                                                                                           src="/images/pic--brand-3.png" /></li> :null}
                            {!arBrandForMainPageKerastase ?  <li className="brands__item"><img alt='' className="brands__thumbnail"
                                                                                           src="/images/pic--brand-4.png" /></li> :null}


                        </ul>
                    </section>
                </div>
            </>
        )
    }
}
