import React from 'react';
import {withStore} from "../../hocs";
import {observer} from "mobx-react";
import {WIDTH} from "../../const/root";

// @ts-ignore
@withStore
@observer
export class SortGrid extends React.Component {

    render() {
        // @ts-ignore
        const {store} = this.props;
        let arData: any = store.pageCatalogStore.arMappingsCatalogList;
        // let key: any = store.pageCatalogStore.activeMappingsCatalogList;

        let keyDesktop: any = store.pageCatalogStore.activeMappingsCatalogList;
        let keyMobile: any = store.pageCatalogStore.activeMappingsCatalogListMobile;

        let key = ""
        let Window = window.innerWidth

        if (Window < WIDTH){
            key = keyMobile
        }else {
            key = keyDesktop
        }

        // @ts-ignore
        return (
            <>
                <ul className="sort-grid">
                    {arData.length ? arData.map((item: any, index: any) => (
                        <li className="sort-grid__item" key={index +item.id} onClick={(e) => store.pageCatalogStore.setMappingsCatalogList(e, item.id, item.class)}>
                            {item.id === key ?
                                <input className="visually-hidden" type="radio" name="grid" checked  id={item.id} />
                                :
                                <input className="visually-hidden" type="radio" name="grid" id={item.id} />
                            }
                            <label className="sort-grid__button" htmlFor="three-col">
                                <span className="visually-hidden">{item.title}</span>
                                <svg className="sort-grid__icon" width="20" height="20">
                                    <use xlinkHref={item.src} />
                                </svg>
                            </label>
                        </li>
                    )) : null
                    }
                </ul>
            </>
        );
    }
}
