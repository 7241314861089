export class Model {
    defaultPage = 1;
    defaultPageSize = 20;
    size = 20;
    subUrl = "";
    q;
    page;

    constructor(request) {
        this.q = request;
    }

    reloadRequest() {
        this.q.reload();
    }

    getAll(then, error) {
        this.q.read(then, error);
    }

    filter(data = {}, then, error = null) {
        this.q.read(
            then,
            error,
            Object.assign({pageNum: this.page, size: this.size}, data)
        );
    }

    search(text, then, error) {
        this.q.read(then, error, {
            searchTxt: text,
            pageNum: this.page,
            size: this.size,
        });
    }

    setPagination(page = null, size = null) {
        this.size = size ? size : this.defaultPageSize;
        this.page = page ? page : this.defaultPage;
    }
}
