import {action, makeAutoObservable} from "mobx"
import {RootStore} from "../index";
import OrderModel from "../../model/OrderModel";

export class PageOrderDetailStore {
    private rootStore: RootStore | undefined;

    arModalData = [];
    public error: string = '';
    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    @action init(id:number) {
        this.onOpenModal(id);
    }

    @action
    setError = (error: any) => {
        console.log(error)
    }

    repeatOrder = (result: any) => {
        this.rootStore?.mainStore.setRedirectUrl('/basket');
    }

    @action
    onOpenModal = (id: number) => {
        this.arModalData = [];
        OrderModel.getProductInfoOrder(id, this.setModalData, this.setError)
    }

    @action
    setModalData = (result: any) => {
        this.arModalData = result.data;
    }

}
