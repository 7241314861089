import React from 'react';
import {AnyQuestions} from "../components/block/AnyQuestions";
import {withStore} from "../hocs";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import ReactGA from "react-ga";
import {YOUR_TRACKING_ID} from "../stores/AppStore";
import {withRouter} from "react-router";
import {Button} from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// @ts-ignore
@withStore
@observer

class PalletPage extends React.Component {
    setGA = () => {
        ReactGA.initialize(YOUR_TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
    };
    componentDidMount() {
        // @ts-ignore
        const {store} = this.props;

        // @ts-ignore
        window.scrollTo(0, 0)
        this.setGA();
        // @ts-ignore
        store.palletStore.init()

    }


    render() {
        // @ts-ignore
        const {store} = this.props;

        let isAuth = store.mainStore.isAuth;
        if(!isAuth){
            store.appStore.setPopupAccessDenied(true)
        }

        const {rowsPallet, changePalletMatrixOrLoreal , shopPalletMatrixOrLoreal, objLoreal, changeShowForLoreal, arrayMainTitle} = store.palletStore


        const objMatrix = [
            {
                title: "Коллекция натуральных оттенков",
                subTitle: "Гамма естественных оттенков – от глубокого черного до ультра светлого блонда. Подходит для окрашивания волос с процентом седины до 50%."
            },{
                title: "Коллекция оттенков блонд",
                subTitle: "Линейка Соколор для осветления натуральных, неокрашенных волос до 5 уровней тона. Предназначена для волос от 6 уровня тона и выше."
            },{
                title: "Коллекция ярких оттенков",
                subTitle: "Яркие насыщенные тона для модного цветного окрашивания."
            },{
                title: "Коллекция оттенков для седых волос",
                subTitle: "Палитра цветов Matrix для окрашивания волос с сединой более 50%. Средства серии подходят для закрашивания стойкой седины."
            },{
                title: "SoColor Sync Pre-Bonded - Щелочная технология",
                subTitle: "Серия безаммиачных красителей для окрашивания без поднятия уровня тона. Придает волосам насыщенный цвет, яркий блеск и шелковистость. Благодаря бондеру в составе укрепляет волокна волос. В линейку входят красители на основе щелочной технологии. Краска без аммиака на основе щелочной технологии. Создает выразительный сияющий оттенок, который смывается постепенно. Косметика со щадящим составом, идеальная для частых экспериментов с оттенками и тонирования осветленных прядей."
            },{
                title: "Tonal Control Кислотные тонеры",
                subTitle: ""
            },{
                title: "SoColor Cult",
                subTitle: "Прямой краситель без необходимости добавления окислителя. Обеспечивает волосам яркий цвет и интенсивный уход: смягчает, разглаживает, придает блеск."
            },
        ]



        // @ts-ignore
        return (
            <>
                <main className="page__main">


                    <div style={{display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        margin: "auto",
                        maxWidth: 1024,
                        marginBottom: 20 ,
                        marginTop: 20,
                        color: "#b17882",

                    }}>
                        <h3 onClick={()=> changePalletMatrixOrLoreal()} style={shopPalletMatrixOrLoreal ? {textDecoration: "underline", cursor: "pointer", marginLeft: 10}:
                            { cursor: "pointer", marginLeft: 10}} >
                            Посмотреть палитру Matrix
                        </h3>
                        <h3 onClick={()=> changePalletMatrixOrLoreal()}  style={!shopPalletMatrixOrLoreal ? {textDecoration: "underline"}: { cursor: "pointer"}} >
                            Посмотреть палитру L'Oreal Professionnel
                        </h3>
                    </div>

                    {shopPalletMatrixOrLoreal ?

                        <div className="container">
                        <h1 className="page__title">Палитра красок для волос Matrix</h1>
                        <div>
                            Палитра красок для волос Matrix включает более 100 оттенков для создания
                            изысканных естественных и трендовых ярких образов. Обеспечивает получение ровного
                            насыщенного цвета, точное попадание в тон, гладкость и блеск волос. В зависимости от гаммы,
                            подходит для стойкого окрашивания, 100% закрашивания седины, осветления, временного тонирования.
                        </div>
                        <h2>
                            Socolor Pre-Bonded
                        </h2>
                        <div>
                            Основная палитра Matrix, включающая аммиачные красители для стойкого окрашивания.
                        </div>

                        {objMatrix.map((itemMain: any, index: number)=>(
                            <>
                                <h3 >
                                    {itemMain.title}
                                </h3>
                                <div>
                                    {itemMain.subTitle}
                                </div>
                                <p></p>
                                {rowsPallet && rowsPallet.length > 0 ? rowsPallet.map((item: any, index: number)=>(
                                    <>
                                        {item.name === itemMain.title && item.brand === "Matrix" ?  <div key={index}>
                                            {/*<div>*/}
                                            {/*    {item.name}*/}
                                            {/*</div>*/}
                                            <div style={{ display:"flex", flexWrap: "wrap"}}>
                                                {item && item.data && item.data.length ? item.data.map((value: any, ind: number)=>(
                                                    <Link to={"/catalog/" + value.code}>
                                                        <div style={{ margin: 10, cursor: "pointer"}}>
                                                            <img src={"https://img.sellwingroup.com/tones/"+ value.huePictureName +"/500_500/" + value.huePictureName +".webp"} style={{width: 150}}/>
                                                            <div style={{textAlign: "center", width: 150}}>
                                                                {value.hueNumber}
                                                            </div>
                                                        </div>
                                                    </Link>

                                                )) : null}
                                            </div>
                                        </div> : null

                                        }
                                    </>
                                )): null
                                }
                            </>
                        ))}
                    </div> :
                        <div className="container">

                            <h1 className="page__title">Палитра красок для волос L'Oreal Professionnel</h1>

                            {arrayMainTitle.map((item: any, index: number) => (
                                <>
                                    <h2>{item.mainTitle}</h2>
                                    {objLoreal.map((itemMain: any, index: number)=>(
                                        <>{itemMain.mainTitle === item.mainTitle ? <>
                                            <h3 style={{cursor: "pointer"}} onClick={()=> changeShowForLoreal(index)} >
                                                {itemMain.show ? <span  style={{verticalAlign: "top"}}><ExpandLessIcon/></span> : <span style={{verticalAlign: "top"}}><ExpandMoreIcon/></span>}

                                                {itemMain.title}
                                            </h3>

                                            <p></p>
                                            {rowsPallet && rowsPallet.length > 0 ? rowsPallet.map((item: any, index: number)=>(
                                                <>
                                                    {item.name === itemMain.title && item.title === itemMain.mainTitle && itemMain.show && item.brand === "L'Oreal Professionnel" ?  <div key={index}>
                                                        {/*<div>*/}
                                                        {/*    {item.name}*/}
                                                        {/*</div>*/}
                                                        <div style={{ display:"flex", flexWrap: "wrap"}}>
                                                            {item && item.data && item.data.length ? item.data.map((value: any, ind: number)=>(
                                                                <Link to={"/catalog/" + value.code}>
                                                                    <div style={{ margin: 10, cursor: "pointer"}}>
                                                                        <img src={"https://img.sellwingroup.com/tones/"+ value.huePictureName +"/500_500/" + value.huePictureName +".webp"} style={{width: 150}}/>
                                                                        <div style={{textAlign: "center", width: 150}}>
                                                                            {value.hueNumber}
                                                                        </div>
                                                                    </div>
                                                                </Link>

                                                            )) : null}
                                                        </div>
                                                    </div> : null

                                                    }
                                                </>
                                            )): null
                                            }
                                        </> : null}
                                        </>
                                    ))}
                                </>
                            ))}
                        </div>
                    }
                    <AnyQuestions />
                </main>
            </>
        )
    }
}
// @ts-ignore
export default withRouter(PalletPage);