import {Model} from "./";
import {Api} from "../api";

export class AuthLoginModel extends Model {

    constructor() {
        super(new Api("/auth"));
    }

    getToken(data, thenFunction, errorFunction) {
        this.q.setDopUrl('/login');
        this.q.create(data, thenFunction, errorFunction);
    }

    getRecovery(data, thenFunction, errorFunction) {
        this.q.setDopUrl('/recovery');
        this.q.create(data, thenFunction, errorFunction);
    }

    setNewPassword(data, thenFunction, errorFunction) {
        this.q.setDopUrl('/change');
        this.q.create(data, thenFunction, errorFunction);
    }
    getCheckWord(code, thenFunction, errorFunction) {
        this.q.setDopUrl('/change/'+code);
        this.q.read({}, thenFunction, errorFunction);
    }

    getTokenRoot(id, thenFunction, errorFunction) {
        this.q.setDopUrl('/login-root/'+id);
        this.q.read({}, thenFunction, errorFunction);
    }

}

export default new AuthLoginModel();



