import React from 'react';
import {Link} from "react-router-dom";

interface Props {
    brand: string;
    idBrand: any;
    arFilterCatalogEmun: any;
    onPageBrandCheckedFromProduct: any;
}

export class SingleProduct extends React.Component<Props, {}> {
    render() {
        let brand: any = this.props.brand;
        let idBrand: any = this.props.idBrand;
        let arFilterCatalogEmun: any = this.props.arFilterCatalogEmun;

        return (
            <>
                {arFilterCatalogEmun.length ?
                    <div className="single-product__other-brands">
                        <p className="single-product__sub-title">Другие категории средств {brand}</p>
                        <ul className="other-brands">
                            {arFilterCatalogEmun.map((item: any, index: any) => (
                                <div key={index}>
                                    <li className="other-brands__item" >
                                        <Link className="other-brands__link"
                                              to={'/catalog/filter/beautyHouseCategory-in-' + item.id + '+brand-in-'+ idBrand}>{item.value}</Link>
                                    </li>
                                </div>
                            ))}
                        </ul>
                    </div>
                : null }
            </>
        )
    }
}
