import {Model} from "./";
import {Api} from "../api";

class BasketModel extends Model {

    constructor() {
        super(new Api("/basket"));
    }

    addProduct(ob,discount=0, thenFunction, errorFunction) {
        this.q.setDopUrl('?discount='+discount);
        this.q.create({
            productId: ob.productId,
            count: ob.count,
            clientId: ob.clientId,
            isMultiplicity: ob.isMultiplicity,
        }, thenFunction, errorFunction);
    }

    getBasket(clientId,discount=0, thenFunction, errorFunction) {
        this.q.setDopUrl('/' + clientId);
        this.q.read({discount:discount}, thenFunction, errorFunction);
    }

    deleteProduct(data, thenFunction, errorFunction) {
        this.q.setDopUrl('/');
        this.q.delete(data, thenFunction, errorFunction);
    }

    deleteAllBasket(id, thenFunction, errorFunction) {
        this.q.setDopUrl('/all');
        this.q.delete(id, thenFunction, errorFunction);
    }

    addProductsArray(ob,thenFunction, errorFunction) {
        this.q.setDopUrl('/array');
        this.q.create(ob, thenFunction, errorFunction);
    }
    getFutureLoyaltyScores(clientId,discount=0,scoresDebt = 0, thenFunction, errorFunction) {
        this.q.setDopUrl('/future_loyalty/' + clientId);
        this.q.read({discount:discount,scoresDebt: scoresDebt}, thenFunction, errorFunction);
    }

}

export default new BasketModel();
