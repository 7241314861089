import {Model} from "./";
import {Api} from "../api";

class MessageViberModel extends Model {

    constructor() {
        super(new Api("/messageLayout"));
    }

    updateMessageViber(id, ob, thenFunction, errorFunction) {
        this.q.setDopUrl('/update/' + id);
        this.q.update(ob, thenFunction, errorFunction);
    }

    getAllMessageViber( thenFunction, errorFunction) {
        this.q.setDopUrl('/all_message_viber');
        this.q.read({}, thenFunction, errorFunction);
    }

}

export default new MessageViberModel();

