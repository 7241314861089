import React from 'react';
import {FooterMenu} from "./FooterMenu";
import {Authorization} from "../modals/Authorization";
import {AccessDenied} from "../modals/AccessDenied";
import {ThankYouApplication} from "../modals/ThankYouApplication";
import {Copyright} from "../block/Copyright";
import {FooterContacts} from "./FooterContacts";
import {withStore} from "../../hocs";
import {observer} from "mobx-react";
import { Link } from "react-router-dom";

// @ts-ignore
@withStore
@observer
export  class FooterBottom extends React.Component {

    render() {
        // @ts-ignore
        const {store} = this.props;
        const {menuFooter, isPopupAuthorization, isPopupPasswordRecovery, isPopupAccessDenied,
            isPopupThankYouApplication, isPopupAddDeliveryAddress,
            isPopupAddDeliveryAddressSuccessful, isPopupSendOrder, isPopupThankYouSendOrder, setPopupAuthorization, setPopupAccessDenied} = store?.appStore;
        let url = store.mainStore.url;


        let params = store.mainStore.getParamsUrl();

        if(params.notIsAuth === 'n'){
            store.mainStore.setShowPopupNotIsAuth(true);
            store.mainStore.popupNotIsAuth()
        }


        return (
            <>
                <footer className="page__footer page-footer">
                    <div className="container page-footer__grid">

                        {/* footer меню */}
                        <FooterMenu store={store} menu={menuFooter} url={url}/>

                        <div className="page-footer__item" key='footer-contacts'>

                            {/* footer контакты */}
                            <FooterContacts />

                            {/* Подписка на рассылку */}

                        </div>

                    </div>

                    {/* copyright */}
                    <Copyright />

                </footer>

                <div className={isPopupAuthorization || isPopupPasswordRecovery || isPopupAccessDenied || isPopupThankYouApplication || isPopupAddDeliveryAddress || isPopupAddDeliveryAddressSuccessful || isPopupSendOrder || isPopupThankYouSendOrder ? "page__overlay js--page-overlay js--page-overlay---my is-open" : "page__overlay js--page-overlay js--page-overlay---my"}  />

                {/*  попап - нет доступа */}
                <AccessDenied isPopupAccessDenied={isPopupAccessDenied}  setPopupAuthorization={setPopupAuthorization} setPopupAccessDenied={setPopupAccessDenied}/>

                {/* попап - для авторизации */}
                <Authorization isPopupAuthorization={isPopupAuthorization}  isPopupPasswordRecovery={isPopupPasswordRecovery}/>

                <div className="modal" data-modal="3">
                    <button className="modal__close js--modal-close" type="button"><span className="visually-hidden">Закрыть модальное окно</span>
                        <span className="modal__close-icon" /></button>
                    <div className="modal__body">
                        <h2 className="modal__title">Обратная связь</h2>
                        <form className="form modal__form">
                            <p className="form__item">
                                <label className="visually-hidden" htmlFor="name">Ваше имя</label>
                                <input className="text-field" type="text" placeholder="Ваше имя" id="name" />
                            </p>
                            <p className="form__item">
                                <label className="visually-hidden" htmlFor="phone">Телефон</label>
                                <input className="text-field" type="text" placeholder="Телефон" id="phone" />
                            </p>
                            <button className="button button--accent-dark modal__button modal__button--submit"
                                    type="submit">Заказать звонок
                            </button>
                        </form>
                    </div>
                </div>

                <div className="modal" data-modal="3">
                    <button className="modal__close js--modal-close" type="button"><span className="visually-hidden">Закрыть модальное окно</span>
                        <span className="modal__close-icon" /></button>
                    <div className="modal__body">
                        <h2 className="modal__title">Обратная связь</h2>
                        <form className="form modal__form">
                            <p className="form__item">
                                <label className="visually-hidden" htmlFor="name">Ваше имя</label>
                                <input className="text-field" type="text" placeholder="Ваше имя" id="name" />
                            </p>
                            <p className="form__item">
                                <label className="visually-hidden" htmlFor="phone">Телефон</label>
                                <input className="text-field" type="text" placeholder="Телефон" id="phone" />
                            </p>
                            <button className="button button--accent-dark modal__button modal__button--submit"
                                    type="submit">Заказать звонок
                            </button>
                        </form>
                    </div>
                </div>

                <div className="modal modal--success" data-modal="4">
                    <button className="modal__close js--modal-close" type="button"><span className="visually-hidden">Закрыть модальное окно</span><span
                        className="modal__close-icon" /></button>
                    <div className="modal__body">
                        <h2 className="modal__title">Спасибо!</h2>
                        <p className="modal__body-text">Мы получили вашу заявку. Ожидайте свзяти с нашим менеджером.</p>
                        <Link className="button button--accent-dark modal__button js--modal-close" to="#">Закрыть
                            окно</Link>
                    </div>
                </div>

                {/* попап - Спасибо за заявку!*/}
                <ThankYouApplication isPopupThankYouApplication={isPopupThankYouApplication}/>

                <div className="modal" data-modal="6">
                    <button className="modal__close js--modal-close" type="button"><span className="visually-hidden">Закрыть модальное окно</span><span
                        className="modal__close-icon" /></button>
                    <div className="modal__body modal__body--clear">
                        <h2 className="modal__title">Новый адрес доставки</h2>
                        <form className="form form__new-address">
                            <p className="form__item">
                                <label className="form__label" htmlFor="">Беларусь</label>
                                <select className="select-field">
                                    <option value="">Выбрать</option>
                                    <option value="">Минская область</option>
                                </select>
                            </p>
                            <p className="form__item">
                                <label className="form__label" htmlFor="">Город</label>
                                <input className="form__field text-field" type="text" />
                            </p>
                            <p className="form__item">
                                <label className="form__label" htmlFor="">Улица</label>
                                <input className="form__field text-field" type="text" />
                            </p>
                            <div className="form__item-group form__item-group--house-detail">
                                <p className="form__item">
                                    <label className="form__label" htmlFor="">Дом</label>
                                    <input className="form__field text-field" type="text" />
                                </p>
                                <p className="form__item">
                                    <label className="form__label" htmlFor="">Кв-ра</label>
                                    <input className="form__field text-field" type="text" />
                                </p>
                                <p className="form__item">
                                    <label className="form__label" htmlFor="">Этаж</label>
                                    <input className="form__field text-field" type="text" />
                                </p>
                            </div>
                            <button className="button button--accent-dark modal__button modal__button--new-address"
                                    type="submit">Добавить
                            </button>
                        </form>
                    </div>
                </div>
            </>
        );
    }
}
