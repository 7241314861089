import { jwtDecode } from "jwt-js-decode";

class Token {
    static getTokenData(token) {
        let jwt = jwtDecode(token);
        return jwt.payload;
    }
}

export default Token;

