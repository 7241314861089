import React from 'react';
import {withStore} from "../../../hocs";
import {observer} from "mobx-react";

interface Props {
    store: any,
    itemFilter:any
    filterActive: any
    onFilterChecked: any
    onBrandChecked: any
    onChecks: any
    includeUserLoyaltyProgram: boolean
}

// @ts-ignore
@withStore
@observer
export class FilterItemDop extends React.Component<Props, {}> {

    render() {

        const {filterActive, onFilterChecked, onBrandChecked, onChecks}=this.props;

        // @ts-ignore
        return (
            <>
                <li className="filter__list-item" key={'filter-top'}>

                    <button className={filterActive['OurOffers'] ? 'filter__list-title js--accordion-toggle is-active' : 'filter__list-title js--accordion-toggle'}
                            onClick={() => onFilterChecked('OurOffers')}
                            data-filter_but_cpde={'OurOffers'}
                            type="button">Наши предложения
                        <svg className="filter__list-icon" width="7" height="12">
                            <use xlinkHref="/images/sprite.svg#icon--arrow-left" />
                        </svg>
                    </button>

                    <div data-filter_cont_cpde={'OurOffers'}
                         className={filterActive['OurOffers'] ? 'filter__list-content js--accordion-content is-open' : 'filter__list-content js--accordion-content'}
                    >
                        <ul className="filter__options">

                            <li className="filter__options-item"
                                onClick={() => onBrandChecked(1, 'isNovelty')}
                                key={'isNovelty'}>
                                <input className="visually-hidden"
                                       type="checkbox"
                                       defaultValue={1}
                                       readOnly
                                       checked={onChecks.includes(1)}
                                       onChange={() => {}} // Добавлен пустой обработчик для избежания предупреждения
                                       name={'isNovelty'}
                                />
                                <label className="filter__options-label checkbox-field"
                                       htmlFor={'isTopBeautyHouse'}>Новинка
                                </label>
                            </li>


                            <li className="filter__options-item"
                                onClick={() => onBrandChecked(2, 'isTopBeautyHouse')}
                                key={'isTopBeautyHouse'}>
                                <input className="visually-hidden"
                                       type="checkbox"
                                       defaultValue={2}
                                       readOnly
                                       checked={onChecks.includes(2)}
                                       onChange={() => {}} // Добавлен пустой обработчик для избежания предупреждения
                                       name={'isTopBeautyHouse'}
                                />
                                <label className="filter__options-label checkbox-field"
                                       htmlFor={'isTopBeautyHouse'}>Топ
                                </label>
                            </li>

                            <li className="filter__options-item"
                                onClick={() => onBrandChecked(3, 'isSellout')}
                                key={'isSellout'}>
                                <input className="visually-hidden"
                                       type="checkbox"
                                       defaultValue={3}
                                       readOnly
                                       checked={onChecks.includes(3)}
                                       onChange={() => {}} // Добавлен пустой обработчик для избежания предупреждения
                                       name={'isSellout'}
                                />
                                <label className="filter__options-label checkbox-field"
                                       htmlFor={'isTopBeautyHouse'}>Акции
                                </label>
                            </li>
                            <li className="filter__options-item"
                                onClick={() => onBrandChecked(4, 'isIncludeLoyaltyProgram')}
                                key={'isIncludeLoyaltyProgram'}>
                                <input className="visually-hidden"
                                       type="checkbox"
                                       defaultValue={4}
                                       readOnly
                                       checked={onChecks.includes(4)}
                                       onChange={() => {}} // Добавлен пустой обработчик для избежания предупреждения
                                       name={'isIncludeLoyaltyProgram'}
                                />
                                <label className="filter__options-label checkbox-field"
                                       htmlFor={'isTopBeautyHouse'}>Бонусные товары
                                </label>
                            </li>
                        </ul>
                    </div>
                </li>
            </>
        );
    }
}
