import React from 'react';
import cap2 from './cap2.svg'

export class MainOurAdvantages extends React.Component {
    render() {
        return (
            <>
                <section className="section advantages advantages--home" style={{paddingTop: 25}}>
                    <div className="container">
                        <h2 className="section__title">Наши преимущества</h2>
                        <ul className="advantages__list">
                            <li className="advantages__item">
                                <a href="https://sellwin.by/" target="_blank" rel="noreferrer">
                                    <svg className="advantages__icon" width="38" height="38">
                                        <use xlinkHref="images/sprite.svg#icon--advantages-1" />
                                    </svg>
                                </a>
                                <a href="https://sellwin.by/" target="_blank" rel="noreferrer">
                                    Официальный портал дистрибьютора.
                                </a>

                            </li>
                            <li className="advantages__item">
                                <a href="/delivery">
                                    <svg className="advantages__icon" width="44" height="24">
                                        <use xlinkHref="images/sprite.svg#icon--advantages-2" />
                                    </svg>
                                </a>
                                <a href="/delivery">
                                    Бесплатная доставка от 300 рублей.
                                </a>

                            </li>
                            {/*<li className="advantages__item">*/}
                            {/*    <svg className="advantages__icon" width="38" height="38">*/}
                            {/*        <use xlinkHref="images/sprite.svg#icon--advantages-3" />*/}
                            {/*    </svg>*/}
                            {/*    Подарки в каждом заказе.*/}
                            {/*</li>*/}
                            <li className="advantages__item">
                                <a href="/payment">
                                    <svg className="advantages__icon" width="38" height="38">
                                        <use xlinkHref="images/sprite.svg#icon--advantages-4" />
                                    </svg>
                                </a>
                                <a href="/payment">
                                    Скидка 3% за предоплату.
                                </a>
                            </li>

                            <li className="advantages__item">
                                <img className="advantages__icon" width="38" height="38" src={cap2} alt='cap' />
                                <a href="https://yandex.by/maps/org/akademiya_l_oreal/63386076887/?display-text=%D0%B0%D0%BA%D0%B0%D0%B4%D0%B5%D0%BC%D0%B8%D1%8F%20l%20oreal%20%D0%BC%D0%B8%D0%BD%D1%81%D0%BA&ll=27.488142%2C53.938071&mode=search&sctx=ZAAAAAgBEAAaKAoSCSSaQBGLeDxAEcrFGFjHD0tAEhIJZVJDG4CND0AR%2BRG%2FYg0X9T8iBgABAgMEBSgKOABAvucBSAFqAnVhnQHNzEw9oAEAqAEAvQEiSeUGwgEG143rkOwB6gEA8gEA%2BAEAggIj0LDQutCw0LTQtdC80LjRjyBsIG9yZWFsINC80LjQvdGB0LqKAgCSAgMxNTeaAgxkZXNrdG9wLW1hcHM%3D&sll=27.488142%2C53.938071&sspn=0.015407%2C0.005172&text=%D0%B0%D0%BA%D0%B0%D0%B4%D0%B5%D0%BC%D0%B8%D1%8F%20l%20oreal%20%D0%BC%D0%B8%D0%BD%D1%81%D0%BA&z=17"
                                   rel="noreferrer" target="_blank">
                                    Академия <br/>L'Oréal
                                </a>

                            </li>


                        </ul>
                    </div>
                </section>
            </>
        )
    }
}
