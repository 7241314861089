import {RootStore} from '../index';
import {action, makeAutoObservable} from "mobx"
import * as query from "query-string"
import ElementModel from "../../model/ElementModel";

export class ComTopSearchStore {

    search:  string = '';
    rows = [];
    isShow: boolean = false;

    private rootStore: RootStore | undefined;

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;

    }

    init() {

        let urlOb = query.parse(window.location.search);
        if (urlOb.q !== undefined) {
            this.search = String(urlOb.q);
        }
        this.reload();
    }

    onChangeInput = (e: any) => {
        this.search = e.target.value;
        localStorage.setItem('search', this.search);
        // if (this.search.length > 2) {
        //    this.reload()
        // } else {
        //     this.rows = [];
        // }
    }



    clearInputField = () =>{
        this.search = ''
    }

    reload() {

        ElementModel.search(this.search, this.setData, this.setError)
    }

    @action
    reloadSearch = ()=> {

        console.log('reload')
        console.log(this.search)
  // this.reload()
}

    @action
    setData = (result: any) => {
        if (result.data.rows) {
            this.rows = result.data.rows
        }
    }

    @action
    setError = (error: any) => {
        console.log(error);
    }
    setIsShown() {
        this.isShow = !this.isShow

    }

    toggleFIeldset = () => this.setIsShown();

    @action
    public setSortParams = (orderField: string) => {
        this.toggleFIeldset();
        if (orderField === 'priceUp') {
            this.rows = this.rows.sort(function (a: any, b: any) {
                if (a.price.base_price > b.price.base_price) {
                    return 1;
                }
                if (a.price.base_price < b.price.base_price) {
                    return -1;
                }
                return 0;
            })
        }
        if (orderField === 'priceDown') {
            this.rows = this.rows.sort(function (a: any, b: any) {
                if (a.price.base_price > b.price.base_price) {
                    return -1;
                }
                if (a.price.base_price < b.price.base_price) {
                    return 1;
                }
                return 0;
            })
        }
        if (orderField === 'name') {
            this.rows = this.rows.sort(function (a: any, b: any) {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            })
        }
        if (orderField === 'createdAt') {
            this.rows = this.rows.sort(function (a: any, b: any) {
                if (a.createdAt > b.createdAt) {
                    return 1;
                }
                if (a.createdAt < b.createdAt) {
                    return -1;
                }
                return 0;
            })
        }

    }
}
