import React from 'react';
import {AnyQuestions} from "../components/block/AnyQuestions";
import {Recommended} from "../components/catalog/Recommended";
import {withStore} from "../hocs";
import {Link} from "react-router-dom";
import {observer} from "mobx-react";
import {withRouter} from "react-router";
import {SliderProduct} from "../components/slider/SliderProduct";
import {SingleProduct} from "../components/block/SingleProduct";
import {BasketItem} from "../components/catalog/BasketItem";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import {WIDTH} from "../const/root";
import ReactGA from "react-ga";
import {YOUR_TRACKING_ID} from "../stores/AppStore";
import SliderForPrevProduct from "../components/slider/SliderForPrevProduct";
import Select from "react-select";


// @ts-ignore
@withStore
@observer
class ProductPage extends React.Component {
    private store: any;
    setGA = () => {
        ReactGA.initialize(YOUR_TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
    };
    componentDidMount() {
        // @ts-ignore
        const {store} = this.props;
        // @ts-ignore
        const code = this.props.match.params.code;
        store.pageProductStore.init(code);
        this.setGA();
        // @ts-ignore
        window.scrollTo(0, 0)
        store.pageBasketStore.initForProductPage()


    }

    // @ts-ignore
    addDefaultSrc = ev => {
        ev.target.src = '/images/pic--goods-0.jpg'
    }

    render() {


        // @ts-ignore
        const {store} = this.props;
        if (window.location.pathname === '/catalog/filter/') {
            // @ts-ignore
            window.history.pushState(null, null, '/catalog');
            // @ts-ignore
            window.location.reload()

        }
        const {
            selectedOption,
            setSelectedOption,
            options,
            arrDeliveryTypeForProductPage
        } = store?.pageBasketStore;

        let isAuth = store.mainStore.isAuth;
        if (!isAuth) {
            store.appStore.setPopupAccessDenied(true)
        }

        // @ts-ignore
        const {pageProductStore} = this.props.store;


        // @ts-ignore
        const {
            arRecommend2,
            arElement,
            activeTab,
            arRecommend,
            isActiveDec,
            arFilterCatalogEmun,
            changeArElement,
            arPrevSawProduct
        }
            // @ts-ignore
            = this.props.store.pageProductStore;
        // @ts-ignore
        const {
            includeUserLoyaltyProgram,
            org_type
        }
            // @ts-ignore
            = this.props.store.mainStore;

        const {
            onPageBrandCheckedFromProduct,
        } = store?.componentFilterCatalogStore;

        const {
            isShow, toggleFIeldset,
        } = store?.componentTopSearchStore

        let show = isShow;


        const {
            id,
            name, //Краткое наименование
            //barcode, //Штрихкод
            props,
            price,
            propsNoFilter
        } = arElement;

        let methodOfApplication = propsNoFilter.methodOfApplication;
        let productComposition = propsNoFilter.productComposition;
        let dyesType = propsNoFilter.dyesType;              // Тип Красителя  !!!!!!!!!!????????
        let hairType = propsNoFilter.hairType;               //Тип волос
        let img = arElement['img'];               //Тип волос
        let idBrand = props.brand;
        let idSeriesLineGamma = props.seriesLineGamma;

        const {
            barcode,
            cosmeticEffectName,     //Эффект
            itemType,
            hueName,                //Оттенок в виде наименование
            shortDescription,       //Краткое описание
            productTextureName,     //Текстура продукта
            brandDescription,       //Описание бренда
            productDescription,     //Описание продукта
            subLine,           //Подлиния
            forWhomName,            //Для кого
            fixingDegree,       // Степень фиксации
            pallet,             //Палитра
            brand,                  //Бренд
            producerCountryName,    //Страна производитель
            subGamma,            //Подгамма
            seriesLineGamma,         //Серия
            hueNumber,         //номер подгаммы или цвета
            vat, // НДС
            isIncludeLoyaltyProgram, // бонусные товары

        } = propsNoFilter;

        if (methodOfApplication === undefined) {   //Способ применения
            methodOfApplication = '';
        }
        if (productComposition === undefined) { // Состав продукта
            productComposition = '';
        }
        if (dyesType === undefined) { // Тип Красителя
            dyesType = '';
        }
        if (hairType === undefined) { // Тип волос
            hairType = '';
        }

        const final_price = parseFloat(price?.final_price);
        const priceOld = parseFloat(price?.base_price);
        const isPriceOld = price !== priceOld;


        if (!arElement) {
            document.location.href = "/404";
        }
        let Width = window.innerWidth;

        // @ts-ignore
        const userId = this.props.store.mainStore.id;
        // @ts-ignore
        const clientId = this.props.store.mainStore.clientId;

        const {addFavorite, arFavorite, deleteFavorite}
            // @ts-ignore
            = this.props.store.pageCatalogStore;
        let icon = false;
        for( let i= 0; i < arFavorite.length; i++){
            if (arFavorite[i].productId === id ){
                icon = true;
            }
        }


        // @ts-ignore
        return (
            <>
                <main className="page__main">
                    <div className="container">
                        <ul className="breadcrumbs">
                            <li className="breadcrumbs__item"><a className="breadcrumbs__link" href="/">Главная</a></li>
                            <li className="breadcrumbs__item"><a className="breadcrumbs__link" href="/catalog"> Каталог</a></li>
                            <li className="breadcrumbs__item"> {name}</li>
                        </ul>
                        <div className="single-product">
                            <div className="single-product__meta">
                                <ul className="single-product__brands">
                                    <li className="single-product__brands-item"><Link
                                        className="single-product__brands-link"
                                        to={'/catalog/filter/brand-in-' + idBrand}>{brand}</Link></li>
                                    <li className="single-product__brands-item"><Link
                                        className="single-product__brands-link"
                                        to={'/catalog/filter/seriesLineGamma-in-' + idSeriesLineGamma}>{seriesLineGamma}</Link>
                                    </li>
                                </ul>
                                <h1 className="single-product__title">{name}</h1>
                                <p className="single-product__sku">Штрихкод : {barcode}</p>
                            </div>

                            <div className="single-product__grid">

                                < SliderProduct
                                    userId={userId} clientId={clientId}  addFavorite={addFavorite} id={id} deleteFavorite={deleteFavorite} icon={icon}
                                    img={img}/>
                                <div className="single-product__content">
                                    <div className="single-product__meta">
                                        <ul className="single-product__brands">
                                            <li className="single-product__brands-item"><Link
                                                className="single-product__brands-link"
                                                to={'/catalog/filter/brand-in-' + idBrand}>{brand}</Link></li>
                                            <li className="single-product__brands-item"><Link
                                                className="single-product__brands-link"
                                                to={'/catalog/filter/seriesLineGamma-in-' + idSeriesLineGamma}>{seriesLineGamma}</Link>
                                            </li>
                                        </ul>
                                        <h1 className="single-product__title">{name}</h1>
                                        <p className="single-product__sku">Штрихкод : {barcode}</p>
                                    </div>

                                    {final_price && final_price > 0 ?
                                        <div className="single-product__controls">
                                            <div className="prod-box-price">
                                                {final_price ?
                                                    <p className="single-product__price">{((final_price * parseFloat(vat) / 100) + final_price).toFixed(2)} BYN</p> : null}
                                                {isPriceOld && priceOld !== final_price ?
                                                    <p className="single-product__price old-price">{((priceOld * parseFloat(vat) / 100) + priceOld).toFixed(2)} BYN</p> : null}
                                            </div>

                                            <BasketItem  item={arElement} isDetail={true} type={"Any"}/>
                                            {/*<div style={{width: 200, height: 100, border: "1px solid black", position: "absolute", marginLeft: 350}}>*/}
                                            {/*    <div> Ljcnff</div>*/}


                                            {/*</div>*/}


                                        </div> : <div style={{fontWeight: "bold", color: "red"}}>
                                            Нет в остатках
                                        </div>}


                                    <div className="single-product__tabs tabs js--tabs">
                                        <ul className="tabs__navigation">
                                            <li className={activeTab === 'tabs-3' ? "tabs__navigation-item is-active" : "tabs__navigation-item"}
                                                key={'tabs-3'}>
                                                <span className="tabs__navigation-link "
                                                      onClick={(e) => pageProductStore.setTabActive('tabs-3')}
                                                >Доставка товара</span>
                                            </li>
                                            <li className={activeTab === 'tabs-1' ? "tabs__navigation-item is-active" : "tabs__navigation-item"}
                                                key={'tabs-1'}>
                                                <span className="tabs__navigation-link "
                                                      onClick={(e) => pageProductStore.setTabActive('tabs-1')}
                                                >Характеристики</span>
                                            </li>
                                            {productComposition !== '' ?
                                                <>
                                                    <li className={activeTab === 'tabs-2' ? "tabs__navigation-item is-active" : "tabs__navigation-item"}
                                                        key={'tabs-2'}>
                                                        <span className="tabs__navigation-link "
                                                              onClick={(e) => pageProductStore.setTabActive('tabs-2')}
                                                        >Состав</span>
                                                    </li>
                                                </>
                                                : null}
                                        </ul>
                                        <div className="tabs__content-wrapper">
                                            <div key={'tabs-1'}
                                                 className={activeTab === 'tabs-1' ? "tabs__content is-open js--tabs-content" : "tabs__content js--tabs-content"}
                                                 id="tab-1">
                                                <table style={Width > WIDTH ? {width: 680} : {}}
                                                       className="single-product__options options-table">
                                                    <tbody>
                                                    {brand && brand !== '' ?
                                                        <tr key='prop-01'>
                                                            <td><span className="options-table__key">Бренд</span></td>
                                                            <td>{brand}</td>
                                                        </tr>
                                                        : null}

                                                    {seriesLineGamma && seriesLineGamma !== '' ?
                                                        <tr key='prop-22'>
                                                            <td><span
                                                                className="options-table__key">Линейка бренда</span>
                                                            </td>
                                                            <td>{seriesLineGamma}</td>
                                                        </tr>
                                                        : null}

                                                    {subLine && subLine !== '' ?
                                                        <tr key='prop-02'>
                                                            <td><span className="options-table__key">Подлиния</span>
                                                            </td>
                                                            <td>{subLine}</td>
                                                        </tr>
                                                        : null}

                                                    {itemType && itemType !== '' ?
                                                        <tr key='prop-07'>
                                                            <td><span className="options-table__key">Тип продукта</span>
                                                            </td>
                                                            <td>{itemType}</td>
                                                        </tr>
                                                        : null}

                                                    {forWhomName && forWhomName !== '' ?
                                                        <tr key='prop-03'>
                                                            <td><span className="options-table__key">Для кого</span>
                                                            </td>
                                                            <td>{forWhomName}</td>
                                                        </tr>
                                                        : null}

                                                    {hairType && hairType !== '' ?
                                                        <tr key='prop-09'>
                                                            <td><span className="options-table__key">Тип волос</span>
                                                            </td>
                                                            <td>{hairType}</td>
                                                        </tr>
                                                        : null}

                                                    {fixingDegree && fixingDegree !== '' ?
                                                        <tr key='prop-16'>
                                                            <td><span
                                                                className="options-table__key">Степень фиксации</span>
                                                            </td>
                                                            <td>{fixingDegree}</td>
                                                        </tr>
                                                        : null}

                                                    {dyesType && dyesType !== '' ?
                                                        <tr key='prop-06'>
                                                            <td><span
                                                                className="options-table__key">Тип красителя</span>
                                                            </td>
                                                            <td>{dyesType}</td>
                                                        </tr>
                                                        : null}

                                                    {pallet && pallet !== '' ?
                                                        <tr key='prop-05'>
                                                            <td><span className="options-table__key">Палитра</span>
                                                            </td>
                                                            <td>{pallet}</td>
                                                        </tr>
                                                        : null}


                                                    {subGamma && subGamma !== '' ?
                                                        <tr key='prop-10'>
                                                            <td><span className="options-table__key">Подгамма</span>
                                                            </td>
                                                            <td>{subGamma}</td>
                                                        </tr>
                                                        : null}

                                                    {producerCountryName && producerCountryName !== '' ?
                                                        <tr key='prop-08'>
                                                            <td><span className="options-table__key">Страна производитель</span>
                                                            </td>
                                                            <td>{producerCountryName}</td>
                                                        </tr>
                                                        : null}

                                                    {cosmeticEffectName && cosmeticEffectName !== '' ?
                                                        <tr key='prop-12'>
                                                            <td><span className="options-table__key">Эффект</span>
                                                            </td>
                                                            <td>{cosmeticEffectName}</td>
                                                        </tr>
                                                        : null}

                                                    {productTextureName && productTextureName !== '' ?
                                                        <tr key='prop-11'>
                                                            <td><span
                                                                className="options-table__key">Текстура продукта</span>
                                                            </td>
                                                            <td>{productTextureName}</td>
                                                        </tr>
                                                        : null}

                                                    {hueNumber !== '' && hueName !== '' ?
                                                        <tr key='prop-15'>
                                                            <td><span className="options-table__key">Оттенок  </span>
                                                            </td>

                                                            <td>{hueName  + ' ' + hueNumber} <img style={{
                                                                width: 20,
                                                                height: 20,
                                                                borderRadius: '100%',
                                                                marginLeft: 10
                                                            }} alt='' src={img.tone}/>
                                                            </td>

                                                        </tr>
                                                        : null}

                                                    {hueNumber !== '' && hueName === '' ?
                                                        <tr key='prop-15'>
                                                            <td><span className="options-table__key">Оттенок  </span>
                                                            </td>

                                                            <td>{hueNumber}<img style={{
                                                                width: 20,
                                                                height: 20,
                                                                // borderRadius: '100%',
                                                                marginLeft: 10
                                                            }} src={img.tone} alt=''/>
                                                            </td>

                                                        </tr>
                                                        : null}
                                                    {show ? <tr>
                                                        <td><span className="options-table__key">Все цвета</span>
                                                        </td>
                                                        <td onClick={toggleFIeldset}>Скрыть    &#9650; </td>
                                                    </tr> : null}
                                                    {arRecommend2.length > 1 && hueNumber ?
                                                        <tr key='prop-13'>
                                                            {!show ? <td><span
                                                                    className="options-table__key">Все цвета</span></td> :
                                                                <td style={{opacity: 0}}><span
                                                                    className="options-table__key">Все цвета</span>
                                                                </td>}
                                                            {show ? null : <div onClick={toggleFIeldset} style={{
                                                                paddingTop: '20px',
                                                                paddingLeft: '15px'
                                                            }}>
                                                                Показать    &#9660;
                                                            </div>}
                                                        </tr>
                                                        : null}


                                                    </tbody>
                                                </table>

                                                {show ?
                                                    <ImageList sx={{width: "100%", height:"auto"}} cols={4} >
                                                        {arRecommend2.map((item: any, index: number) => (
                                                            <ImageListItem
                                                                onClick={() => changeArElement(item.code)}
                                                                key={index}>
                                                                <img
                                                                    style={Width < WIDTH ? {
                                                                        width: "90%",
                                                                       height:  "20vw",
                                                                        cursor: "pointer",
                                                                    } : { width: "90px",
                                                                        height:  "90px",
                                                                        cursor: "pointer",}}
                                                                    src={item.img.tone}
                                                                    srcSet={item.img.tone}
                                                                    loading="lazy"
                                                                    alt=''
                                                                />
                                                                <div style={Width < WIDTH ? {fontSize: 12, textAlign: "center", paddingTop: 10, width: "90%", cursor: "pointer", paddingBottom: 10} :
                                                                    {fontSize: 12, textAlign: "center", paddingTop: 10, width: "90px", cursor: "pointer", paddingBottom: 10}}>
                                                                    {item.propsNoFilter.hueNumber}
                                                                </div>
                                                            </ImageListItem>
                                                        ))}
                                                    </ImageList> : null}



                                                <div style={{width: "94%",
                                                    height: 50,
                                                    textAlign: "center",
                                                    border: "1px solid grey",
                                                    paddingTop: 13,
                                                    color: "#d1a0a9",
                                                    cursor: "pointer"}} onClick={toggleFIeldset}>{!show ? "Показать все цвета" : "Свернуть"}</div>
                                            </div>


                                            <div key={'tabs-2'}
                                                 className={activeTab === 'tabs-2' ? "tabs__content is-open js--tabs-content" : "tabs__content js--tabs-content"}
                                                 id="tab-2">

                                                {productComposition !== '' ?
                                                    <p>
                                                        {productComposition}
                                                    </p>
                                                    : null}
                                            </div>
                                            <div key={'tabs-3'}
                                                 className={activeTab === 'tabs-3' ? "tabs__content is-open js--tabs-content" : "tabs__content js--tabs-content"}
                                                 id="tab-3">
                                                <table style={Width > WIDTH ? {width: 680} : {}}
                                                       className="single-product__options options-table">
                                                    <tbody>

                                                    <div className="form__item-group">
                                                        <p className="form__item">
                                                            <label className="form__label" htmlFor="">Адрес доставки</label>
                                                            <Select className="select-field"
                                                                    defaultValue={selectedOption?.Points}
                                                                    onChange={setSelectedOption?.Points}
                                                                    options={options?.Points}
                                                                    value={selectedOption?.Points}
                                                            />
                                                        </p>
                                                    </div>
                                                    {arrDeliveryTypeForProductPage && arrDeliveryTypeForProductPage.length && arrDeliveryTypeForProductPage.map((item: any) => (
                                                        <>
                                                            {item.deliveryTime &&
                                                            item.deliveryTime[0] &&
                                                            item.deliveryTime[0].clientShipDates &&
                                                            item.deliveryTime[0].clientShipDates.length > 0 && org_type !=="urlico" ? <>
                                                                    <tr key='prop-02'>
                                                                        <td><span className="options-table__key">{item.name === "Самовывоз" ? item.name + " (Минский р-н, ТЛЦ 'Щомыслица' 28/3)" : item.name}</span>
                                                                        </td>
                                                                        <td>{item.deliveryTime && item.deliveryTime[0]  ? item.deliveryTime[0].clientShipDates : ""}</td>
                                                                    </tr>
                                                                </>:
                                                                <tr key='prop-02'>
                                                                    <td><span className="options-table__key">{item.name === "Самовывоз" ? item.name + " (Минский р-н, ТЛЦ 'Щомыслица' 28/3)" : item.name}</span>
                                                                    </td>
                                                                    <td>{item.deliveryTime && item.deliveryTime[0]  ? item.deliveryTime[0].name : ""}</td>
                                                                </tr>

                                                            }
                                                        </>

                                                        // <tr key='prop-02'>
                                                        //     <td><span className="options-table__key">{item.name === "Самовывоз" ? item.name + " (Минский р-н, ТЛЦ 'Щомыслица' 28/3)" : item.name}</span>
                                                        //     </td>
                                                        //     <td>{item.deliveryTime && item.deliveryTime[0]  ? item.deliveryTime[0].name : ""}</td>
                                                        // </tr>

                                                    )) }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div   style={Width < 1023.99 ? {display: "contents"} : {}}    className="single-product__description js--spoiler-wrapper">
                                    <h2 className="single-product__description-title">Описание</h2>
                                    <p className="single-product__description-text ">

                                        {isActiveDec ?
                                            <>
                                                {productDescription ? <>{productDescription} <br/><br/></> : null}
                                            </>
                                            :
                                            <>
                                                {productDescription ? <>{productDescription.slice(0, 200) + ' ...'}
                                                    <br/><br/></> : null}
                                            </>
                                        }

                                        {isActiveDec ?
                                            <>
                                                {shortDescription ? <> <b>Краткое описание</b> <br/>{shortDescription}
                                                    <br/><br/></> : null}
                                                {brandDescription ? <> <b>Описание бренда </b> <br/>{brandDescription}
                                                    <br/><br/></> : null}
                                                {methodOfApplication ? <> <b>Способ применения
                                                    продукта </b><br/>{methodOfApplication} <br/><br/></> : null}
                                            </>
                                            : null}
                                    </p>
                                    <span onClick={(e) => pageProductStore.setActiveDic(isActiveDec)}
                                          className={isActiveDec ? "single-product__description-more is-active" : "single-product__description-more"}>
                                        {isActiveDec ? "Свернуть описание" : "Развернуть описание"}</span>
                                </div>

                                {/* Сочетается с продуктами: */}
                                <Recommended items={arRecommend}/>

                                <SingleProduct brand={brand} idBrand={idBrand} arFilterCatalogEmun={arFilterCatalogEmun}
                                               onPageBrandCheckedFromProduct={onPageBrandCheckedFromProduct}/>
                                <SliderForPrevProduct items={arPrevSawProduct}/>

                            </div>
                        </div>
                    </div>
                    <AnyQuestions/>
                </main>
            </>
        )
    }
}

// @ts-ignore
export default withRouter(ProductPage);
