
import React from 'react';
import {Redirect} from "react-router-dom";
import {withStore} from "../hocs";
import {observer} from "mobx-react";
import {withRouter} from "react-router";
import {AnyQuestions} from "../components/block/AnyQuestions";
import {Authorization} from "../components/modals/Authorization";

// @ts-ignore
@withStore
@observer
class ChangePage extends React.Component {
    componentDidMount() {
        // @ts-ignore
        const {store} = this.props;
        // @ts-ignore
        const code = this.props.match.params.code;
        store.pageChangeStore.init(code);
    }

    render() {

        // @ts-ignore
        const {store} = this.props;
        const {inputs, redirect,redirect404,email, error} = store.pageChangeStore;
        const {onSubmitForm} = store.pageChangeStore;

        return (
            <>
                {/*{redirect ? <Redirect to={"/personal/add-user-successful"} /> : null}*/}
                {redirect404 ? <Redirect to={"/404"} /> : null}

                <main className="page__main" style={redirect ? {opacity: 0.2}: {}}>
                    <div className="container">
                        <ul className="breadcrumbs">
                            <li className="breadcrumbs__item"><a className="breadcrumbs__link" href="/">Главная</a></li>
                            <li className="breadcrumbs__item"> Сменить пароль</li>
                        </ul>
                        <h1 className="page__title">Сменить пароль {email}</h1>
                        <div className="cooperation-order">
                            <div className="cooperation-order__content pageChange">
                                {error.length ? <div className="input-box__error-text">{error}</div> : null}
                                <form className="cooperation-order__form form" onSubmit={(e) => onSubmitForm(e)} >
                                    <input type="hidden" name="siteIds" value="4" />
                                    <div className="">
                                        <p className="form__item" >
                                            <label className="form__label" htmlFor="">{inputs.password.title}</label>
                                        <input
                                            value={inputs.password.value}
                                            required={inputs.password.type !== 'text2'}
                                            list={'list-' + inputs.password.code}
                                            onChange={(e) => store.pageChangeStore.onChangeInputPassword(e, inputs.password)}
                                            className="form__field text-field"
                                            type={inputs.password.type}
                                            placeholder={inputs.password.tooltip}
                                        />
                                            {inputs.password.error.length ?
                                                <span className="input-box__error-text">{inputs.password.error}</span>
                                                : null}
                                        </p>

                                        <p className="form__item" >
                                            <label className="form__label" htmlFor="">{inputs.password2.title}</label>
                                        <input
                                            value={inputs.password2.value}
                                            required={inputs.password2.type !== 'text2'}
                                            list={'list-' + inputs.password2.code}
                                            onChange={(e) => store.pageChangeStore.onChangeInputPassword(e, inputs.password2)}
                                            className="form__field text-field"
                                            type={inputs.password2.type}
                                            placeholder={inputs.password2.tooltip}
                                        />

                                            {inputs.password2.error.length ?
                                                <span className="input-box__error-text">{inputs.password2.error}</span>
                                                : null}
                                        </p>

                                    </div>

                                    <div className="box-buttom--change">
                                        <button className="button cooperation-order__form-button button--accent-dark"
                                                type="submit">Сменить
                                        </button>
                                        <button className="button cooperation-order__form-button button--accent-dark"
                                                onClick={(e) => store.appStore.setPopupAuthorization(true)}
                                                type="button">Авторизация
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                    <AnyQuestions/>
                </main>
                <Authorization isPopupAuthorization={redirect} isPopupPasswordRecovery={false}/>

            </>
        )
    }
}

// @ts-ignore
export default withRouter(ChangePage);
