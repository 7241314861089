// export enum EnumUserType {
//     'root' = "root",
//     'ourAdmin' = 'ourAdmin',
//     'ourUser' = 'ourUser',
//     'clientAdmin' = 'clientAdmin',
//     'clientLPR' = 'clientLPR',
//     'clientUser' = 'clientUser'
// }
export enum EnumUserType {
    'root' = "root",
    'ourAdmin' = 'ourAdmin',
    'ourUser' = 'ourUser',
    'clientAdmin' = 'clientAdmin',
    'clientLPR' = 'clientLPR',
    'clientUser' = 'clientUser',
    'adminAuth' = 'adminAuth'
}