import React from 'react';
import {withStore} from "../../hocs";
import {observer} from "mobx-react";
import InputMask from "react-input-mask";


interface Props {
    isPopupAuthorization: boolean;
    isPopupPasswordRecovery: boolean;
}


// @ts-ignore
@withStore
@observer
export class Authorization extends React.Component<Props, {}> {

    render() {
        // @ts-ignore
        let isPopupAuthorization = this.props.isPopupAuthorization;
        let isPopupPasswordRecovery = this.props.isPopupPasswordRecovery;
        // @ts-ignore
        const {store} = this.props;

        const {
            email,
            password,
            error,
            onSubmitFormLogin,
            onChangePassword,
            onChangeEmail,
        } = store.authStore;
        const {
            emailRecovery,
            errorRecovery,
            onChangeEmailRecovery,
            onSubmitFormRecovery,
            okRecovery,
            SetOkRecovery
        } = store.authStore;

        const closeModal = ()=>{
            store.appStore.setPopupAuthorization(false)
            if (window.location.pathname !== "/"){
                window.location.replace("/")
            }
        }


        return (
            <>
                <div className={isPopupAuthorization ? "modal is-open" : "modal"} id="authorization"
                     data-modal="authorization">
                    <button className="modal__close js--modal-close"
                            onClick={(e) => closeModal() }
                            type="button">
                        <span className="visually-hidden">Закрыть модальное окно</span>
                        <span className="modal__close-icon"/></button>
                    <div className="modal__body">
                        <h2 className="modal__title">Вход</h2>
                        <form className="form modal__form" method="post" onSubmit={(e) => onSubmitFormLogin(e)}>
                            <input type="hidden" required name="siteId" value="4"/>
                            <p className="form__item">
                                <label className="visually-hidden" htmlFor="login">Email</label>
                                <input className="text-field"
                                       value={email} onChange={(e) => onChangeEmail(e)}
                                       type="email" required placeholder="Email" id="login"/>
                            </p>
                            <p className="form__item">
                                <label className="visually-hidden" htmlFor="pass">Пароль</label>
                                <input className="text-field"
                                       value={password}
                                       onChange={(e) => onChangePassword(e)}
                                       type="password" required placeholder="Пароль" id="pass"/>
                            </p>
                            <span
                                className={error.length ? 'input-box__error-text' : 'input-box__error-text none'}>{error}</span>
                            <button className="button button--accent-dark modal__button modal__button--submit"
                                    type="submit">Войти
                            </button>
                            <div onClick={() => SetOkRecovery(false)}
                               className="button button--accent modal__button js--open-modal passwordrecovery"
                               data-modal="passwordrecovery">Забыли пароль?</div>
                        </form>
                    </div>
                    <div className="modal__footer">
                        <p className="modal__sub-title">Еще не наш клиент?</p>
                        <p>Для авторизации необходимо подать заявку на сотрудничество</p>
                        <a className="button button--accent-dark modal__button" href="/cooperation">Подать заявку</a>
                    </div>
                </div>
                {okRecovery ?
                    <div className={isPopupPasswordRecovery ? "modal is-open" : "modal"} data-modal="passwordrecovery">
                        <button className="modal__close js--modal-close"
                                onClick={(e) => store.appStore.setPopupPasswordRecovery(false)}
                                type="button">
                            <span className="visually-hidden">Восстановление пароля</span>
                            <span className="modal__close-icon"/>
                        </button>
                        <div className="modal__body">
                            <h2 className="modal__title">Пароль отправлен вам на почту</h2>
                            <form className="form modal__form">
                                <p className="form__item">
                                    Ссылка на восстановления пароля была отправлена вам на почту!
                                </p>
                            </form>
                        </div>
                    </div>
                    :
                    <div className={isPopupPasswordRecovery ? "modal is-open" : "modal"} data-modal="passwordrecovery">
                        <button className="modal__close js--modal-close"
                                onClick={(e) => store.appStore.setPopupPasswordRecovery(false)}
                                type="button">
                            <span className="visually-hidden">Восстановление пароля</span>
                            <span className="modal__close-icon"/></button>
                        <div className="modal__body">
                            <h2 className="modal__title">Восстановление пароля</h2>
                            <form className="form modal__form" onSubmit={(e) => onSubmitFormLogin(e)}>
                                <input type="hidden" name="siteIds" value="4"/>
                                <p>Пожалуйста введите свой email или телефон начиная с '+', чтобы получать ссылку на восстановление пароля</p>
                                <p className="form__item">
                                    <label className="visually-hidden" htmlFor="name">E-mail</label>
                                    {emailRecovery.includes("+") ? <InputMask
                                        value={emailRecovery}
                                        mask='+375 (99) 999-99-99'
                                        onChange={(e) => onChangeEmailRecovery(e)}
                                        className="text-field text-field--border" type="text"
                                        placeholder={emailRecovery}
                                        id="value-4"
                                    /> : <input className="text-field" type="text" placeholder="E-mail или телефон" id="email"
                                                onChange={(e) => onChangeEmailRecovery(e)}
                                                value={emailRecovery}/>}
                                    <span
                                        className={errorRecovery.length ? 'input-box__error-text' : 'input-box__error-text none'}>{errorRecovery}</span>
                                </p>
                                <button className="button button--accent-dark modal__button modal__button--submit"
                                        onClick={() => onSubmitFormRecovery()}
                                        type="button">Отправить
                                </button>
                            </form>
                        </div>
                    </div>
                }
            </>
        )
    }
}
