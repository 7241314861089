import React from 'react';
import {withStore} from "../../hocs";
import {observer} from "mobx-react";
import InputMask from 'react-input-mask';

// @ts-ignore
@withStore
@observer
export class AnyQuestions extends React.Component {

    render() {
        // @ts-ignore
        const {store} = this.props;

        const {
            onChangeNameForBitrix,
            onChangePhoneForBitrix,
            onChangeTestForBitrix,
            sendMessage,
            isModalOpen,
            onCloseModal,
            phoneForBitrix,
            nameForBitrix,
            textForBitrix
        } = store?.pagePersonalStore;


        return (
            <>
                <section className="any-questions">
                    <div className="container">
                        <h2 className="any-questions__title">Остались вопросы?</h2>
                        <p className="any-questions__description">Мы вам перезвоним!</p>
                        <div className="form any-questions__form">
                            <p className="form__item">
                                <label className="visually-hidden" htmlFor="person-name" />
                                <input value={nameForBitrix} className="text-field" type="text" placeholder="Ваше имя" id="person-name" onChange={event => (onChangeNameForBitrix(event))} />
                            </p>
                            <p className="form__item">
                                <label className="visually-hidden" htmlFor="person-phone" />

                                <InputMask
                                    value={phoneForBitrix}
                                    mask='+375 (99) 999-99-99'
                                    onChange={(e) => onChangePhoneForBitrix(e)}
                                    className="text-field" type="text"
                                    placeholder= 'Телефон'
                                    id="person-phone"
                                />

                                {/*<input value={phoneForBitrix} className="text-field" type="text" placeholder="Телефон" id="person-phone" onChange={event => (onChangePhoneForBitrix(event))} />*/}
                            </p>
                            <p className="form__item">
                                <label className="visually-hidden" htmlFor="person-test" />
                                <input value={textForBitrix} className="text-field" type="text" placeholder="Текст сообщения" id="person-text" onChange={event => (onChangeTestForBitrix(event))} />
                            </p>

                            <button className="button button--accent-light any-questions__button" onClick={()=> sendMessage()}>
                                Отправить
                            </button>
                        </div>
                    </div>
                </section>

                <div className={isModalOpen ? "modal modal--success is-open" : "modal modal--success"}  data-modal="isModalOpen2">
                    <button className="modal__close js--modal-close"
                            onClick={() => onCloseModal()}
                            type="button">
                        <span className="visually-hidden">Закрыть модальное окно</span>
                        <span className="modal__close-icon" />
                    </button>
                    <div className="modal__body modal__body--clear">
                        <svg className="modal__icon" width="31" height="27">
                            <use xlinkHref="/images/sprite.svg#icon--comment"/>
                        </svg>
                        <h2 className="modal__title modal__title--order-success accent-text">Внимание!</h2>
                        <p className="modal__body-text">
                            Для отправки сообщения необходимо заполнить все поля
                        </p>
                        <span className="button button--accent-dark modal__button js--modal-close"
                              onClick={() => onCloseModal()}>Вернуться</span>
                    </div>
                </div>
            </>
        )
    }
}
