import React from 'react';
import {GoodsItem} from "./GoodsItem";

interface Props {
    items: [];
}

export class Recommended extends React.Component<Props, {}> {
    // @ts-ignore
    addDefaultSrc = ev => {
        ev.target.src = '/images/pic--goods-0.jpg'
    }

    render() {

        let arData= this.props.items;


        return (
            <>
                {arData.length > 0 ?
                    <div className="single-product__recommended">
                        <p className="single-product__sub-title">Сочетается с продуктами:</p>
                        <div className="goods-catalog">
                            <div className="goods-catalog__list goods-catalog__list--grid">
                                {arData.map((item: any, index: number) => (
                                    <GoodsItem key={index} item={item} width="1" type={"Сочетается с продуктами"}/>
                                ))}
                            </div>
                        </div>
                    </div>
                : null}
            </>
        );
    }
}
