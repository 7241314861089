import React from 'react';
import {Link} from "react-router-dom";

import {observer} from 'mobx-react';
import {withStore} from "../../hocs";
import s from "./adminTop.module.css"
import {EnumUserType} from "../../enum/EnumUserType";


// @ts-ignore
@withStore
@observer
export class AdminTop extends React.Component {
    render() {

        // @ts-ignore
        const {store} = this.props;
        const { onLoginRoot, userType, parentId, parentEmail, email} = store?.mainStore;


        let isAdmin = false;
        if (userType === EnumUserType.adminAuth || userType === EnumUserType.root) {
            isAdmin = true;
        }
        if (userType === EnumUserType.adminAuth || parentId !== '') {
        return (
            <>
                <div className={s.lightWhite}>
                    <div className={s.container}>
                        <div className={s.adminsPanel}>
                            {userType === EnumUserType.adminAuth ? <div><Link style={{fontSize: 16}}   className={s.littlePhonetext} to={'/admin/'}> Администрирование </Link></div> : <span>{null}</span>}

                            {parentEmail && !isAdmin  ? <span >

                                    <span className={s.littleParent} onClick={()=>onLoginRoot(parentId)}>{parentEmail} - </span>
                                    <span style={{color: "white"}}  className={s.breadcrumbs__item_text_active}>{email}</span>

                            </span> : <span style={{fontSize: 16}} className={s.littlePhonetext2}>{email}</span>}

                        </div>
                    </div>
                </div>
            </>
        );
    }
        return null;
    }
}


