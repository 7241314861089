import React from 'react';
// @ts-ignore
import Slider from "react-slick";
import {APP_NO_IMG} from "../../const/root";
import {WIDTH} from "../../const/root";

interface Props {
    img: [];
    icon: boolean;
    deleteFavorite: any;
    addFavorite: any;
    id: any;
    clientId: any;
    userId: any;
}

export class SliderProduct extends React.Component<Props, {}> {

    slider1: Slider | undefined;
    slider2: Slider | undefined;

    state = {
        photoIndex: 0,
        isOpen: false,
        nav1: undefined,
        nav2: undefined
    };

    constructor(props: any) {
        super(props);
        this.state = {
            photoIndex: 0,
            isOpen: false,
            nav1: undefined,
            nav2: undefined
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    // @ts-ignore
    addDefaultSrc = ev => {
        ev.target.src = APP_NO_IMG;
    }

    render() {

        let img: any = this.props.img;
        let icon: boolean = this.props.icon;
        let addFavorite: any = this.props.addFavorite;
        let deleteFavorite: any = this.props.deleteFavorite;
        let id: any = this.props.id;
        let clientId: any = this.props.clientId;
        let userId: any = this.props.userId;


        let slickPrev =()=> this.slider2?.slickPrev()
        let slickNext =()=> this.slider2?.slickNext()

        let setting2 = {
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
            centerMode: true,
            vertical: false,
            focusOnSelect: true,
            arrows: false,
        }

        let setting1 = {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            fade: true,

        }

        let Width = window.innerWidth;

        // @ts-ignore
        return (
            <>


                <div style={Width<WIDTH ? {width: '100%',  display: "flex", justifyContent: "space-around"}: {}} className="container-slider-my">

                    <div className="single-product__gallery-my">

                            <Slider
                                className="gallery__slider"
                                asNavFor={this.state.nav2}
                                ref={
                                    // @ts-ignore
                                    slider => (this.slider1 = slider)
                                }
                                {...setting1}
                            >

                                {img?.big.length ?
                                    img.big.map((src: string, index: any) => (

                                            <div  className="big-img-slin" key={index}>
                                                {
                                                    icon ?
                                                        <span style={{backgroundImage: `url("../images/bg--is-favorite.png")`,position: "absolute", zIndex: 1}}
                                                              onClick={ () => deleteFavorite(id, userId, clientId)}
                                                              className="goods__favorite js--favorite-toggle"/>
                                                        : <span style={{ position: "absolute", zIndex: 2}}   onClick={ () => addFavorite(id, userId, clientId)} className="goods__favorite js--favorite-toggle"/>
                                                }
                                                <img style={Width<WIDTH ? {display: "block", marginLeft: "auto", marginRight: "auto", width: 150 }: {}} onError={this.addDefaultSrc}
                                                     src={src+ '?p=' + Math.round(Math.round(Math.random()*1000)/100)}
                                                     alt="preview.png"/>

                                            </div>

                                    ))
                                    :
                                    <div className="big-img-slin" >
                                        {
                                            icon ?
                                                <span style={{backgroundImage: `url("../images/bg--is-favorite.png")`, position: "absolute", zIndex: 1}}
                                                      onClick={ () => deleteFavorite(id, userId, clientId)}

                                                      className="goods__favorite js--favorite-toggle"/>

                                                : <span className="goods__favorite js--favorite-toggle" style={{position: "absolute", zIndex: 2}}   onClick={ () => addFavorite(id, userId, clientId)} />
                                        }
                                        <img style={Width<WIDTH ? {display: "block", marginLeft: "auto", marginRight: "auto", width: 150 }: {} } onError={this.addDefaultSrc}
                                             src={APP_NO_IMG}
                                             alt="main.png"/>
                                    </div>
                                }

                                {img?.tone && img?.tone.length > 0 ?
                                        <div className="big-img-slin">
                                            {
                                                icon ?
                                                    <span style={{backgroundImage: `url("../images/bg--is-favorite.png")`, position: "absolute", zIndex: 1}}
                                                          onClick={ () => deleteFavorite(id, userId, clientId)}

                                                          className="goods__favorite js--favorite-toggle"/>

                                                    : <span className="goods__favorite js--favorite-toggle" style={{position: "absolute", zIndex: 2}}   onClick={ () => addFavorite(id, userId, clientId)} />
                                            }
                                            <img style={Width<WIDTH ? {display: "block", marginLeft: "auto", marginRight: "auto", width: 150 }: {} } onError={this.addDefaultSrc}
                                                 src={img.tone[0]+ '?p=' + Math.round(Math.round(Math.random()*1000)/100)}
                                                 alt="preview.png"/>
                                        </div>
                                    :null}
                            </Slider>

                    </div>
                    <div className="scroll-gallery__carousel-my">
                        <div className='scroll-gallery__carousel-box-my'>
                           { <button
                                onClick={()=>slickPrev()}
                                className="navigation-control scroll-gallery__control scroll-gallery__control--left "
                                type="button"><span className="visually-hidden">Предыдущий слайд</span>
                                <svg className="navigation-control__icon" width="7" height="12">
                                    <use xlinkHref="/images/sprite.svg#icon--arrow-left"></use>
                                </svg>
                            </button>}

                            <Slider className=""
                                    asNavFor={this.state.nav1}
                                    ref={
                                        // @ts-ignore
                                        slider => (this.slider2 = slider)
                                    }
                                    {...setting2}
                            >
                                {img?.small.length ?
                                    img.small.map((src: string, index: any) => (
                                        <div className="min-img-slin" key={index}>
                                            <img onError={this.addDefaultSrc}
                                                 src={src+ '?p=' + Math.round(Math.round(Math.random()*1000)/100)}
                                                 alt="preview.png"/>
                                        </div>
                                    ))
                                    :
                                    <div className="min-img-slin">
                                        <img src={APP_NO_IMG} alt="preview.png"/>
                                    </div>
                                }
                                {img?.tone && img?.tone.length > 0 ?
                                    <div className="big-img-slin">
                                        <img onError={this.addDefaultSrc}
                                             src={img.tone[0]+ '?p=' + Math.round(Math.round(Math.random()*1000)/100)}
                                             alt="preview.png"/>
                                    </div>
                                    :null}
                            </Slider>

                            {<button
                                onClick={()=>slickNext()}
                                className="navigation-control scroll-gallery__control scroll-gallery__control--right "
                                type="button"><span className="visually-hidden">Следующий слайд</span>
                                <svg className="navigation-control__icon" width="7" height="12">
                                    <use xlinkHref="/images/sprite.svg#icon--arrow-right"></use>
                                </svg>
                            </button>}

                        </div>

                    </div>
                </div>

            </>

        );
    }
}


