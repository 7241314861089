// @ts-ignore
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {GoodsItem} from "../catalog/GoodsItem";
import React from "react";

const SliderForPrevProduct = (props: any) => {

    let array = props.items

    let Width = window.innerWidth
    let slidesToShow = 4

   if (Width < 1023.99 && Width > 767.99){
       slidesToShow = 3
   }else if (Width < 767.99 ){
       slidesToShow = 2
   }
    let settings = {
        dots: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToShow,
        infinite: true,
    };

    return (
        <>
            {array && array.length < 5  ?
                <div className="single-product__recommended">
                    <p className="single-product__sub-title">Недавно просмотренные товары:</p>
                    <div className="goods-catalog">
                        <div className="goods-catalog__list goods-catalog__list--grid">
                            {array.map((item: any, index: number) => (
                                <GoodsItem key={index} item={item} width="1" type={"Недавно просмотренные товары"}/>
                            ))}
                        </div>
                    </div>
                </div>
                :   <div style={{width: "100%", height: 700}}>
                    <div className="single-product__recommended">
                        <p className="single-product__sub-title">Недавно просмотренные товары:</p>
                        <Slider {...settings}>
                            {array.map((item: any, index: number) => (
                                <div className="goods-catalog"  >
                                    <div className="goods-catalog__list goods-catalog__list--grid">
                                        <GoodsItem key={index} item={item} width={array.length < 5 ? "1" : "450px"} type={"Недавно просмотренные товары"} />
                                    </div>
                                </div>
                            ))}

                        </Slider>
                    </div>
                </div>}

        </>



    );
};

export default SliderForPrevProduct;