import axios from "axios";

export class Api {
    instance;
    subUrl;
    token;
    dopUrl;
    baseURL;

    constructor(subUrl = "", dopUrl = "") {
        this.initToken();
        this.setInstance();
        this.subUrl = subUrl;
        this.dopUrl = dopUrl;
        this.baseURL = '';
    }

    setDopUrl(dopUrl) {
        this.dopUrl = dopUrl;
    }

    setInstance() {
        this.setBaseUrl();
        this.instance = this.initInstance()
    }



    setBaseUrl() {
        let protocol = 'http:';
        if (window.location.protocol === 'https:') {
            protocol = 'https:';
        }
        let host = 'devnest.sellwin.by';
        if (window.location.host === "beautyhouse.by" ) {
            host = 'nest.sellwin.by';
        }
        if (window.location.host === "localhost:3000") {
            host = 'localhost:5000';
            // host = 'localhost:5000';
            // host = 'devnest.sellwin.by';
        }
        //host = 'nest.sellwin.by';
        this.baseURL = protocol + '//' + host;
    }


    reload() {
        this.initToken();
        this.instance = this.initInstance();
    }

    initToken() {
        this.token = localStorage.getItem("token");
    }

    initInstance() {
        return axios.create({
            baseURL: this.baseURL,
            timeout: 60000,
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: "Bearer " + this.token,
            },
            mode: "cors",
            crossdomain: true,
        });
    }

    // CRUD

    create(data, thenFunction, errorFunction) {
        this.promise(
            this.instance.post(this.subUrl + this.dopUrl, data),
            thenFunction,
            errorFunction
        );
    }

    read(data, thenFunction, errorFunction) {
        this.promise(
            this.instance.get(this.subUrl + this.dopUrl, {params: data}),
            thenFunction,
            errorFunction
        );
    }

    update(data, thenFunction, errorFunction, config = {}) {
        this.promise(
            this.instance.put(this.subUrl + this.dopUrl, data, config),
            thenFunction,
            errorFunction
        );
    }

    delete(data, thenFunction, errorFunction, params = null) {
        this.promise(
            this.instance.delete(this.subUrl + this.dopUrl, {
                data: data,
                params: params,
            }),
            thenFunction,
            errorFunction
        );
    }

    promise(p, thenFunction, errorFunction) {
        p.then((result) => {
            thenFunction(result)
        }).catch((error) => {
            errorFunction(error)
        });
    }

    uploadFile = (formData, thenFunction, errorFunction) => {
        axios.post(this.baseURL+this.subUrl + this.dopUrl, formData, {
            headers: {
                "Content-type": "multipart/form-data",
                Authorization: "Bearer " + this.token,
            },
            mode: "cors",
            crossdomain: true,
        })
            .then((response) => {
                thenFunction(response);
            })
            .catch((error) => {
                errorFunction(error)
            });
    }

    uploadFileBeautyhouse = (formData, thenFunction, errorFunction) => {
        axios.post(this.baseURL+this.subUrl + this.dopUrl, formData, {
            headers: {
                "Content-type": "multipart/form-data",
                Authorization: "Bearer " + this.token,
            },
            mode: "cors",
            crossdomain: true,
        })
            .then((response) => {
                thenFunction(response);
            })
            .catch((error) => {
                errorFunction(error)
            });
    }

}
