import {RootStore} from '../index';
import {action, makeAutoObservable} from "mobx";
import ContentModel from "../../model/ContentModel";
import BHContentModel from "../../model/BHContentModel";



export class PalletStore {

    rowsPallet = [
        {"title": "Натуральные базовые оттенки (Natural)",
            array: [
                {
                    "id": 50055,
                    "name": "MAJIREL GLOW L`Oreal Professionnel",
                    "tone": "https://lorealprofessionnel.ru/media/wysiwyg/palitra/INOA__10_31.png",
                    "hueNumber": "1KV",
                    "linkProduct": "matrix_socolor_sync_pre_bonded_krem_kraska_dlya_volos_7va_90ml_9351060334"
                },
                {
                    "id": 50056,
                    "name": "MAJIREL GLOW L`Oreal Professionnel",
                    "tone": "https://lorealprofessionnel.ru/media/wysiwyg/palitra/INOA__9_32.png",
                    "hueNumber": "2KV",
                    "linkProduct": "matrix_socolor_sync_pre_bonded_krem_kraska_dlya_volos_7va_90ml_9351060334"
                },
                {
                    "id": 50057,
                    "name": "MAJIREL GLOW L`Oreal Professionnel",
                    "tone": "https://lorealprofessionnel.ru/media/wysiwyg/palitra/INOA_9_31.png",
                    "hueNumber": "3KV",
                    "linkProduct": "matrix_socolor_sync_pre_bonded_krem_kraska_dlya_volos_7va_90ml_9351060334"
                },
                {
                    "id": 50058,
                    "name": "MAJIREL GLOW L`Oreal Professionnel",
                    "tone": "https://lorealprofessionnel.ru/media/wysiwyg/palitra/INOA_8_32.png",
                    "hueNumber": "4KV",
                    "linkProduct": "matrix_socolor_sync_pre_bonded_krem_kraska_dlya_volos_7va_90ml_9351060334"
                },{
                    "id": 50059,
                    "name": "MAJIREL GLOW L`Oreal Professionnel",
                    "tone": "https://lorealprofessionnel.ru/media/wysiwyg/palitra/INOA__8_31.png",
                    "hueNumber": "5KV",
                    "linkProduct": "matrix_socolor_sync_pre_bonded_krem_kraska_dlya_volos_7va_90ml_9351060334"
                },{
                    "id": 50060,
                    "name": "MAJIREL GLOW L`Oreal Professionnel",
                    "tone": "https://lorealprofessionnel.ru/media/wysiwyg/palitra/INOA__7_42.png",
                    "hueNumber": "6KV",
                    "linkProduct": "matrix_socolor_sync_pre_bonded_krem_kraska_dlya_volos_7va_90ml_9351060334"
                },
                {
                    "id": 50061,
                    "name": "MAJIREL GLOW L`Oreal Professionnel",
                    "tone": "https://lorealprofessionnel.ru/media/wysiwyg/palitra/INOA_7_35.png",
                    "hueNumber": "7KV",
                    "linkProduct": "matrix_socolor_sync_pre_bonded_krem_kraska_dlya_volos_7va_90ml_9351060334"
                },
                {
                    "id": 50062,
                    "name": "MAJIREL GLOW L`Oreal Professionnel",
                    "tone": "https://lorealprofessionnel.ru/media/wysiwyg/palitra/INOA_7_32.png",
                    "hueNumber": "8KV",
                    "linkProduct": "matrix_socolor_sync_pre_bonded_krem_kraska_dlya_volos_7va_90ml_9351060334"
                },
                {
                    "id": 50063,
                    "name": "MAJIREL GLOW L`Oreal Professionnel",
                    "tone": "https://lorealprofessionnel.ru/media/wysiwyg/palitra/INOA_7_31.png",
                    "hueNumber": "9KV",
                    "linkProduct": "matrix_socolor_sync_pre_bonded_krem_kraska_dlya_volos_7va_90ml_9351060334"
                },
            ]
        },
        {"title": "Коричневые/бежевые теплые оттенки",
            array: [
                {
                    "id": 50067,
                    "name": "MAJIREL GLOW L`Oreal Professionnel",
                    "tone": "https://lorealprofessionnel.ru/media/wysiwyg/palitra/INOA_5_23.png",
                    "hueNumber": "2MN",
                    "linkProduct": "matrix_socolor_sync_pre_bonded_krem_kraska_dlya_volos_7va_90ml_9351060334"},
                {
                    "id": 50068,
                    "name": "MAJIREL GLOW L`Oreal Professionnel",
                    "tone": "https://lorealprofessionnel.ru/media/wysiwyg/palitra/INOA_5_15.png",
                    "hueNumber": "3MN",
                    "linkProduct": "matrix_socolor_sync_pre_bonded_krem_kraska_dlya_volos_7va_90ml_9351060334"
                },
                {
                    "id": 50069,
                    "name": "MAJIREL GLOW L`Oreal Professionnel",
                    "tone": "https://lorealprofessionnel.ru/media/wysiwyg/palitra/INOA_4_15.png",
                    "hueNumber": "4MN",
                    "linkProduct": "matrix_socolor_sync_pre_bonded_krem_kraska_dlya_volos_7va_90ml_9351060334"
                },
                {
                    "id": 50070,
                    "name": "MAJIREL GLOW L`Oreal Professionnel",
                    "tone": "https://lorealprofessionnel.ru/media/wysiwyg/palitra/INOA_3_15.png",
                    "hueNumber": "5MN",
                    "linkProduct": "matrix_socolor_sync_pre_bonded_krem_kraska_dlya_volos_7va_90ml_9351060334"
                }
                ]
        },
    ];

    objLoreal = [
        // {
        //     mainTitle: "Палитра оттенков Inoa (Иноа)",
        //     title: "Палитра оттенков Inoa (Иноа)",
        //     subTitle: "",
        //     show: false
        // },
        {
            mainTitle: "Палитра оттенков Inoa (Иноа)",
            title: "Натуральные базовые оттенки (Natural)",
            subTitle: "",
            show: false

        },{
            mainTitle: "Палитра оттенков Inoa (Иноа)",
            title: "Коричневые/бежевые теплые оттенки",
            subTitle: "",
            show: false
        },{
            mainTitle: "Палитра оттенков Inoa (Иноа)",
            title: "Коричневые/бежевые холодные оттенки",
            subTitle: "",
            show: false
        },{
            mainTitle: "Палитра оттенков Inoa (Иноа)",
            title: "Оттенки мокка (Mocha)",
            subTitle: "",
            show: false
        },{
            mainTitle: "Палитра оттенков Inoa (Иноа)",
            title: "Перламутровые оттенки (Violet)",
            subTitle: "",
            show: false
        },{
            mainTitle: "Палитра оттенков Inoa (Иноа)",
            title: "Пепельные оттенки (Ash)",
            subTitle: "",
            show: false
        },{
            mainTitle: "Палитра оттенков Inoa (Иноа)",
            title: "Красные оттенки (Red)",
            subTitle: "",
            show: false
        },{
            mainTitle: "Палитра оттенков Inoa (Иноа)",
            title: "Медные оттенки (Cooper)",
            subTitle: "",
            show: false
        },{
            mainTitle: "Палитра оттенков Inoa (Иноа)",
            title: "Металлизированные оттенки",
            subTitle: "",
            show: false
        },{
            mainTitle: "Палитра оттенков Majirel (Мажирель)",
            title: "Базовые (Fundamental)",
            subTitle: "",
            show: false
        },{
            mainTitle: "Палитра оттенков Majirel (Мажирель)",
            title: "Пепельные (Ash)",
            subTitle: "",
            show: false
        },{
            mainTitle: "Палитра оттенков Majirel (Мажирель)",
            title: "Перламутровые (Iridescent)",
            subTitle: "",
            show: false
        },{
            mainTitle: "Палитра оттенков Majirel (Мажирель)",
            title: "Золотистые оттенки (Gold)",
            subTitle: "",
            show: false
        },{
            mainTitle: "Палитра оттенков Majirel (Мажирель)",
            title: "Медные оттенки",
            subTitle: "",
            show: false
        },{
            mainTitle: "Палитра оттенков Majirel (Мажирель)",
            title: "Красные и интенсивные красные оттенки",
            subTitle: "",
            show: false
        },{
            mainTitle: "Палитра оттенков Majirel (Мажирель)",
            title: "Мокка (Mocha)",
            subTitle: "",
            show: false
        },{
            mainTitle: "Палитра оттенков Majirel (Мажирель)",
            title: "Коричневые / Бежевые теплые оттенки",
            subTitle: "",
            show: false
        },{
            mainTitle: "Палитра оттенков Majirel (Мажирель)",
            title: "Коричневые / Бежевые холодные оттенки",
            subTitle: "",
            show: false
        },{
            mainTitle: "Majirel Cool Inforced (Кул Инфорсед)",
            title: "Majirel Cool Inforced (Кул Инфорсед)",
            subTitle: "",
            show: false
        },
        {
            mainTitle: "Majirel High-lift (Хай-Лифт)",
            title: "Majirel High-lift (Хай-Лифт)",
            subTitle: "",
            show: false
        },
        // {
        //     mainTitle: "Majirel Cool-cover (Кул-Кавер)",
        //     title: "Majirel Cool-cover (Кул-Кавер)",
        //     subTitle: "",
        //     show: false
        // },
        {
            mainTitle: "Majirel Cool-cover (Кул-Кавер)",
            title: "Базовые (Fundamental)",
            subTitle: "",
            show: false
        },{
            mainTitle: "Majirel Cool-cover (Кул-Кавер)",
            title: "Пепельные (Ash)",
            subTitle: "",
            show: false
        },{
            mainTitle: "Majirel Cool-cover (Кул-Кавер)",
            title: "Золотистые (Gold)",
            subTitle: "",
            show: false
        },{
            mainTitle: "Majirel Cool-cover (Кул-Кавер)",
            title: "Матовые (Matte)",
            subTitle: "",
            show: false
        },{
            mainTitle: "Majirel Cool-cover (Кул-Кавер)",
            title: "Мокка (Mocha)",
            subTitle: "",
            show: false
        },{
            mainTitle: "Majirel Glow (Глоу)",
            title: "Majirel Glow (Глоу)",
            subTitle: "",
            show: false
        },{
            mainTitle: "Majirel Glow (Глоу)",
            title: "Light Bases",
            subTitle: "",
            show: false
        },{
            mainTitle: "Majirel Mix (Микстона Мажирель микс)",
            title: "Majirel Mix (Микстона Мажирель микс)",
            subTitle: "",
            show: false
        },{
            mainTitle: "Majirel Mix (Микстона Мажирель микс)",
            title: "Majirel High Resist",
            subTitle: "",
            show: false
        },{
            mainTitle: "Dia Light",
            title: "Натуральные базовые оттенки",
            subTitle: "",
            show: false
        },{
            mainTitle: "Dia Light",
            title: "Пепельные оттенки",
            subTitle: "",
            show: false
        },{
            mainTitle: "Dia Light",
            title: "Коричневые холодные оттенки",
            subTitle: "",
            show: false
        },{
            mainTitle: "Dia Light",
            title: "Молочные коктейли",
            subTitle: "",
            show: false
        },{
            mainTitle: "Dia Light",
            title: "Бежевые холодные оттенки",
            subTitle: "",
            show: false
        },{
            mainTitle: "Dia Light",
            title: "Мокка",
            subTitle: "",
            show: false
        },{
            mainTitle: "Dia Light",
            title: "Бежевые теплые",
            subTitle: "",
            show: false
        },{
            mainTitle: "Dia Light",
            title: "Красные, медные, сливовые",
            subTitle: "",
            show: false
        },
        // {
        //     mainTitle: "Dia Richesse",
        //     title: "Dia Richesse",
        //     subTitle: "",
        //     show: false
        // },
        {
            mainTitle: "Dia Richesse",
            title: "Натуральные базовые оттенки",
            subTitle: "",
            show: false
        },{
            mainTitle: "Dia Richesse",
            title: "Пепельные оттенки",
            subTitle: "",
            show: false
        },{
            mainTitle: "Dia Richesse",
            title: "Коричневые холодные оттенки",
            subTitle: "",
            show: false
        },{
            mainTitle: "Dia Richesse",
            title: "Мокка",
            subTitle: "",
            show: false
        },{
            mainTitle: "Dia Richesse",
            title: "Коричневые теплые оттенки",
            subTitle: "",
            show: false
        },{
            mainTitle: "Dia Richesse",
            title: "Молочные коктейли",
            subTitle: "",
            show: false
        },{
            mainTitle: "Dia Richesse",
            title: "Бежевые холодные оттенки",
            subTitle: "",
            show: false
        },{
            mainTitle: "Dia Richesse",
            title: "Бежевые теплые",
            subTitle: "",
            show: false
        },{
            mainTitle: "Dia Richesse",
            title: "Красные, медные, сливовые",
            subTitle: "",
            show: false
        },{
            mainTitle: "Dia Richesse",
            title: "Перламутровые",
            subTitle: "",
            show: false
        },
        {
            mainTitle: "Dia Color",
            title: "Краситель-блеск",
            subTitle: "",
            show: false
        }
    ]

    arrayMainTitle = [
        {
            mainTitle: "Палитра оттенков Inoa (Иноа)"
        },
        {
            mainTitle: "Палитра оттенков Majirel (Мажирель)"
        },
        {
            mainTitle: "Majirel Cool Inforced (Кул Инфорсед)"
        },
        {
            mainTitle: "Majirel High-lift (Хай-Лифт)"
        },
        {
            mainTitle: "Majirel Cool-cover (Кул-Кавер)"
        },
        {
            mainTitle: "Majirel Glow (Глоу)"
        },
        {
            mainTitle: "Majirel Mix (Микстона Мажирель микс)"
        },
        {
            mainTitle: "Dia Light"
        },
        {
            mainTitle: "Dia Richesse"
        },
        {
            mainTitle: "Dia Color"
        }
    ]



    shopPalletMatrixOrLoreal = false


    private rootStore: RootStore | undefined;

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    init() {
        BHContentModel.getPalletPage({}, this.setDataPallet, this.setError)
    }

    @action
    setData = (result: any) => {
        if (result.data) {
            // this.rowsBrands = result.data.data
        }
    }
    @action
    setDataPallet = (result: any) => {
        if (result.data) {
            this.rowsPallet = result.data.rows
            console.log(result.data)
        }
    }

    @action
    setError = (error: any) => {
        console.log(error);
    }

    setDetailBrand(id: number) {
        ContentModel.getDetailBrands(id,this.setDataDetail, this.setError)
    }

    @action
    setDataDetail = (result: any) => {
        if (result.data) {
        }
    }

    @action
    changePalletMatrixOrLoreal = () => {
       this.shopPalletMatrixOrLoreal = !this.shopPalletMatrixOrLoreal
    }

    @action
    changeShowForLoreal = (index: number) => {
        for (let i =0 ; i< this.objLoreal.length; i++){
            if ( i === index){
                this.objLoreal[i].show = !this.objLoreal[i].show
            }
        }
    }

}
