import React from 'react';
import {Link} from "react-router-dom";
import {withStore} from "../../hocs";
import {observer} from "mobx-react";
import {WIDTH} from "../../const/root";

// @ts-ignore
@withStore
@observer
export class MainAktsii extends React.Component {
    componentDidMount() {
        // @ts-ignore
        const {store} = this.props;
        store.pageCooperationStore.init();
    }
    render() {

        let Width = window.innerWidth

        return (
            <>
                <section className="section sale sale--home" style={{background: "white"}} >
                    <div className="container">
                        <h2 className="section__title">Акции</h2>
                        <div className="sale__grid" >
                            <article className="sale__item sale-item" style={{border: "2px solid #f3e5e8"}}>
                                <a href="/catalog/filter/isSellout-is-true">
                                    <img style={{height: 290}} className="sale-item__thumbnail" src="images/sale.png" alt='' />
                                </a>
                                <div className="sale-item__content"><span className="sale-item__date"><span
                                    className="sale-item__date-day">3</span><span
                                    className="sale-item__date-month">июн</span></span>
                                    <a href="/catalog/filter/isSellout-is-true">
                                        <h3 style={{paddingTop: 30}} className="sale-item__title">Распродажа - предложение товаров по сниженной цене</h3>
                                    </a>
                                    <Link  className="sale-item__more" to={'/catalog/filter/isSellout-is-true'}>
                                        Подробнее
                                    </Link >
                                </div>
                            </article>
                        </div>
                        <Link className="sale__show-all button" to="/sale">Все акции</Link>
                    </div>
                </section>
            </>
        )
    }
}
