import React from 'react';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {withStore} from "../../hocs";
import {observer} from "mobx-react";
import {Checkbox} from "@mui/material";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: "auto",
    width: "calc(350px + (600 - 350) * (100vw - 400px) / (2000 - 350))",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    height: 450,
    p: 4,
};



// @ts-ignore
@withStore
@observer
export default class PopupConfirmConsent extends React.Component {
    componentDidMount() {

    }
    render() {

        // @ts-ignore
        const {store} = this.props;
        const {openConfirmConsent ,
            handleCloseConfirmConsent,
            showPrivacyPolicy,
            handleOpenShowPrivacyPolicy,
            handleCloseShowPrivacyPolicy,
            handleChangeConfirmInfo,
            disableConfirmButton,
            updateConfirmInfo} = store?.mainStore

        return (
            <>

            <div>
                <Modal
                    open={openConfirmConsent}
                    onClose={handleCloseConfirmConsent}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" style={{textAlign: "center", paddingBottom: 10, fontSize: "calc( (100vw - 480px)/(1280 - 480) * (16 - 14) + 14px)"}} component="h2">
                            Согласие на обработку персональных данных
                        </Typography>
                        <Typography id="modal-modal-description" style={{fontSize: "calc( (100vw - 480px)/(1280 - 480) * (14 - 12) + 12px)"}}>
                            Подтверждаю ознакомление с <span onClick={()=> handleOpenShowPrivacyPolicy()} style={{marginRight: 5, marginLeft: 5 , textDecoration: "underline", color: "#d1a0a9", cursor: "grab"}}>политикой обработки персональных данных</span>
                            и даю согласие на обработку персональных данных для следующих целей:
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{fontSize: "calc( (100vw - 480px)/(1280 - 480) * (14 - 12) + 12px)"}}>
                            <div style={{display: "inline-flex"}}>
                                <span style={{paddingRight: 10}}>
                                     <Checkbox  style={{color: "#d1a0a9"}} value="firstConfirm" onChange={(event)=> handleChangeConfirmInfo(event.target.value)}/>
                                </span>

                                <span>
                                Получение информации о поступлении товара, о снижении цены на товар посредством электронной связи, SMS, Viber, E-mail и иных средств связи.
                                </span>
                            </div>

                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{fontSize: "calc( (100vw - 480px)/(1280 - 480) * (14 - 12) + 12px)"}}>
                            <div style={{display: "inline-flex"}}>
                                <span style={{paddingRight: 10}}>
                                    <Checkbox  style={{color: "#d1a0a9"}} value="secondConfirm" onChange={(event)=> handleChangeConfirmInfo(event.target.value)}/>
                                </span>

                                <span>
                                Проведение рассылок маркетингового, рекламного, новостного характера, анализа данных.
                                </span>
                            </div>

                        </Typography>
                        <div style={{justifyContent: "space-between", display: "flex", paddingTop: 20}}>
                            <button style={{width: 130}} onClick={handleCloseConfirmConsent} className="button">Отказываюсь</button>
                            <button style={disableConfirmButton ? {width: 130, opacity: 0.5} : {width: 130}} disabled={disableConfirmButton}  onClick={()=> updateConfirmInfo()}  className="button button--accent-dark">Соглашаюсь</button>
                        </div>

                    </Box>
                </Modal>
            </div>
        <div>
            <Modal
                open={showPrivacyPolicy}
                onClose={handleCloseShowPrivacyPolicy}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" style={{textAlign: "center", paddingBottom: 10}} component="h2">
                        Политика обработки персональных данных
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                        <p>
                            1. Политика обработки персональных данных (далее - Политика) регулирует правоотношения по обработке персональных данных между ООО «Сэльвин», УНП 100819821, адрес: 220084, г. Минск, ул. Академика Купревича, д. 14, каб. 37 (4 этаж) (далее - Оператор) и Пользователем beautyhouse.by (далее - Пользователь).

                            Под «Пользователем» понимается дееспособное физическое лицо, достигшее 18-летнего возраста и желающее заказать услуги Компании, оставить комментарий, зарегистрироваться на веб-сайте либо совершить иные действия, предусмотренные функционалом интернет- ресурса Оператора.

                            Под «Сайтом» или «Интернет-ресурсом Оператора» понимается веб-сайт beautyhouse.by с учетом всех уровней доменных имен, принадлежащий Оператору.

                            Под «персональными данными» понимается любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (гражданину).

                            Под «обработкой персональных данных» понимается любое действие (операция) или совокупность действий (операций) с персональными данными, совершаемых с использованием средств автоматизации или без использования таких средств. К таким действиям (операциям) можно отнести: сбор, получение, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.

                        </p>

                        <p>
                            2. Настоящая Политика определяет порядок обработки персональных данных пользователей сайта beautyhouse.by (далее - Сайт), условия и принципы обработки персональных данных, права Пользователей и обязанности Оператора, сведения о реализуемых мерах по защите обрабатываемых персональных данных. Текст настоящей Политики размещается Оператором в сети Интернет на Сайте.
                        </p>

                        <p>
                            3. Настоящая Политика действует в отношении всех персональных данных, которые Оператор получает от Пользователей.
                        </p>

                        <p>
                            4. Пользователь соглашается с настоящей Политикой путем проставления соответствующей отметки на сайте Оператора.
                            В случае несогласия с условиями Политики Пользователь должен немедленно прекратить любое использование Сайта.
                        </p>

                        <p>
                            5. При использовании функционала Сайта Пользователь может предоставлять Оператору следующие персональные данные (перечень и виды персональных данных зависят от конкретных функциональных возможностей интернет-ресурса, используемых Пользователем): фамилия, имя и отчество, адрес электронной почты, номер контактного телефона, идентификационные данные пользователя (user ID), адрес доставки товара, логин и пароль в личном кабинете на сайте Оператора.
                        </p>

                        <p>
                            6. Предоставляя свои персональные данные, Пользователь соглашается на их обработку (вплоть до отзыва Пользователя своего согласия на обработку персональных данных), включая сбор, хранение, обезличивание, передачу третьим лицам в предусмотренных Политикой случаях, Оператором в целях предоставления Пользователю рекламной, справочной информации, сервисов в соответствии с функциональными возможностями Сайта и в иных целях согласно п.8 настоящей Политики.
                        </p>

                        <p>
                            При обработке персональных данных Оператор руководствуется следующими нормативными правовыми актами:
                        </p>

                        <p>
                            <ul>
                                <li>
                                    - Конституция Республики Беларусь;
                                </li>
                                <li>
                                    - Закон Республики Беларусь от 07.05.2021 N 99-З «О защите персональных данных»;
                                </li>
                                <li>
                                    - Закон Республики Беларусь от 21.07.2008 N 418-З «О регистре населения»;
                                </li>
                                <li>
                                    - Закон Республики Беларусь от 10.11.2008 N 455-З «Об информации, информатизации и защите информации»;
                                </li>
                                <li>
                                    - Приказ ОАЦ при Президенте Республики Беларусь от 20 февраля 2020г. №66 «О мерах по реализации Указа Президента Республики Беларусь от 9 декабря 2019 г. № 449»;
                                </li>
                                <li>
                                    - Иные нормативные правовые акты Республики Беларусь и нормативные документы уполномоченных органов государственной власти.
                                </li>
                            </ul>
                        </p>

                        <p>
                            Предоставленное Пользователем согласие на обработку персональных данных действует со дня предоставления такого согласия до момента достижения целей обработки или отзыва Пользователем согласия, если иное не предусмотрено действующим законодательством.
                            Пользователь в любое момент может отозвать предоставленное Оператору согласие в порядке, установленном настоящей Политикой.
                        </p>

                        <p>
                            7. Если Пользователь желает уточнить персональные данные в случае, когда персональные данные являются неполными, неточными или неактуальными, либо желает отозвать свое согласие на обработку персональных данных, Пользователь должен направить официальный запрос Оператору с темой «Уточнить персональные данные» или «Прекратить обработку персональных данных» на адрес электронной почты postmaster@sellwin.by. В письме необходимо указать свой электронный адрес и соответствующее требование.
                        </p>

                        <p>
                            8. Оператор использует предоставленные Пользователем данные в целях:
                        </p>

                        <p>
                            8.1. регистрация и идентификация Пользователя на Сайте, предоставление Пользователю возможности полноценного использования Сайта;
                        </p>

                        <p>
                            8.2. дальнейшей коммуникации по заявке Пользователя для исполнения обязательств по заявке Пользователя, консультирования по вопросам оказания Оператором услуг;
                        </p>

                        <p>
                            8.3.	 отправки сообщений информационного характера;
                        </p>

                        <p>
                            8.3.	 отправки сообщений информационного характера;
                        </p>

                        <p>
                            8.4. рекламы, продвижения товаров, работ (услуг), в том числе на основании полученной информации о персональных предпочтениях и настройках Пользователя;
                        </p>

                        <p>
                            8.5. оценки и анализа работы Сайта Оператора;
                        </p>

                        <p>
                            8.6. аналитики эффективности размещения рекламы, статистических исследований на основе обезличенной информации, предоставленной Пользователем;
                        </p>

                        <p>
                            8.7.	информирования Пользователя об акциях, скидках и специальных предложениях посредством рассылок по электронной почте, телефонной связи;
                        </p>

                        <p>
                            8.8. проведения маркетинговых исследований, в том числе с привлечением третьих лиц в качестве подрядчика (исполнителя, консультанта).
                        </p>

                        <p>
                            9. Оператор не проверяет данные, представленные или указанные Пользователем.
                        </p>

                        <p>В связи с этим Оператор исходит из того, что при представлении персональных данных Пользователь:</p>

                        <p>
                            9.1. является дееспособным лицом. В случае недееспособности лица, использующего Сайт, согласие на обработку персональных данных предоставляется законным представителем.

                        </p>

                        <p>
                            9.2. указывает достоверную информацию о себе (либо о представляемом им недееспособном лице). Пользователь самостоятельно поддерживает представленные персональные данные в актуальном состоянии.

                        </p>

                        <p>
                            9.3. самостоятельно поддерживает представленные персональные данные в актуальном состоянии.

                        </p>

                        <p>
                            9.4. осознает, что информация на Сайте, размещаемая Пользователем, может становиться доступной для других лиц, может быть скопирована или распространена такими пользователями в случаях, предусмотренных Политикой.
                        </p>

                        <p>
                            10. Оператор обрабатывает персональные данные на основе следующих принципов:
                        </p>

                        <p>
                            10.1. законности;
                        </p>

                        <p>
                            10.2. ограничения обработки персональных данных достижением конкретных заранее определенных законных целей;
                        </p>

                        <p>
                            10.3. недопущения обработки персональных данных, несовместимой с целями сбора и/или хранения полученных от Пользователя данных;
                        </p>
                        <p>
                            10.3. недопущения обработки персональных данных, несовместимой с целями сбора и/или хранения полученных от Пользователя данных;
                        </p>
                        <p>
                            10.4. соответствия содержания и объема обрабатываемых персональных данных целям их обработки;
                        </p>
                        <p>
                            10.5. недопущения обработки избыточных по отношению к целям обработки данных;
                        </p>
                        <p>
                            10.6. обеспечение точности, достаточности и актуальности персональных данных по отношению к целям их обработки;
                        </p>
                        <p>
                            10.7. уничтожение или обезличивание персональных данных в целях недопущения их раскрытия при достижении целей обработки данных, утраты необходимости такой обработки или при получении от Пользователя требования об уничтожении персональных данных либо поступлении заявления об отзыве согласия на обработку персональных данных.
                        </p>
                        <p>
                            11. Оператор при обработке персональных данных принимает необходимые и достаточные организационные и технические меры для защиты персональных данных от неправомерного доступа к ним, а также от иных неправомерных действий в отношении персональных данных.
                            Обработка персональных данных Пользователей осуществляется Оператором с учетом следующего:
                        </p>
                        <p>
                            11.1. Обработка персональных данных Пользователей осуществляется с использованием баз данных на территории Республики Беларусь.
                        </p>
                        <p>
                            11.2. Обработка персональных данных осуществляется как с использованием автоматизированных средств, так и без их использования.
                        </p>
                        <p>
                            11.3. Оператор обязуется не передавать полученную от Пользователя информацию третьим лицам, кроме случаев, специально оговоренных в настоящей Политике.
                        </p>
                        <p>
                            11.4. Оператор обязуется сохранять в тайне, не раскрывать и не распространять персональные данные без согласия Пользователя, если иное не предусмотрено действующим законодательством и/или настоящей Политикой.
                        </p>
                        <p>
                            11.5. Хранение персональных данных Пользователей осуществляется на электронных носителях, а для целей исполнения обязательств перед Пользователями может осуществляться на материальных носителях после извлечения персональных данных.
                        </p>
                        <p>
                            11.6. Хранение персональных данных осуществляется в пределах срока, объективно необходимого для исполнения обязательств перед Пользователями, и определяется следующими событиями (в зависимости от того, какое событие наступит ранее):
                        </p>
                        <p>
                            11.6.1. до момента удаления Пользователем персональных данных посредством личного кабинета;
                        </p>
                        <p>
                            11.6.2. до момента уничтожения персональных данных Оператором в связи с поступлением от Пользователя требования об уничтожении персональных данных или отзыве согласия на их обработку;
                        </p>
                        <p>
                            11.6.3.  до момента истечения срока действия согласия Пользователя.
                        </p>
                        <p>
                            11.7. Оператор имеет право хранить персональные данные Пользователя, кроме случаев получения требования об уничтожении данных или отзыва согласия на обработку данных, в обезличенном виде после исполнения обязательств перед Пользователем для целей, указанных в п. 8.6. настоящей политики.
                        </p>
                        <p>
                            12. Не считается нарушением обязательств, предусмотренных настоящей Политикой, передача информации в соответствии с обоснованными и применимыми требованиями законодательства Республики Беларусь, а равно предоставление Оператором информации партнерам, действующим на основании договора (соглашения) с Оператором, для исполнения обязательств перед Пользователем; передача Оператором третьим лицам данных о Пользователе в обезличенной форме в целях оценки и анализа работы Сайта Оператора, предоставления персональных рекомендаций, показа рекламных объявлений на основании персональных предпочтений и настроек Пользователя, а также проведения маркетинговых, аналитических и/или статистических исследований.
                        </p>
                        <p>
                            13. Оператор получает информацию об ip-адресе Пользователя и сведения о том, по ссылке с какого интернет-сайта он пришел. Данная информация не используется для установления личности посетителя.
                        </p>
                        <p>
                            14. Пользователь имеет право на получение от Оператора информации, касающейся обработки его персональных данных. Оператор безвозмездно предоставляет Пользователю или его представителю возможность ознакомления с персональными данными, относящимися к Пользователю.
                            В случае выявления неполноты, неточности или неактуальности сведений Оператор на основании информации Пользователя вносит в персональные данные Пользователя необходимые изменения в срок, не превышающий 7 (семь) рабочих дней, и уведомляет Пользователя о внесенных изменениях.
                            В случае если Пользователь или его представитель предоставит Оператору подтверждение факта незаконного получения или обработки его персональных данных, а равно факта несоответствия действий с его персональными данными целям обработки, Оператор в срок, не превышающий 7 (семь) рабочих дней обязуется уничтожить такие персональные данные Пользователя и уведомить Пользователя о предпринятых мерах.
                        </p>
                        <p>
                            15. Оператор обязуется прекратить обработку персональных данных Пользователя в случае:
                        </p>
                        <p>
                            15.1. выявления неправомерной обработки персональных данных Пользователя;
                        </p>
                        <p>
                            15.2. отзыва Пользователем согласия на обработку его персональных данных;
                        </p>
                        <p>
                            15.3. получения от Пользователя требования об уничтожении персональных данных;
                        </p>
                        <p>
                            15.4. достижения цели обработки персональных данных.
                            При наступлении указанных в настоящем пункте случаев Оператор прекращает обработку персональных данных и обеспечивает уничтожение данных в срок, не превышающий 30 (тридцать) дней, если иной срок не установлен законодательством.
                            При невозможности уничтожения персональных данных Пользователя в указанный срок Оператор производит блокировку персональных данных Пользователя и обеспечивает их уничтожение в срок, установленный законодательством, но не более 6 (шести) месяцев.
                        </p>
                        <p>
                            16. Оператор вправе вносить изменения в настоящую Политику в любое время. Актуальный текст Политики размещается на Сайте.
                        </p>
                        <p>
                            17. Продолжение пользования Сайтом или его сервисами после публикации новой редакции Политики означает принятие Политики и ее условий Пользователем.
                            В случае несогласия с условиями Политики Пользователь должен немедленно прекратить использование Сайта и его сервисов.
                        </p>
                        <p>
                            18. Все вопросы по настоящей Политике и/или обработке персональных данных направляются на адрес электронной почты Оператора: postmaster@sellwin.by.
                        </p>

                    </Typography>
                    <div style={{justifyContent: "space-around", display: "flex", paddingTop: 20}}>
                        <button style={{width: 130}} onClick={handleCloseShowPrivacyPolicy}className="button button--accent-dark">Назад</button>
                        {/*<Button variant="contained" color="primary" onClick={handleCloseShowPrivacyPolicy} >Назад</Button>*/}
                    </div>

                </Box>
            </Modal>
        </div>
    </>

    )
    }
}
