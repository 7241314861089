import {RootStore} from '../index';
import {action, makeAutoObservable} from "mobx"
import AuthLoginModel from "../../model/AuthLoginModel";


export class PageChangeStore {
    private rootStore: RootStore | undefined;
    public email = '';
    public code = '';
    public inputs = {
        password:{
            code: 'password',
            type: "password",
            title: 'Пароль*',
            tooltip: 'Пароль',
            error: '',
            value: '',
            server: true,
        },
        password2:{
            code: 'password2',
            type: "password",
            title: 'Повторите пароль*',
            tooltip: 'Повторите пароль',
            error: '',
            value: '',
        },
    }
    public error: string = '';
    public redirect: boolean = false;
    public redirect404: boolean = false;

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    @action
    init(code: string) {
        this.code = code;
        AuthLoginModel.getCheckWord(code, (result: any) => {
            if (result.data.email) {
                this.email = result.data.email;
            } else {
                this.redirect404 = true;
            }
        }, this.setError);
        this.redirect = false;
        this.redirect404 = false;
    }

    @action
    onSubmitForm = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        let data = [];
        for (let code in this.inputs) {
            // @ts-ignore
            let item=this.inputs[code];
            // @ts-ignore
            if (item.server) {
                data.push(item);
            }
        }

        let dt: any = {};
        data.map((item: any) => {
            dt[item.code] = item.value;
            return item;
        });

        if (this.validate()) {
            dt['check_word'] = this.code;
            AuthLoginModel.setNewPassword(
                dt,
                this.setSubmit,
                this.setError
            );
        }
        return false;
    }

    @action
    onChangeInputPassword = (e: { target: { value: string; }; }, item: any) => {

        item.value = e.target.value;
        if (item.code === "password" || item.code === "password2"){
            this.validate()
        }
    }

    @action
    validate() {
        let result = true;
        // PASSWORD
        let i1 = this.inputs.password;
        let i2 = this.inputs.password2;
        if (i1 !== undefined && i2 !== undefined) {
            if (i1.value !== i2.value) {
                i2.error = 'Неверное потверждение пароля!';
                result = false;
            } else if(i1.value === i2.value){
                i2.error = '';
            }
        }
        if (i1.value.length < 8 ) {
            i1.error = 'Длина пароля должна быть больше 7';
            result = false;
        }
        if ( i1.value.length > 12) {
            i1.error = 'Длина пароля должна быть меньше 13';
            result = false;
        }

        if (/^[^A-ZА-ЯЁ]*$/.test(i1.value)){
            i1.error = 'Пароль должен содержать хотябы одну заглавную букву';
            result = false;
        }
        if (/^[^~!@#$%^&*()_+]*$/.test(i1.value)){
            i1.error = 'Пароль должен содержать хотябы один спец символ ~ ! @ # $ % ^ & * ( ) _ +';
            result = false;
        }
        if (/^[^1234567890]*$/.test(i1.value)){
            i1.error = 'Пароль должен содержать хотябы одну цифру';
            result = false;
        }
        if (i1.value.length > 8 && i1.value.length < 12 && !/^[^A-ZА-ЯЁ]*$/.test(i1.value) && !/^[^~!@#$%^&*()_+]*$/.test(i1.value) && !/^[^1234567890]*$/.test(i1.value)){
            i1.error = '';
            result = true;
        }

        return result;
    }

    @action
    setSubmit = (result: any) => {
        this.error = '';
        this.redirect = true
    }

    @action
    onChangeInput = (e: { target: { value: string; }; }, item: any) => {
        item.value = e.target.value;
    }

    @action
    setError = (error: any) => {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            error.response.data.message !== undefined
        ) {
            this.error = String(error.response.data.message);
        } else if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            Array.isArray(error.response.data)
        ) {
            let el = {
                error: ''
            };
            error.response.data.map((item: any) => {
                // @ts-ignore
                el = this.getCodeItemInputs(item.code);
                if (el !== undefined) {
                    el.error = item.error;
                }
                return item;
            });
        } else if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            !Array.isArray(error.response.data)
        ) {
            return String(error.response.data);
        }

    }

}
