import {RootStore} from '../index';
import {action, makeAutoObservable} from "mobx";
import axios from "axios";
import BlockPropertyModel from "../../model/BlockPropertyModel";



export class LoyaltyStore {

    rowsLoyalty = [];
    clientId: number = 0;
    arBrand =[];
    arResultLoyalty = []
    private rootStore: RootStore | undefined;

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;

    }


    init() {
        axios.get(`https://nest.sellwin.by/content/loyalty`)
            .then(res => {
                this.rowsLoyalty = []
                this.arBrand =[];
                this.arResultLoyalty = []
                this.rowsLoyalty = res.data.data
                this.filterLoyaltyWithBrand()
            })
    }

    @action
    filterLoyaltyWithBrand = ()=> {
        // @ts-ignore
        this.clientId = this.rootStore.mainStore.getClientId();
        if (this.clientId > 0){
            BlockPropertyModel.getBrand({
                json: {
                    clientId: this.clientId,
                },
            }, this.setCatalog, this.setError);
        }
    }

    @action
    setCatalog = (result: any) => {
        this.arBrand = result.data.enum;
        if (this.arBrand.length && this.rowsLoyalty.length){
            for (let i = 0; i< this.rowsLoyalty.length; i++){
                for (let a= 0; a< this.arBrand.length; a++){
                    // @ts-ignore
                    if (this.rowsLoyalty[i].brand === this.arBrand[a].value ){
                        // @ts-ignore
                        this.arResultLoyalty.push( {pdf_file: this.rowsLoyalty[i].pdf_file, name: this.rowsLoyalty[i].name,brand: this.rowsLoyalty[i].brand} )
                    }
                }
            }
        }
    }

    @action
    setError = (error: any) => {
        console.log(error);
    }


}
