import {Model} from "./";
import {Api} from "../api";

class PaymentModel extends Model {

    constructor() {
        super(new Api("/payment"));
    }

    getTypes(clientId,thenFunction, errorFunction) {
        this.q.setDopUrl('/types/'+clientId);
        this.q.read({}, thenFunction, errorFunction);
    }

    webPay(ob,thenFunction, errorFunction) {
        this.q.setDopUrl('/webPay');
        this.q.create({dto: ob}, thenFunction, errorFunction);
    }

}

export default new PaymentModel();
