import React from 'react';

import {withStore} from "../../hocs";
import {observer} from "mobx-react";
import {WIDTH} from "../../const/root";
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
    countPage: number,
    currentPage: number,
    onCurrentPage: (page: number) => void,
    showSkeleton: boolean
}

// @ts-ignore
@withStore
@observer
export class PaginationCatalog extends React.Component<Props, {}> {
    componentDidMount() {
    }
    render() {
        // @ts-ignore
        const {store} = this.props;
        const {countPage, currentPage, onCurrentPage, showSkeleton} = this.props;

        const items = [];

        let prev = 1;
        let next = 1;

        if (currentPage > 1) {
            prev=currentPage-1;
        }

        if (currentPage >= 1) {
            next=currentPage+1;
        }


        if (countPage < 2) {
            return null;
        }

        let start = 1;
        let end = 5;

        if (countPage > 5) {
            if (currentPage > 3) {
                start = currentPage - 2;
                end = currentPage + 2;
                if (end > countPage) {
                    end = countPage;
                }
            }
        } else {
            end = countPage;
        }

        let Width = window.innerWidth;

        for (let i = start; i <= end; i++) {
            if (i !== currentPage) {
                items.push(<li onClick={() => onCurrentPage(i)}  className="pagination__pages-item"><span style={Width < WIDTH ? {fontSize: 12, height: 30, width: 30}: {}} className="pagination__item">{i}</span></li>);
            } else {
                items.push(<li className="pagination__pages-item"><span className="pagination__item pagination__item--current" >{i}</span></li>);
            }
        }

        let elPrev = <span style={Width < WIDTH ? {fontSize: 12, height: 30, width: 30}: {}} className="pagination__item pagination__control pagination__control--prev" onClick={() => onCurrentPage(prev)}>
            <div className="pagination__control--icon">
                &#706;
            </div>
        </span>;


        if (currentPage === 1) {
            elPrev = <></>;
        }

        let elNext = <span style={Width < WIDTH ? {fontSize: 12, height: 30, width: 30}: {}} className="pagination__item pagination__control pagination__control--next" onClick={() => onCurrentPage(next)}
                        >
            <div className="pagination__control--icon" >
                &#707;
            </div>
        </span>;

        if (countPage === currentPage) {
            elNext = <></>;
        }

        let itemsEnd = <></>;
        let itemsFirst = <></>;

        if(countPage>5 && currentPage<countPage){
            itemsEnd=<>
                <li className="pagination__pages-item"><span>...</span></li>
                <li onClick={() => onCurrentPage(countPage)} className="pagination__pages-item"><span style={Width < WIDTH ? {fontSize: 12, height: 30, width: 30}: {}} className="pagination__item" >{countPage}</span></li>
            </>;
        }
        if ( end === countPage){
            itemsEnd=<></>
            elNext = <></>;
        }
        if(countPage>3 && currentPage<countPage && currentPage>3 || currentPage === 60){
            itemsFirst=<>
                <li className="pagination__pages-item"></li>
                <li onClick={() => onCurrentPage(1)} className="pagination__pages-item"><span style={Width < WIDTH ? {fontSize: 12, height: 30, width: 30}: {}} className="pagination__item" >1</span></li>
                <li className="pagination__pages-item"><span>...</span></li>
            </>;
        }else {
            itemsFirst= <></>;
        }

        let showMore = true
         if (countPage === currentPage) {
             showMore = false;
        }

        return (<>
            <>
                {showMore ? <>{!showSkeleton ?  <>
                        <div style={{alignItems: "center", margin: "auto", maxWidth: 200 , cursor: "pointer", display: "flex"}}
                             className="goods__add-cart button button--accent in-basket"
                             onClick={()=> store.pageCatalogStore.addArray()}>показать еще </div>
                    </>

                    : <div style={{margin: "auto", display: "flex", alignItems: "center", justifyContent: "center"}}> <CircularProgress style={{color: "#b17882"}} disableShrink /></div>}</> : null}

            </>


                <div className="pagination">

                {elPrev}
                <ul   className="pagination__pages">
                    {itemsFirst}
                    {items}
                    {itemsEnd}
                </ul>
                {elNext}
            </div>
        </>);
    }

}
