import React from 'react';



export class FooterContacts extends React.Component {
    render() {
        return (
            <>
                <ul className="footer-contacts">
                    <li className="footer-contacts__item" key='footer-contacts-phone'>
                        <p className="footer-contacts__label footer-contacts__label--phone">
                            <svg className="footer-contacts__icon" width="20" height="20">
                                <use xlinkHref="/images/sprite.svg#icon--phone" />
                            </svg>
                            Наш телефон
                        </p>
                        <a href="tel:+375(44)748-08-23">+375(44)748-08-23</a>
                    </li>
                    <li className="footer-contacts__item" key='footer-contacts-email'>
                        <p className="footer-contacts__label footer-contacts__label--email">
                            <svg className="footer-contacts__icon" width="23" height="16">
                                <use xlinkHref="/images/sprite.svg#icon--email" />
                            </svg>
                            E-mail
                        </p>
                        <a href="mailto:info@bh.by">info@bh.by</a>
                    </li>
                </ul>
            </>
        )
    }
}
