import {Model} from "./";
import {Api} from "../api";

class ElementModel extends Model {

    constructor() {
        super(new Api("/element"));
    }

    getCatalog(data, thenFunction, errorFunction) {

        this.q.setDopUrl('/list/catalog');
        this.q.read(data, thenFunction, errorFunction);
    }

  
    getBestsellers(data, thenFunction, errorFunction) {
        this.q.setDopUrl('/bestsellers');
        this.q.read(data, thenFunction, errorFunction);
    }

    getBrands(data, thenFunction, errorFunction) {
        this.q.setDopUrl('/list/brand');
        this.q.read(data, thenFunction, errorFunction);
    }

    getElement(code, clientId, thenFunction, errorFunction) {
        this.q.setDopUrl('/detail/' + code);
        this.q.read({json: {clientId: clientId}}, thenFunction, errorFunction);
    }

    search(data,thenFunction, errorFunction){
        this.q.setDopUrl('/search-catalog-2');
        this.q.read(data, thenFunction, errorFunction);
    }
    searchCatalog(data,thenFunction, errorFunction){
        this.q.setDopUrl('/search-catalog');
        this.q.read(data, thenFunction, errorFunction);
    }

    searchCode(q,thenFunction, errorFunction){
        this.q.setDopUrl('/search-catalog-code');
        this.q.read({q: q}, thenFunction, errorFunction);
    }
    getAllCatalogList(data, thenFunction, errorFunction) {
        this.q.setDopUrl('/all');
        this.q.read(data, thenFunction, errorFunction);
    }
    getRecommendedCart(ob, thenFunction, errorFunction) {
        this.q.setDopUrl('/recommended-cart/');
        this.q.read(ob, thenFunction, errorFunction);
    }

    geRelatedProducts(ob, thenFunction, errorFunction) {
        this.q.setDopUrl('/related-products/');
        this.q.read(ob, thenFunction, errorFunction);
    }


}

export default new ElementModel();


