import {Model} from "./";
import {Api} from "../api";

class NewsModel extends Model {

    constructor() {
        super(new Api("/adapter-news"));
    }

    setView(id, thenFunction, errorFunction) {
        this.q.setDopUrl('/'+id);
        this.q.update({}, thenFunction, errorFunction);
    }


    getCount( clientId,thenFunction, errorFunction){
        this.q.setDopUrl('/count/'+clientId);
        this.q.read({}, thenFunction, errorFunction);
    }


    getRows( clientId,thenFunction, errorFunction){
        this.q.setDopUrl('/rows/'+clientId);
        this.q.read({}, thenFunction, errorFunction);
    }


}

export default new NewsModel();