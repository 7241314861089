import {Model} from "./";
import {Api} from "../api";

class FavoriteModel extends Model {

    constructor() {
        super(new Api("/favorite"));
    }



    create(ob, thenFunction, errorFunction) {
        this.q.setDopUrl('');
        this.q.create({
            productId: ob.productId,
            clientId: ob.clientId,
            userId: ob.userId,
        },thenFunction, errorFunction);
    }
    getFavorite(clientId,discount=0, thenFunction, errorFunction) {
        this.q.setDopUrl('/' + clientId);
        this.q.read({discount:discount}, thenFunction, errorFunction);
    }
    addFavorite(ob,discount=0, thenFunction, errorFunction ) {
        this.q.setDopUrl('?discount='+discount);
        this.q.create({
            productId: ob.productId,
            count: ob.count,
            clientId: ob.clientId,
        },thenFunction, errorFunction);
    }


    deleteAllFa(id, thenFunction, errorFunction) {
        this.q.setDopUrl('/all');
        this.q.delete(id, thenFunction, errorFunction);
    }
    deleteFavorite(ob,discount=0, thenFunction, errorFunction) {
        this.q.setDopUrl('/deleteOne');
        this.q.delete(ob, thenFunction, errorFunction);
    }

}

export default new FavoriteModel();