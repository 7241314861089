import React , { Suspense, lazy } from 'react';
import {HeaderTop}              from "./components/header/HeaderTop";
import {FooterBottom}           from "./components/footer/FooterBottom";
import {
    BrowserRouter as Router, Switch, Route,
} from "react-router-dom";

import './App.css';
import CatalogPage         from "./pages/CatalogPage";
import ProductPage              from "./pages/ProductPage";
import {MainPage}               from "./pages/MainPage";
import ChangePage               from "./pages/ChangePage";
import {AdminTop} from "./pages/adminTop/AdminTop";
import {Spin} from "antd"
import ConfirmPageEmail from "./pages/ConfirmPageEmail";
import NewsMainDetailPage from "./pages/NewsMainDetailPage";
import LoyaltyInfoPage from "./pages/LoyaltyInfoPage";
import NewsPersonal from "./pages/personal/NewsPersonal";
import SingleBrandPage from "./pages/SingleBrandPage";
import PalletPage from "./pages/PalletPage";
import AdminMessage from "./pages/adminTop/AdminMessage";


const NewsPage = lazy(  () => import('./pages/NewsPage'))
const AboutPage = lazy(  () => import('./pages/AboutPage'))
const CooperationPage = lazy(  () => import('./pages/CooperationPage'))
const RefundPage = lazy(  () => import('./pages/RefundPage'))
const PersonalDataPage = lazy(  () => import('./pages/personal/PersonalDataPage'))
const OrderHistoryPage = lazy(  () => import('./pages/personal/OrderHistoryPage'))
const DeliveryAddressPage = lazy(  () => import('./pages/personal/DeliveryAddressPage'))
const ContractsPage = lazy(  () => import('./pages/personal/ContractsPage'))
const PriceListPage = lazy(  () => import('./pages/personal/PriceListPage'))
const LoyaltyPage = lazy(  () => import('./pages/personal/LoyaltyPage'))
const PersonalSalePage = lazy(  () => import('./pages/personal/PersonalSalePage'))
const PrivacyPolicyPage = lazy(  () => import('./pages/PrivacyPolicyPage'))
const RequisitesPage = lazy(  () => import('./pages/RequisitesPage'))
const ContactsPage = lazy(  () => import('./pages/ContactsPage'))
const OrderPage = lazy(  () => import('./pages/OrderPage'))
const OrderConfirmPage = lazy(  () => import('./pages/OrderConfirmPage'))
const BasketPage = lazy(  () => import('./pages/BasketPage'))
const FavoritePage = lazy(  () => import('./pages/FavoritePage'))
const ConfirmWebPay = lazy(  () => import('./pages/ConfirmWebPayPage'))


const HowOrderPage = lazy(  () => import('./pages/HowOrderPage'))
const PaymentPage = lazy(  () => import('./pages/PaymentPage'))
const NovinkiPage = lazy(  () => import('./pages/NovinkiPage'))
const DeliveryPage = lazy(  () => import('./pages/DeliveryPage'))
const HowtobuyPage = lazy(  () => import('./pages/HowtobuyPage'))
const QuestionPage = lazy(  () => import('./pages/QuestionPage'))
const NotFoundPage = lazy(  () => import('./pages/NotFoundPage'))
const AdminPage = lazy(  () => import('./pages/AdminPage'))
const AdminLogPage = lazy(  () => import('./pages/adminTop/AdminLogPage'))
const TrainingPage = lazy(  () => import('./pages/TrainingPage'))
const CatalogSearchPage = lazy(  () => import('./pages/CatalogSearchPage'))

const SalePage = lazy(  () => import('./pages/SalePage'))
const BrandsPage = lazy(  () => import('./pages/BrandsPage'))



export default class App extends React.Component<{}, {}> {
    render() {
        return (
            <>
                <Router>
                    <AdminTop/>
                    <HeaderTop />
                    <Switch>
                        <Route exact path="/"               children={<MainPage/>} />
                        <Route exact path="/catalog"        children={<CatalogPage/>} />
                        <Route exact path="/catalog/search" children={ <Suspense fallback={<Spin/>} ><CatalogSearchPage/> </Suspense>}/>
                        <Route exact path="/catalog/:code"  children={<ProductPage/>} />
                        <Route exact path="/catalog/filter/:filter" children={<CatalogPage/>}/>
                        <Route exact path="/catalog/:code1" children={<CatalogPage/>}/>
                        <Route exact path="/catalog/:code1/filter/:filter" children={<CatalogPage/>}/>
                        <Route exact path="/catalog/:code1/:code2" children={<CatalogPage/>}/>
                        <Route exact path="/catalog/:code1/:code2/filter/:filter" children={<CatalogPage/>}/>

                        <Route exact path="/brands"         children={ <Suspense fallback={<Spin/>} > <BrandsPage/> </Suspense>} />
                        <Route exact path="/sale"           children={<Suspense fallback={<Spin/>} > <SalePage/> </Suspense>} />
                        <Route exact path="/cooperation"    children={<Suspense fallback={<Spin/>} ><CooperationPage/> </Suspense>} />
                        <Route exact path="/training"       children={<Suspense fallback={<Spin/>} ><TrainingPage/>  </Suspense>} />
                        <Route exact path="/about"          children={ <Suspense fallback={<Spin/>} ><AboutPage/> </Suspense>} />
                        <Route exact path="/news"           children={ <Suspense fallback={<Spin/>} ><NewsPage/> </Suspense>} />
                        <Route exact path="/news/:code"           children={ <NewsMainDetailPage/>} />

                        <Route exact path="/contacts"       children={ <Suspense fallback={<Spin/>} ><ContactsPage/> </Suspense>} />
                        <Route exact path="/question"       children={ <Suspense fallback={<Spin/>} ><QuestionPage/></Suspense>} />
                        <Route exact path="/howtobuy"       children={ <Suspense fallback={<Spin/>} ><HowtobuyPage/></Suspense>} />
                        <Route exact path="/delivery"       children={ <Suspense fallback={<Spin/>} ><DeliveryPage/></Suspense>} />
                        <Route exact path="/payment"        children={ <Suspense fallback={<Spin/>} ><PaymentPage/></Suspense>} />
                        <Route exact path="/refund"         children={  <Suspense fallback={<Spin/>} ><RefundPage/></Suspense>} />
                        <Route exact path="/novinki"        children={ <Suspense fallback={<Spin/>} ><NovinkiPage/></Suspense>} />
                        <Route exact path="/favorite"       children={ <Suspense fallback={<Spin/>} ><FavoritePage/> </Suspense>} />
                        <Route exact path="/basket"         children={<Suspense fallback={<Spin/>} ><BasketPage/> </Suspense>} />
                        <Route exact path="/order"          children={<Suspense fallback={<Spin/>} ><OrderPage/> </Suspense>} />
                        <Route exact path="/order-confirm"  children={<Suspense fallback={<Spin/>} ><OrderConfirmPage/> </Suspense>} />
                        <Route exact path="/how-order"      children={ <Suspense fallback={<Spin/>} > <HowOrderPage/> </Suspense>} />
                        <Route exact path="/requisites"     children={ <Suspense fallback={<Spin/>} ><RequisitesPage/></Suspense> } />
                        <Route exact path="/privacy-policy" children={ <Suspense fallback={<Spin/>} ><PrivacyPolicyPage/> </Suspense>} />
                        <Route exact path="/loyalty-program" children={ <Suspense fallback={<Spin/>} ><LoyaltyInfoPage/> </Suspense>} />
                        <Route exact path="/change/:code"   children={<ChangePage/>}/>
                        <Route exact path="/email/:code"   children={<ConfirmPageEmail/>}/>
                        <Route exact path="/brands/"   children={<BrandsPage/>}/>
                        <Route exact path="/brands/:code"   children={<SingleBrandPage/>}/>
                        <Route exact path="/pallet"   children={<PalletPage/>}/>

                        <Route exact path="/personal/data"                  children={<Suspense fallback={<Spin/>} ><PersonalDataPage/> </Suspense>} />
                        <Route exact path="/personal/order-history"         children={ <Suspense fallback={<Spin/>} ><OrderHistoryPage/> </Suspense>} />
                        <Route exact path="/personal/delivery-address"      children={<Suspense fallback={<Spin/>} ><DeliveryAddressPage/> </Suspense>} />
                        <Route exact path="/personal/contracts"             children={<Suspense fallback={<Spin/>} ><ContractsPage/> </Suspense>} />
                        <Route exact path="/personal/price-list"            children={<Suspense fallback={<Spin/>} ><PriceListPage/> </Suspense>} />
                        <Route exact path="/personal/loyalty"               children={ <Suspense fallback={<Spin/>} ><LoyaltyPage/> </Suspense>} />
                        <Route exact path="/personal/sale"               children={ <Suspense fallback={<Spin/>} ><PersonalSalePage/> </Suspense>} />
                        <Route exact path="/personal/news"               children={ <Suspense fallback={<Spin/>} ><NewsPersonal/> </Suspense>} />
                        <Route exact path="/confirmWebPay"               children={ <Suspense fallback={<Spin/>} ><ConfirmWebPay/> </Suspense>} />

                        <Route exact path="/admin"                            children={ <Suspense fallback={<Spin/>} ><AdminPage/> </Suspense>}/>
                        <Route exact path="/admin/log"                            children={ <Suspense fallback={<Spin/>} ><AdminLogPage/> </Suspense>}/>
                        <Route exact path="/admin/message"                            children={ <Suspense fallback={<Spin/>} ><AdminMessage/> </Suspense>}/>

                        <Route exact path="*"                               children={ <Suspense fallback={<Spin/>} ><NotFoundPage/> </Suspense>}/>
                        <Route exact path="/404"                            children={<Suspense fallback={<Spin/>} ><NotFoundPage/> </Suspense>}/>
                    </Switch>
                    <FooterBottom/>
                </Router>
            </>
        );
    }
}

