import {Model} from "./";
import {Api} from "../api";

class LogModel extends Model {

    constructor() {
        super(new Api("/log"));
    }


    getAll(data,thenFunction, errorFunction) {
        this.q.setDopUrl('/all/');
        this.q.read(data, thenFunction, errorFunction);
    }


}


export default new LogModel();