import React from 'react';

export class HelperSvg {

    static l1 = 'd638ec4e-ca79-11eb-b417-005056011f71';
    static l2 = 'e1a546fb-ca79-11eb-b417-005056011f71';
    static l3 = '08e85403-ca7a-11eb-b417-005056011f71';
    static l4 = '3ee2be2d-ca7a-11eb-b417-005056011f71';
    static l5 = '1df00895-ca7a-11eb-b417-005056011f71';

    static getSidebarLeft(code: string) {
        let r = <></>;
        switch (code) {
            // Бытовая химия
            case HelperSvg.l1:
                r = <svg className="sidebar__image" width="60" height="60" viewBox="0 0 60 60"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                    <path className="sidebar__image_bg"
                          d="M11.25 0H48.75C54.9637 0 60 5.03625 60 11.25V48.75C60 54.9637 54.9637 60 48.75 60H11.25C5.03625 60 0 54.9637 0 48.75V11.25C0 5.03625 5.03625 0 11.25 0Z"
                          fill="#ECF6FE"/>
                    <path className="sidebar__image_line"
                          d="M39.1059 23.5722L32.8291 20.1286C31.8233 19.5767 31.1018 18.6496 30.7999 17.5764C31.4021 17.5442 31.8808 17.0472 31.8808 16.437C31.8808 15.806 31.3693 15.2944 30.7383 15.2944H30.6425V11.1426C30.6425 10.5116 30.1309 10 29.4999 10H21.1197C20.4887 10 19.9771 10.5116 19.9771 11.1426V15.2943H19.7374C19.1064 15.2943 18.5948 15.8059 18.5948 16.4369C18.5948 17.0679 19.1064 17.5795 19.7374 17.5795H19.9771V17.9681C19.9771 18.8104 19.7279 19.6248 19.2563 20.3231L17.6333 22.7271C16.9058 23.8046 16.5212 25.0611 16.5212 26.3608V43.7799C16.5212 46.6583 18.8645 49 21.7448 49H37.255C40.1353 49 42.4786 46.6582 42.4786 43.7799V29.2671C42.4786 26.8957 41.1862 24.7134 39.1059 23.5722ZM22.2622 12.2852H28.3574V15.2943H22.2622V12.2852ZM40.1934 43.7799C40.1934 45.3983 38.8752 46.7148 37.255 46.7148H21.7449C20.1246 46.7148 18.8065 45.3982 18.8065 43.7799V26.3608C18.8065 25.5185 19.0556 24.7041 19.5272 24.0058L21.1503 21.6018C21.8778 20.5243 22.2623 19.2678 22.2623 17.9681V17.5795H28.4592C28.8011 19.4931 29.9894 21.177 31.73 22.132L38.0068 25.5755C39.3555 26.3155 40.1934 27.7301 40.1934 29.2671V43.7799Z"
                          fill="#074DB7"/>
                    <path className="sidebar__image_line"
                          d="M35.0983 33.5916H23.9025C22.4918 33.5916 21.344 34.7393 21.344 36.1501V41.4375C21.344 42.8483 22.4918 43.996 23.9025 43.996H35.0983C36.5091 43.996 37.6567 42.8483 37.6567 41.4375V36.1501C37.6567 34.7393 36.5091 33.5916 35.0983 33.5916ZM35.3716 41.4375C35.3716 41.5882 35.249 41.7109 35.0983 41.7109H23.9025C23.7518 41.7109 23.6292 41.5882 23.6292 41.4375V36.1501C23.6292 35.9993 23.7518 35.8767 23.9025 35.8767H35.0983C35.249 35.8767 35.3716 35.9993 35.3716 36.1501V41.4375Z"
                          fill="#074DB7"/>
                    <path className="sidebar__image_line"
                          d="M28.8718 31.0005H35.1492C36.1131 31.0005 36.8973 30.2165 36.8973 29.2528V27.8584C36.8973 27.2025 36.5353 26.6078 35.9526 26.3063L29.6756 23.0591C29.4284 22.931 29.1504 22.8634 28.8718 22.8634C27.908 22.8634 27.1238 23.6474 27.1238 24.611V29.2528C27.1238 30.2164 27.9079 31.0005 28.8718 31.0005ZM29.4089 25.4939L34.6122 28.1856V28.7153H29.4089V25.4939Z"
                          fill="#074DB7"/>
                </svg>;
                break;
            // Гигиена
            case HelperSvg.l2:
                r = <svg className="sidebar__image" width="60" height="60" viewBox="0 0 60 60"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                    <path className="sidebar__image_bg"
                          d="M11.25 0H48.75C54.9637 0 60 5.03625 60 11.25V48.75C60 54.9637 54.9637 60 48.75 60H11.25C5.03625 60 0 54.9637 0 48.75V11.25C0 5.03625 5.03625 0 11.25 0Z"
                          fill="#ECF6FE"/>
                    <path className="sidebar__image_line"
                          d="M12.3438 45.5723C12.3438 47.4623 13.9209 49 15.8594 49H27.5781C28.6525 49 29.6152 48.5272 30.2606 47.7844C31.5555 48.5749 33.0571 49 34.6094 49H41.7969C46.3201 49 50 45.4121 50 41.002C50 36.5918 46.3201 33.0039 41.7969 33.0039H34.6094C34.2056 33.0039 33.8055 33.0341 33.4102 33.0906C33.2826 30.6932 32.7241 29.3313 32.1817 28.0092C31.6223 26.6454 31.0938 25.3571 31.0938 22.7207C31.0938 19.9618 29.0775 17.6536 26.4062 17.1228V13.4277C26.4062 11.5377 24.8291 10 22.8906 10H20.5469C18.6084 10 17.0312 11.5377 17.0312 13.4277V17.1228C14.36 17.6536 12.3438 19.9618 12.3438 22.7207C12.3438 25.3571 11.8152 26.6454 11.2557 28.0092C10.6384 29.514 10 31.07 10 34.1465C10 37.223 10.6384 38.779 11.2557 40.2837C11.8152 41.6476 12.3438 42.9359 12.3438 45.5723ZM32.1818 40.2837C32.7082 39.0008 33.2495 37.6797 33.3977 35.4116C33.7937 35.3305 34.199 35.2891 34.6094 35.2891H41.7969C45.0277 35.2891 47.6562 37.8519 47.6562 41.002C47.6562 44.152 45.0277 46.7148 41.7969 46.7148H34.6094C33.3327 46.7148 32.1041 46.3123 31.0938 45.5723C31.0938 42.9359 31.6223 41.6476 32.1818 40.2837ZM19.375 13.4277C19.375 12.7977 19.9007 12.2852 20.5469 12.2852H22.8906C23.5368 12.2852 24.0625 12.7977 24.0625 13.4277V17.0078H19.375V13.4277ZM13.4318 28.8579C14.0491 27.3532 14.6875 25.7972 14.6875 22.7207C14.6875 20.8307 16.2646 19.293 18.2031 19.293H25.2344C27.1729 19.293 28.75 20.8307 28.75 22.7207C28.75 25.7972 29.3884 27.3532 30.0057 28.8579C30.5652 30.2218 31.0938 31.5101 31.0938 34.1465C31.0938 36.7829 30.5652 38.0712 30.0057 39.435C29.3884 40.9398 28.75 42.4958 28.75 45.5723C28.75 46.2023 28.2243 46.7148 27.5781 46.7148H15.8594C15.2132 46.7148 14.6875 46.2023 14.6875 45.5723C14.6875 42.4958 14.0491 40.9398 13.4318 39.435C12.8723 38.0712 12.3438 36.7829 12.3438 34.1465C12.3438 31.5101 12.8723 30.2218 13.4318 28.8579Z"
                          fill="#074DB7"/>
                    <path className="sidebar__image_line"
                          d="M21.7188 42.1445C25.5958 42.1445 28.75 39.0692 28.75 35.2891C28.75 33.2292 27.9911 32.1193 27.3812 31.2275C26.8366 30.4308 26.4062 29.8015 26.4062 28.4336C26.4062 25.9135 24.3034 23.8633 21.7188 23.8633C19.1341 23.8633 17.0312 25.9135 17.0312 28.4336C17.0312 29.8015 16.6009 30.4308 16.0562 31.2275C15.4464 32.1193 14.6875 33.2292 14.6875 35.2891C14.6875 39.0692 17.8417 42.1445 21.7188 42.1445ZM18.0063 32.4952C18.6161 31.6033 19.375 30.4934 19.375 28.4336C19.375 27.1736 20.4264 26.1484 21.7188 26.1484C23.0111 26.1484 24.0625 27.1736 24.0625 28.4336C24.0625 30.4934 24.8214 31.6033 25.4313 32.4952C25.9759 33.2918 26.4062 33.9212 26.4062 35.2891C26.4062 37.8091 24.3034 39.8594 21.7188 39.8594C19.1341 39.8594 17.0312 37.8091 17.0312 35.2891C17.0312 33.9212 17.4616 33.2918 18.0063 32.4952Z"
                          fill="#074DB7"/>
                    <path className="sidebar__image_line"
                          d="M41.7969 30.7188C43.7354 30.7188 45.3125 29.1811 45.3125 27.291C45.3125 25.401 43.7354 23.8633 41.7969 23.8633C39.8584 23.8633 38.2812 25.401 38.2812 27.291C38.2812 29.1811 39.8584 30.7188 41.7969 30.7188ZM41.7969 26.1484C42.443 26.1484 42.9688 26.661 42.9688 27.291C42.9688 27.921 42.443 28.4336 41.7969 28.4336C41.1507 28.4336 40.625 27.921 40.625 27.291C40.625 26.661 41.1507 26.1484 41.7969 26.1484Z"
                          fill="#074DB7"/>
                    <path className="sidebar__image_line"
                          d="M44.1406 21.5781C44.7878 21.5781 45.3125 21.0666 45.3125 20.4355C45.3125 19.8045 44.7878 19.293 44.1406 19.293C43.4934 19.293 42.9688 19.8045 42.9688 20.4355C42.9688 21.0666 43.4934 21.5781 44.1406 21.5781Z"
                          fill="#074DB7"/>
                </svg>;
                break;
            // Косметика и парфюмерия
            case HelperSvg.l3:
                r = <svg className="sidebar__image" width="60" height="60" viewBox="0 0 60 60"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                    <path className="sidebar__image_bg"
                          d="M11.25 0H48.75C54.9637 0 60 5.03625 60 11.25V48.75C60 54.9637 54.9637 60 48.75 60H11.25C5.03625 60 0 54.9637 0 48.75V11.25C0 5.03625 5.03625 0 11.25 0Z"
                          fill="#ECF6FE"/>
                    <path className="sidebar__image_line"
                          d="M36.4908 34.7522V24.4078C36.4908 23.9332 36.106 23.5484 35.6314 23.5484H34.9036L34.8912 16.1131C34.8912 16.1075 34.8911 16.1021 34.891 16.0966C34.9382 15.5595 34.8608 14.9584 34.6561 14.3133C34.2607 13.0684 33.4178 11.7575 32.2824 10.622C31.1469 9.48665 29.836 8.64364 28.5911 8.24843C27.1534 7.79186 25.9339 7.96862 25.157 8.74564C24.6986 9.20393 24.4492 9.8163 24.4154 10.5365C24.4145 10.5494 24.414 10.5625 24.4137 10.5755C24.4106 10.6609 24.4105 10.7477 24.4135 10.836V23.5484H23.6865C23.2118 23.5484 22.8271 23.9332 22.8271 24.4078V34.7522C22.3675 34.7691 22 35.1471 22 35.6109V48.9449C22 50.6295 23.3705 52 25.0551 52H34.2627C35.9473 52 37.3178 50.6295 37.3178 48.9449V35.6109C37.3179 35.1471 36.9503 34.7691 36.4908 34.7522ZM24.5459 25.2671H34.7721V34.7515H24.5459V25.2671ZM27.1264 9.72057C27.4005 9.72057 27.7218 9.77565 28.071 9.88651C29.0443 10.1955 30.1363 10.9065 31.067 11.8373C31.9978 12.7681 32.7088 13.8601 33.0179 14.8334C33.2631 15.6054 33.2351 16.2405 32.9435 16.5322C32.6518 16.8239 32.0167 16.8517 31.2447 16.6066C30.2714 16.2976 29.1794 15.5865 28.2485 14.6557C27.3178 13.7249 26.6068 12.6329 26.2977 11.6597C26.1915 11.325 26.1366 11.0161 26.1322 10.7497V10.6805C26.1376 10.3651 26.218 10.115 26.3723 9.96084C26.532 9.80117 26.7946 9.72057 27.1264 9.72057ZM26.1322 14.8564C26.4061 15.2052 26.7077 15.5454 27.0333 15.8711C28.1688 17.0064 29.4797 17.8494 30.7247 18.2447C31.2479 18.4108 31.742 18.4931 32.1967 18.4931C32.5487 18.4931 32.877 18.4437 33.1764 18.3457C33.179 19.9022 33.1824 21.9519 33.185 23.5484H26.1322V14.8564ZM35.5992 48.9449C35.5992 49.6818 34.9996 50.2813 34.2627 50.2813H25.0551C24.3183 50.2813 23.7187 49.6818 23.7187 48.9449V36.4702H35.5991V48.9449H35.5992Z"
                          fill="#074DB7"/>
                    <path className="sidebar__image_line"
                          d="M26.3535 40.9326H32.9649C33.4395 40.9326 33.8243 40.5479 33.8243 40.0733C33.8243 39.5987 33.4395 39.2139 32.9649 39.2139H26.3535C25.8789 39.2139 25.4941 39.5987 25.4941 40.0733C25.4941 40.5479 25.8789 40.9326 26.3535 40.9326Z"
                          fill="#074DB7"/>
                </svg>;
                break;
            //Хозяйственные товары
            case HelperSvg.l4:
                r = <svg className="sidebar__image" width="60" height="60" viewBox="0 0 60 60"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                    <path className="sidebar__image_bg"
                          d="M11.25 0H48.75C54.9637 0 60 5.03625 60 11.25V48.75C60 54.9637 54.9637 60 48.75 60H11.25C5.03625 60 0 54.9637 0 48.75V11.25C0 5.03625 5.03625 0 11.25 0Z"
                          fill="#ECF6FE"/>
                    <g clipPath="url(#clip0_382:7529)">
                        <path className="sidebar__image_line"
                              d="M48.6563 10.3346C48.2104 9.88856 47.4873 9.88856 47.0413 10.3346L32.0698 25.3061C29.2628 23.0911 25.1822 23.2535 22.5623 25.7936L10.4918 34.3613C9.9139 34.7715 9.8469 35.6017 10.3453 36.1001L22.911 48.6657C23.4238 49.1786 24.2798 49.0891 24.6755 48.4814L32.8023 36.0017C35.1877 33.526 35.4614 29.7568 33.6204 26.9855L48.6563 11.9496C49.1023 11.5036 49.1023 10.7805 48.6563 10.3346ZM27.5945 26.0372C31.4613 26.0372 33.8371 30.292 31.8072 33.5835L24.9959 26.7722C25.7692 26.2933 26.6622 26.0372 27.5945 26.0372ZM23.53 46.0547L21.6754 44.2001L24.9694 39.2595C25.3193 38.7347 25.1775 38.0257 24.6527 37.6759C24.1281 37.326 23.419 37.4678 23.0691 37.9926L20.0285 42.5532L12.9164 35.4412L23.1623 28.1687L30.439 35.4454L23.53 46.0547Z"
                              fill="#074DB7"/>
                        <path className="sidebar__image_line"
                              d="M31.9137 47.1469C33.4183 48.3693 35.6119 48.1703 36.8037 46.7033C37.9955 45.2364 37.741 43.0484 36.2365 41.8261C34.7319 40.6038 32.5382 40.8028 31.3464 42.2697C30.1547 43.7367 30.4091 45.9246 31.9137 47.1469ZM34.7955 43.5997C35.2971 44.0072 35.3819 44.7365 34.9846 45.2255C34.5874 45.7144 33.8562 45.7808 33.3546 45.3733C32.8531 44.9659 32.7683 44.2366 33.1655 43.7476C33.5628 43.2586 34.294 43.1923 34.7955 43.5997Z"
                              fill="#074DB7"/>
                        <path className="sidebar__image_line"
                              d="M40.9091 45.5125C41.4114 45.9206 42.1412 45.8544 42.5391 45.3647C42.937 44.8749 42.8523 44.147 42.35 43.7389C41.8476 43.3308 41.1179 43.397 40.72 43.8868C40.3221 44.3766 40.4067 45.1044 40.9091 45.5125Z"
                              fill="#074DB7"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_382:7529">
                            <rect width="39" height="39" fill="white" transform="translate(10 10)"/>
                        </clipPath>
                    </defs>
                </svg>;
                break;
            //Продукты питания
            case HelperSvg.l5:
                r = <svg className="sidebar__image" width="60" height="60" viewBox="0 0 60 60"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                    <path className="sidebar__image_bg"
                          d="M11.25 0H48.75C54.9637 0 60 5.03625 60 11.25V48.75C60 54.9637 54.9637 60 48.75 60H11.25C5.03625 60 0 54.9637 0 48.75V11.25C0 5.03625 5.03625 0 11.25 0Z"
                          fill="#ECF6FE"/>
                    <path className="sidebar__image_line"
                          d="M42.4961 25.2292V23.0613H14.168V28.4812C14.168 33.5219 16.825 37.9506 20.8243 40.4773H12V41.5613C12 44.5498 14.4314 46.9812 17.4199 46.9812H39.2441C42.2327 46.9812 44.6641 44.5498 44.6641 41.5613V40.4773H35.8398C36.804 39.8681 37.6876 39.1495 38.5009 38.3093H41.4121C45.5961 38.3093 49 34.873 49 30.6492V25.2292H42.4961ZM42.3104 42.6453C41.863 43.9071 40.6574 44.8132 39.2441 44.8132H17.4199C16.0067 44.8132 14.8011 43.9071 14.3537 42.6453H42.3104ZM28.332 40.4773C21.7173 40.4773 16.3359 35.0959 16.3359 28.4812V25.2292H40.3281V28.4812C40.3281 35.0959 34.9467 40.4773 28.332 40.4773ZM46.832 30.6492C46.832 33.6776 44.4007 36.1414 41.4121 36.1414H40.2298C41.7046 33.872 42.4961 31.2336 42.4961 28.4812V27.3972H46.832V30.6492Z"
                          fill="#074DB7"/>
                    <path className="sidebar__image_line"
                          d="M23.5675 10.0188L22.3649 11.8227C22.7954 12.1097 23.0424 12.5712 23.0424 13.0887C23.0424 13.6062 22.7954 14.0677 22.3649 14.3547C21.3361 15.0406 20.7219 16.2018 20.7219 17.4608C20.7219 18.7198 21.3362 19.881 22.3649 20.5668L23.5675 18.7629C23.1369 18.4758 22.8899 18.0012 22.8899 17.4607C22.8899 16.9202 23.1368 16.4457 23.5675 16.1586C24.5962 15.4727 25.2104 14.325 25.2104 13.0886C25.2104 11.8523 24.5962 10.7046 23.5675 10.0188Z"
                          fill="#074DB7"/>
                    <path className="sidebar__image_line"
                          d="M28.9876 10.0188L27.785 11.8227C28.2156 12.1097 28.4625 12.5712 28.4625 13.0887C28.4625 13.6062 28.2156 14.0677 27.785 14.3547C26.7563 15.0406 26.1421 16.2018 26.1421 17.4608C26.1421 18.7198 26.7563 19.881 27.785 20.5668L28.9876 18.7629C28.5571 18.4758 28.3101 18.0012 28.3101 17.4607C28.3101 16.9202 28.557 16.4457 28.9876 16.1586C30.0164 15.4727 30.6306 14.325 30.6306 13.0886C30.6306 11.8523 30.0164 10.7046 28.9876 10.0188Z"
                          fill="#074DB7"/>
                    <path className="sidebar__image_line"
                          d="M34.4798 10.0188L33.2772 11.8227C33.7078 12.1097 33.9547 12.5712 33.9547 13.0887C33.9547 13.6062 33.7078 14.0677 33.2772 14.3547C32.2485 15.0406 31.6343 16.2018 31.6343 17.4608C31.6343 18.7198 32.2485 19.881 33.2772 20.5668L34.4798 18.7629C34.0492 18.4758 33.8022 18.0012 33.8022 17.4607C33.8022 16.9202 34.0492 16.4457 34.4798 16.1586C35.5086 15.4727 36.1228 14.325 36.1228 13.0886C36.1228 11.8523 35.5086 10.7046 34.4798 10.0188Z"
                          fill="#074DB7"/>
                </svg>;
                break;
            default:
                break;
        }
        return r;
    }

    static getMenuTop(code: string) {
        let r = <></>;
        switch (code) {
            // Бытовая химия
            case HelperSvg.l1:
                r = <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.9261 6.96008L12.7072 5.19414C12.1914 4.91113 11.8215 4.4357 11.6666 3.88535C11.9755 3.86883 12.221 3.61395 12.221 3.30102C12.221 2.97742 11.9586 2.71508 11.635 2.71508H11.5859V0.585938C11.5859 0.262344 11.3236 0 11 0H6.7024C6.37881 0 6.11646 0.262344 6.11646 0.585938V2.71504H5.99354C5.66994 2.71504 5.4076 2.97738 5.4076 3.30098C5.4076 3.62457 5.66994 3.88691 5.99354 3.88691H6.11646V4.08621C6.11646 4.51816 5.98869 4.93578 5.74686 5.29391L4.91451 6.52672C4.54143 7.0793 4.34424 7.72363 4.34424 8.39016V17.323C4.34424 18.7991 5.54592 20 7.02299 20H14.9769C16.454 20 17.6557 18.7991 17.6557 17.323V9.88059C17.6557 8.66445 16.993 7.54535 15.9261 6.96008ZM7.28834 1.17188H10.414V2.71504H7.28834V1.17188ZM16.4838 17.323C16.4838 18.153 15.8078 18.8281 14.9769 18.8281H7.02303C6.19213 18.8281 5.51615 18.1529 5.51615 17.323V8.39016C5.51615 7.9582 5.64393 7.54059 5.88576 7.18246L6.71811 5.94965C7.09119 5.39707 7.28838 4.75273 7.28838 4.08621V3.88691H10.4663C10.6416 4.86824 11.251 5.7318 12.1436 6.22152L15.3625 7.98746C16.0541 8.36691 16.4838 9.09234 16.4838 9.88059V17.323Z"
                        fill="white"/>
                    <path
                        d="M13.8709 12.0982H8.12945C7.40598 12.0982 6.81738 12.6868 6.81738 13.4103V16.1218C6.81738 16.8453 7.40598 17.4339 8.12945 17.4339H13.8709C14.5943 17.4339 15.1829 16.8453 15.1829 16.1218V13.4103C15.1829 12.6868 14.5943 12.0982 13.8709 12.0982ZM14.011 16.1218C14.011 16.1991 13.9482 16.262 13.8709 16.262H8.12945C8.05215 16.262 7.98926 16.1991 7.98926 16.1218V13.4103C7.98926 13.333 8.05215 13.2701 8.12945 13.2701H13.8709C13.9482 13.2701 14.011 13.333 14.011 13.4103V16.1218Z"
                        fill="white"/>
                    <path
                        d="M10.6779 10.7695H13.8971C14.3914 10.7695 14.7936 10.3674 14.7936 9.87324V9.15812C14.7936 8.82176 14.6079 8.5168 14.3091 8.36219L11.0901 6.69695C10.9633 6.63129 10.8208 6.5966 10.6779 6.5966C10.1836 6.5966 9.78149 6.99863 9.78149 7.49281V9.8732C9.78149 10.3674 10.1836 10.7695 10.6779 10.7695ZM10.9534 7.94559L13.6217 9.32594V9.59758H10.9534V7.94559Z"
                        fill="white"/>
                </svg>;
                break;
            // Гигиена
            case HelperSvg.l2:
                r = <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1143:12940)">
                        <path
                            d="M1.20192 18.2422C1.20192 19.2114 2.0107 20 3.00481 20H9.01442C9.56538 20 10.0591 19.7575 10.3901 19.3766C11.0541 19.782 11.8242 20 12.6202 20H16.3061C18.6257 20 20.5128 18.16 20.5128 15.8984C20.5128 13.6368 18.6257 11.7969 16.3061 11.7969H12.6202C12.4131 11.7969 12.2079 11.8123 12.0052 11.8413C11.9398 10.6119 11.6534 9.91348 11.3752 9.23551C11.0883 8.53609 10.8173 7.87543 10.8173 6.52344C10.8173 5.10863 9.78333 3.92492 8.41346 3.6527V1.75781C8.41346 0.788555 7.60469 0 6.61058 0H5.40865C4.41454 0 3.60577 0.788555 3.60577 1.75781V3.6527C2.2359 3.92492 1.20192 5.10863 1.20192 6.52344C1.20192 7.87543 0.930889 8.53609 0.64395 9.23551C0.327364 10.0072 0 10.8051 0 12.3828C0 13.9605 0.327364 14.7584 0.64395 15.5301C0.930889 16.2295 1.20192 16.8902 1.20192 18.2422ZM11.3753 15.5301C11.6452 14.8722 11.9228 14.1947 11.9988 13.0316C12.2019 12.99 12.4097 12.9688 12.6202 12.9688H16.3061C17.9629 12.9688 19.3109 14.283 19.3109 15.8984C19.3109 17.5139 17.9629 18.8281 16.3061 18.8281H12.6202C11.9655 18.8281 11.3354 18.6217 10.8173 18.2422C10.8173 16.8902 11.0883 16.2295 11.3753 15.5301ZM4.80769 1.75781C4.80769 1.43473 5.07728 1.17188 5.40865 1.17188H6.61058C6.94195 1.17188 7.21154 1.43473 7.21154 1.75781V3.59375H4.80769V1.75781ZM1.7599 9.67074C2.07648 8.89906 2.40385 8.10113 2.40385 6.52344C2.40385 5.55418 3.21262 4.76562 4.20673 4.76562H7.8125C8.80661 4.76562 9.61539 5.55418 9.61539 6.52344C9.61539 8.10113 9.94275 8.89906 10.2593 9.67074C10.5463 10.3702 10.8173 11.0308 10.8173 12.3828C10.8173 13.7348 10.5463 14.3955 10.2593 15.0949C9.94275 15.8666 9.61539 16.6645 9.61539 18.2422C9.61539 18.5653 9.34579 18.8281 9.01442 18.8281H3.00481C2.67344 18.8281 2.40385 18.5653 2.40385 18.2422C2.40385 16.6645 2.07648 15.8666 1.7599 15.0949C1.47296 14.3955 1.20192 13.7348 1.20192 12.3828C1.20192 11.0308 1.47296 10.3702 1.7599 9.67074Z"
                            fill="white"/>
                        <path
                            d="M6.00958 16.4844C7.9978 16.4844 9.61535 14.9073 9.61535 12.9688C9.61535 11.9124 9.22617 11.3432 8.91342 10.8859C8.6341 10.4773 8.41342 10.1546 8.41342 9.45312C8.41342 8.16078 7.33506 7.10938 6.00958 7.10938C4.6841 7.10938 3.60573 8.16078 3.60573 9.45312C3.60573 10.1546 3.38506 10.4773 3.10573 10.8859C2.79299 11.3432 2.40381 11.9124 2.40381 12.9688C2.40381 14.9073 4.02136 16.4844 6.00958 16.4844ZM4.10573 11.536C4.41847 11.0786 4.80766 10.5095 4.80766 9.45312C4.80766 8.80695 5.34684 8.28125 6.00958 8.28125C6.67232 8.28125 7.2115 8.80695 7.2115 9.45312C7.2115 10.5095 7.60068 11.0786 7.91342 11.536C8.19275 11.9445 8.41342 12.2673 8.41342 12.9688C8.41342 14.2611 7.33506 15.3125 6.00958 15.3125C4.6841 15.3125 3.60573 14.2611 3.60573 12.9688C3.60573 12.2673 3.82641 11.9445 4.10573 11.536Z"
                            fill="white"/>
                        <path
                            d="M16.3061 10.625C17.3002 10.625 18.1089 9.83645 18.1089 8.86719C18.1089 7.89793 17.3002 7.10938 16.3061 7.10938C15.3119 7.10938 14.5032 7.89793 14.5032 8.86719C14.5032 9.83645 15.3119 10.625 16.3061 10.625ZM16.3061 8.28125C16.6374 8.28125 16.907 8.5441 16.907 8.86719C16.907 9.19027 16.6374 9.45312 16.3061 9.45312C15.9747 9.45312 15.7051 9.19027 15.7051 8.86719C15.7051 8.5441 15.9747 8.28125 16.3061 8.28125Z"
                            fill="white"/>
                        <path
                            d="M17.5079 5.9375C17.8398 5.9375 18.1089 5.67517 18.1089 5.35156C18.1089 5.02796 17.8398 4.76562 17.5079 4.76562C17.176 4.76562 16.907 5.02796 16.907 5.35156C16.907 5.67517 17.176 5.9375 17.5079 5.9375Z"
                            fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1143:12940">
                            <rect width="20.5128" height="20" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>;
                break;
            // Косметика и парфюмерия
            case HelperSvg.l3:
                r = <svg width="7" height="20" viewBox="0 0 7 20" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.58671 12.1601V7.45808C6.58671 7.24235 6.4118 7.06747 6.1961 7.06747H5.86529L5.85963 3.68775C5.85963 3.68521 5.85959 3.68275 5.85955 3.68025C5.881 3.43616 5.84584 3.16293 5.75276 2.86969C5.57304 2.30382 5.18989 1.70794 4.67381 1.19183C4.1577 0.675752 3.56182 0.292563 2.99594 0.112921C2.34245 -0.0946107 1.78814 -0.014262 1.43499 0.338928C1.22663 0.547241 1.11328 0.82559 1.09789 1.15296C1.0975 1.15882 1.09726 1.16476 1.09715 1.17069C1.09574 1.20948 1.0957 1.24897 1.09703 1.28909V7.06747H0.766574C0.550839 7.06747 0.375963 7.24235 0.375963 7.45808V12.1601C0.167025 12.1678 0 12.3396 0 12.5504V18.6113C0 19.3771 0.622946 20 1.3887 20H5.57394C6.33969 20 6.96264 19.3771 6.96264 18.6113V12.5504C6.96268 12.3396 6.79561 12.1678 6.58671 12.1601ZM1.15722 7.84869H5.80549V12.1598H1.15722V7.84869ZM2.33019 0.782076C2.45475 0.782076 2.6008 0.807114 2.75955 0.857503C3.20195 0.997966 3.6983 1.32116 4.12137 1.74423C4.54444 2.1673 4.86763 2.66369 5.00814 3.10609C5.11958 3.45702 5.10688 3.74568 4.97431 3.87825C4.84174 4.01086 4.55304 4.02352 4.20215 3.91208C3.75975 3.77161 3.26336 3.44842 2.84025 3.02531C2.41718 2.60224 2.09399 2.10586 1.95352 1.66349C1.90524 1.51135 1.88028 1.37096 1.87825 1.24987V1.21843C1.88071 1.07503 1.91727 0.961366 1.98739 0.891291C2.06 0.818715 2.17937 0.782076 2.33019 0.782076ZM1.87829 3.11656C2.00278 3.27511 2.13984 3.42971 2.28788 3.57775C2.804 4.09383 3.39988 4.47702 3.96575 4.6567C4.2036 4.7322 4.4282 4.76959 4.63487 4.76959C4.79486 4.76959 4.94408 4.74713 5.08017 4.7026C5.08138 5.41007 5.0829 6.34175 5.08407 7.06747H1.87829V3.11656ZM6.18145 18.6113C6.18145 18.9463 5.90893 19.2188 5.57398 19.2188H1.3887C1.05375 19.2188 0.781221 18.9463 0.781221 18.6113V12.941H6.18141V18.6113H6.18145Z"
                        fill="white"/>
                    <path
                        d="M1.97887 14.9694H4.98407C5.19977 14.9694 5.37468 14.7945 5.37468 14.5788C5.37468 14.363 5.19977 14.1881 4.98407 14.1881H1.97887C1.76313 14.1881 1.58826 14.363 1.58826 14.5788C1.58826 14.7945 1.76313 14.9694 1.97887 14.9694Z"
                        fill="white"/>
                </svg>;
                break;

            case String(HelperSvg.l4):
                r = <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1143:12949)">
                        <path
                            d="M19.8237 0.171563C19.5951 -0.0571477 19.2242 -0.0571477 18.9955 0.171563L11.3178 7.84927C9.87833 6.71337 7.78573 6.79669 6.4422 8.0993L0.2522 12.493C-0.0441718 12.7034 -0.0785292 13.1291 0.177082 13.3846L6.62101 19.8286C6.88396 20.0916 7.32296 20.0457 7.52586 19.7341L11.6935 13.3342C12.9167 12.0646 13.0571 10.1317 12.113 8.71051L19.8237 0.999772C20.0525 0.771061 20.0525 0.400274 19.8237 0.171563ZM9.02279 8.2242C11.0058 8.2242 12.2241 10.4061 11.1832 12.0941L7.69019 8.60111C8.08674 8.35554 8.54467 8.2242 9.02279 8.2242ZM6.93847 18.4896L5.98739 17.5385L7.6766 15.0049C7.85604 14.7357 7.78331 14.3721 7.51419 14.1927C7.24514 14.0133 6.8815 14.086 6.70206 14.3552L5.14278 16.694L1.49559 13.0468L6.74989 9.31727L10.4815 13.0489L6.93847 18.4896Z"
                            fill="white"/>
                        <path
                            d="M11.2378 19.0497C12.0094 19.6766 13.1343 19.5745 13.7455 18.8222C14.3567 18.0699 14.2262 16.9479 13.4546 16.3211C12.683 15.6942 11.5581 15.7963 10.9469 16.5486C10.3357 17.3009 10.4662 18.4229 11.2378 19.0497ZM12.7157 17.2306C12.9729 17.4396 13.0164 17.8136 12.8126 18.0643C12.6089 18.3151 12.2339 18.3491 11.9767 18.1402C11.7196 17.9312 11.676 17.5572 11.8798 17.3065C12.0835 17.0557 12.4585 17.0217 12.7157 17.2306Z"
                            fill="white"/>
                        <path
                            d="M15.8508 18.2116C16.1084 18.4208 16.4826 18.3869 16.6867 18.1357C16.8907 17.8846 16.8473 17.5113 16.5897 17.302C16.3321 17.0927 15.9579 17.1267 15.7538 17.3779C15.5498 17.629 15.5932 18.0023 15.8508 18.2116Z"
                            fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1143:12949">
                            <rect width="20" height="20" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>;
                break;
            //Продукты питания
            case HelperSvg.l5:
                r = <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1143:12954)">
                        <path
                            d="M16.1518 8.3227V7.21092H1.6246V9.99037C1.6246 12.5754 2.98719 14.8465 5.03809 16.1422H0.512817V16.6981C0.512817 18.2307 1.75968 19.4775 3.29226 19.4775H14.4842C16.0168 19.4775 17.2636 18.2307 17.2636 16.6981V16.1422H12.7383C13.2328 15.8298 13.686 15.4613 14.103 15.0304H15.596C17.7416 15.0304 19.4872 13.2682 19.4872 11.1021V8.3227H16.1518ZM16.0566 17.254C15.8272 17.9011 15.2089 18.3658 14.4842 18.3658H3.29226C2.56753 18.3658 1.94927 17.9011 1.71984 17.254H16.0566ZM8.88822 16.1422C5.49607 16.1422 2.73638 13.3825 2.73638 9.99037V8.3227H15.0401V9.99037C15.0401 13.3825 12.2804 16.1422 8.88822 16.1422ZM18.3754 11.1021C18.3754 12.6552 17.1285 13.9187 15.596 13.9187H14.9896C15.7459 12.7549 16.1518 11.4018 16.1518 9.99037V9.43448H18.3754V11.1021Z"
                            fill="white"/>
                        <path
                            d="M6.44484 0.522446L5.82814 1.44752C6.04894 1.59472 6.17557 1.83138 6.17557 2.09676C6.17557 2.36214 6.04894 2.5988 5.82814 2.746C5.30056 3.09773 4.9856 3.6932 4.9856 4.33885C4.9856 4.9845 5.3006 5.57996 5.82814 5.93166L6.44484 5.00658C6.22404 4.85938 6.09738 4.61598 6.09738 4.33881C6.09738 4.06165 6.22401 3.81828 6.44484 3.67104C6.97242 3.31931 7.28739 2.73077 7.28739 2.09672C7.28739 1.46268 6.97242 0.874138 6.44484 0.522446Z"
                            fill="white"/>
                        <path
                            d="M9.22438 0.522446L8.60768 1.44752C8.82848 1.59472 8.95511 1.83138 8.95511 2.09676C8.95511 2.36214 8.82848 2.5988 8.60768 2.746C8.0801 3.09773 7.76514 3.6932 7.76514 4.33885C7.76514 4.9845 8.08014 5.57996 8.60768 5.93166L9.22438 5.00658C9.00359 4.85938 8.87692 4.61598 8.87692 4.33881C8.87692 4.06165 9.00355 3.81828 9.22438 3.67104C9.75196 3.31931 10.0669 2.73077 10.0669 2.09672C10.0669 1.46268 9.75196 0.874138 9.22438 0.522446Z"
                            fill="white"/>
                        <path
                            d="M12.0409 0.522446L11.4242 1.44752C11.645 1.59472 11.7716 1.83138 11.7716 2.09676C11.7716 2.36214 11.645 2.5988 11.4242 2.746C10.8966 3.09773 10.5817 3.6932 10.5817 4.33885C10.5817 4.9845 10.8967 5.57996 11.4242 5.93166L12.0409 5.00658C11.8201 4.85938 11.6934 4.61598 11.6934 4.33881C11.6934 4.06165 11.8201 3.81828 12.0409 3.67104C12.5685 3.31931 12.8835 2.73077 12.8835 2.09672C12.8835 1.46268 12.5685 0.874138 12.0409 0.522446Z"
                            fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1143:12954">
                            <rect width="18.9744" height="18.9744" fill="white"
                                  transform="translate(0.512817 0.512817)"/>
                        </clipPath>
                    </defs>
                </svg>;
                break;
            default:
                break;
        }
        return r;
    }
}
