import {Model} from "./";
import {Api} from "../api";

class BasketModel extends Model {

    constructor() {
        super(new Api("/order"));
    }

    addOrder(ob, thenFunction, errorFunction) {
        this.q.setDopUrl('');
        this.q.create(ob, thenFunction, errorFunction);
    }

    deleteOrder(id,thenFunction, errorFunction) {
        this.q.setDopUrl('/'+id);
        this.q.delete({},thenFunction, errorFunction);
    }

    getOrders(data, thenFunction, errorFunction) {
        this.q.setDopUrl('/list');
        this.q.read(data, thenFunction, errorFunction);
    }

    repeat(ob,thenFunction, errorFunction){
        this.q.setDopUrl('/repeat');
        this.q.create(ob, thenFunction, errorFunction);
    }

    changeStatus(id,thenFunction, errorFunction){
        this.q.setDopUrl('/status-created');
        this.q.create({id:id}, thenFunction, errorFunction);
    }

    getProductInfoOrder(id, thenFunction, errorFunction) {
        this.q.setDopUrl('/order-group-product/'+id);
        this.q.read({}, thenFunction, errorFunction);
    }
    getOrderWebPayLink(id, thenFunction, errorFunction) {
        this.q.setDopUrl('/order-web-pay-link/'+id);
        this.q.read({}, thenFunction, errorFunction);
    }

    getOrderCsv(id, thenFunction, errorFunction) {
        this.q.setDopUrl('/order-csv/'+id);
        this.q.read({}, thenFunction, errorFunction);
    }
    // getTypes(clientId,thenFunction, errorFunction) {
    //     this.q.setDopUrl('/types/'+clientId);
    //     this.q.read({}, thenFunction, errorFunction);
    // }

}

export default new BasketModel();
