// @ts-ignore
import React from 'react';
import {withStore} from "../hocs";
import {observer} from "mobx-react";
import {withRouter} from "react-router";

// @ts-ignore
@withStore
@observer
class ConfirmPageEmail extends React.Component {
    componentDidMount() {
        // @ts-ignore
        const {store} = this.props;
        // @ts-ignore
        const code = this.props.match.params.code;
        store.pageConfirmStore.init(code);
        store.mainStore.setRedirectUrl('');
    }

    render() {

        // @ts-ignore
        const {store} = this.props;
        const { email, error} = store.pageConfirmStore;

        return (
            <>
                <main className="page__main">
                    <div className="container page-not-found">
                        {error.length ? <div className="error">{error}</div> :  <p className="page-not-found__description">Email {email} подтверждён</p>}
                    </div>
                </main>
            </>
        )
    }
}

// @ts-ignore
export default withRouter(ConfirmPageEmail);
