import {RootStore} from '../index';
import {action, makeAutoObservable} from "mobx"
import PaymentModel from "../../model/PaymentModel";

import md5 from "md5";


export class WebPayStore {


    private rootStore: RootStore | undefined;

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    @action
    paymentAction = async () => {
        let arBasket = this.rootStore?.pageBasketStore.arBasket
        let count = []
        let price = []
        let name = []
        let summAll = Number(this.rootStore?.pageBasketStore.summAll)
        let summNds = Number(this.rootStore?.pageBasketStore.summNds)

        // @ts-ignore
        for (let i = 0; i< arBasket?.length ; i++){
                if (arBasket) {
                    // @ts-ignore
                    count.push(Number(arBasket[i].count))
                    // @ts-ignore
                    price.push(Number(arBasket[i].product?.price?.final_price))
                    // @ts-ignore
                    name.push(arBasket[i].product.name)
                }
        }

        let wsb_seed: string = "123456789987654321"
        let wsb_storeid: string = "143654255"
        let wsb_order_num: string = "ORDER-12666345678"
        let wsb_test: string = "1"
        let wsb_currency_id: string = "BYN"
        let wsb_total: string = ""
        let secret_key = "123456789987654321"
        let wsb_signature: string = ""
        let arrayForRequest:any = {}

        if (summAll){
            // @ts-ignore
            wsb_total= String(summAll)

            wsb_signature= md5(wsb_seed + wsb_storeid + wsb_order_num + wsb_test + wsb_currency_id + wsb_total + secret_key)

            arrayForRequest =
                {"wsb_storeid": "143654255",
                    "wsb_order_num": "ORDER-12666345678",
                    "wsb_currency_id": "BYN",
                    "wsb_version": 1,
                    "wsb_seed": "123456789987654321",
                    "wsb_test": 1,
                    "wsb_invoice_item_name": name,
                    "wsb_invoice_item_quantity": count,
                    "wsb_invoice_item_price": price,
                    "wsb_total": summAll,
                    "wsb_signature": wsb_signature,
                    "wsb_3ds_payment_option": "force_3ds",
                    "wsb_store": "beautyhouse",
                    "wsb_language_id": "russian",
                    "wsb_return_url": "http://localhost:3000/order",
                    "wsb_cancel_return_url": "http://localhost:3000/order",
                    "wsb_notify_url": "http://localhost:3000/order",
                    "wsb_customer_name": "Иванов Петр Петрович",
                    "wsb_customer_address": "Минск ул. Шафарнянская д.11 оф.54",
                    "wsb_service_date": "Доставка до 1 января 2022 года",
                    "wsb_tax": summNds,
                    "wsb_shipping_name": "Стоимость доставки",
                    "wsb_shipping_price": 0,
                    "wsb_discount_name": "Скидка на товар",
                    "wsb_discount_price": 0,
                    "wsb_order_tag": "Договор №152/12-1 от 12.01.19",
                    "wsb_email": "ivanov@test.by",
                    "wsb_phone": "375291234567",
                    "wsb_order_contract": "Договор №152/12-1 от 12.01.19",
                    "wsb_tab": "cardPayment",
                    "wsb_startsesstime": "1603383601",
                    "wsb_startsessdatetime": "2020-10-22T16:20:01+03:00"}
            PaymentModel.webPay(arrayForRequest, this.setModalData, this.setError)
        }

    }
    @action
    setModalData = (result: any) => {
        if (result.data.data.redirectUrl){
            window.location.replace(result.data.data.redirectUrl);
        }

    }
    @action
    setError = (error: any) => {
        console.log(error)
    }

}
