import React from 'react';
import {withStore} from "../../hocs";
import {observer} from "mobx-react";
import {WIDTH} from "../../const/root";

interface Props {
    item: {};
    isDetail: boolean;
    type: string;
}

// @ts-ignore
@withStore
@observer
export class BasketItem extends React.Component<Props, {}> {

    render() {
        let item: any = this.props.item;
        let isDetail: boolean = this.props.isDetail;
        let type: string = this.props.type;
        const price = parseFloat(item.price?.final_price);
        const stocks = item.stocks

        // @ts-ignore
        const {store} = this.props;
        const {minusCount, addCount, addProduct, getCount, changeProductInputCatalogPage} = store?.pageBasketStore;

        let inBasketClass = '';
        let inBasketText = 'В корзину';

        if (item?.basket?.count) {
            inBasketClass = 'in-basket';
            inBasketText = 'В корзине';
        }


        let Width = window.innerWidth;

        let viewDesktop = store.pageCatalogStore.viewCatalogClass
        let viewMobile = store.pageCatalogStore.viewCatalogClassMobile

        let view = ""

        if (Width < WIDTH){
            view = viewMobile
        }else {
            view = viewDesktop
        }

        if (window.location.pathname === "/" || window.location.search.includes("?p=") || type === "Бестселлеры" ||  type ==="Недавно просмотренные товары"){
            view = ""
        }


        return (
            <>
                {price && stocks.length > 0  ? <>
                    {isDetail ?
                        <>
                            <div  className="single-product__counter counter">
                                <button

                                    onClick={() => minusCount(item)}
                                    className="counter__control counter__control--minus"
                                    type="button"><span
                                    className="visually-hidden">Уменьшить на единицу</span>
                                </button>

                                <input className="counter__field " type="text" value={getCount(item)}
                                       onChange={(e) => changeProductInputCatalogPage(e, item)}
                                />

                                <button
                                    onClick={() => addCount(item)}
                                    className="counter__control counter__control--plus"
                                        type="button"><span
                                    className="visually-hidden">Увеличить на единицу</span>
                                </button>

                            </div>
                            <button
                                style={Width<WIDTH ? {padding:0}:{}}
                                onClick={() => addProduct(item)}
                                className={"single-product__add-cart button button--accent-dark " + inBasketClass}
                                    type="button">{inBasketText}
                            </button>
                        </>
                        :
                        <>
                            <div
                                style={Width<WIDTH ?{minWidth: "140px", marginBottom: 10}: {}}
                                className="goods__counter counter" >
                                <button
                                    onClick={() => minusCount(item)}
                                    className="counter__control counter__control--minus"
                                    type="button"><span
                                    className="visually-hidden">Уменьшить на единицу</span>
                                </button>

                                <input className="counter__field" type="text"
                                       value={getCount(item)}
                                       onChange={(e) => changeProductInputCatalogPage(e, item)}/>

                                <button
                                    onClick={() => addCount(item)}
                                    className="counter__control counter__control--plus"
                                    type="button"><span
                                    className="visually-hidden">Увеличить на единицу</span>
                                </button>

                            </div>

                            {view === "goods-catalog__list--table" && Width<WIDTH ? <div  onClick={() => addProduct(item)} style={inBasketClass.length > 0 ? {backgroundColor: "#f3e5e8", height: 50, width: 60} : {backgroundColor: "#b17882", height: 50, width: 60}}>
                                <div style={{marginTop: 10, marginLeft: 10}}>
                                    <svg className="link-indicator__icon" width="40" height="30">
                                        <use xlinkHref="/images/sprite.svg#icon--cart" />
                                    </svg>
                                </div>

                            </div> :   <button
                                style={Width<WIDTH ? {padding:0}:{}}
                                onClick={() => addProduct(item)}
                                className={"goods__add-cart button button--accent " + inBasketClass} type="button">
                                {inBasketText}
                            </button>}

                        </>

                    }
                    </>
                    : <div style={{fontWeight: "bold", color: "red"}}>
                        Нет в остатках
                    </div>}



            </>
        );
    }
}
