import {Model} from "./";
import {Api} from "../api";

class WebPayModel extends Model {

    constructor() {
        super(new Api("/webpay"));
    }

    // addWebPayOrder(ob, thenFunction, errorFunction) {
    //     this.q.setDopUrl('');
    //     this.q.create(ob, thenFunction, errorFunction);
    // }

    getOrderWebPayLink(id, thenFunction, errorFunction) {
        this.q.setDopUrl('/order-web-pay-link/'+id);
        this.q.read({}, thenFunction, errorFunction);
    }



}

export default new WebPayModel();
