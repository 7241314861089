import React from 'react';
import {withStore} from "../../hocs";
import {observer} from "mobx-react";

// @ts-ignore
@withStore
@observer
export class PersonalPhoto extends React.Component {
    componentDidMount() {
        // @ts-ignore
        const {store} = this.props;
        let isAuth = store.mainStore.isAuth;
        if(!isAuth){
            store.appStore.setPopupAccessDenied(true)
        }
        // store.pagePersonalStore.getInfo()

    }
    render() {
        // @ts-ignore
        const {store} = this.props;
        const {name} = store.mainStore;
        const {loyaltyLevel} = store.pagePersonalStore;


        return (
            <>
                <div className="user-account__preview user-preview">
                    <div className="user-preview__content"><span className="user-preview__welcome">Добро пожаловать,</span>
                        <p className="user-preview__name">{name}</p><span
                            className="user-preview__pro">{loyaltyLevel}</span>
                    </div>
                </div>
            </>
        )
    }
}
