import React from 'react';
import {withStore} from "../../hocs";
import {observer} from "mobx-react";

interface Props {
    item: any
    store: any
    showArray: any
    filterActive: any
    index: number
}

// @ts-ignore
@withStore
@observer
export class SubHeaderMenu extends React.Component<Props, {}> {


    render() {
        // @ts-ignore
        const store = this.props.store.appStore;
        // @ts-ignore
        let item = this.props.item;
        let filterActive = this.props.filterActive
        let index = this.props.index

        let id = item['id'];
        let name = item['name'];
        let code = item['code'];
        let enums = item['enum'];
        let showArray = this.props.showArray
        let Width = window.innerWidth;

        return (<>
                {Width > 1024 ?
                    <>
                        <div className="sub-catalog__item-col" key={index}>
                            {name === 'Палитра' ?
                                <>
                                    <span className="sub-catalog__item-title js--sub-catalog-toggle">{name}</span>
                                    <span>
                                    <a href={'/catalog/filter/beautyHouseCategory-in-' + Object.keys(filterActive)[0] + '?1'}>
                                    <span className="sub-catalog__link js--sub-catalog-toggle">
                                        /Все палитры
                                    </span>
                                    </a>
                                </span>
                                </>
                                : <p className="sub-catalog__item-title js--sub-catalog-toggle">{name}</p>
                            }

                            <ul style={{width: 200}} className="sub-menu">
                                {enums ? enums.map((item: any, index: any) => (
                                    <li key={index}>
                                        <a className="sub-menu__link"
                                           href={this.props.store.componentFilterCatalogStore.url1}
                                           onMouseEnter={() => this.props.store.componentFilterCatalogStore.setUrlPageMouseEnter(item.id, code)}
                                           onClick={() => this.props.store.componentFilterCatalogStore.setUrlPageMouseEnter(item.id, code)}>{item.value}</a>
                                    </li>
                                )) : null}
                            </ul>
                        </div>
                    </>
                    :
                    <>
                        <div className="sub-catalog__item-col" key={index}>
                            {name === 'Палитра' ?
                                <>
                                    <span className="sub-catalog__item-title js--sub-catalog-toggle">{name}</span>
                                    <span>
                              <a href={'/catalog/filter/beautyHouseCategory-in-' + Object.keys(filterActive)[0] + '?1'}>
                                    <span className="sub-catalog__link js--sub-catalog-toggle">
                                        /Все палитры
                                    </span>
                                    </a>
                                </span>
                                </>
                                : <>
                                    <p className="sub-catalog__item-title js--sub-catalog-toggle"
                                       onClick={() => store.setOpenSubMenuCatalog(name)}>{name}</p>
                                    {showArray ? showArray.map((item: any, index: any) => (
                                        <div key={index}>
                                            {item.show === true && item.name === name && item.enum ? item.enum.map((item: any, index: any) => (
                                                <div key={index}
                                                     className="sub-catalog__item-title js--sub-catalog-toggle"
                                                     style={{paddingLeft: 10}}
                                                     onMouseOver={() => this.props.store.componentFilterCatalogStore.setUrlPageMouseEnter(item.id, code)}>
                                                    <a href={this.props.store.componentFilterCatalogStore.url1}> {item.value ? " - " : null} {item.value} </a>
                                                </div>
                                            )) : null}
                                        </div>
                                    )) : null}
                                </>
                            }
                        </div>
                    </>}
            </>
        );
    }
}


