import React from 'react';
import {AnyQuestions} from "../components/block/AnyQuestions";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";



export default class LoyaltyInfoPage extends React.Component {
    componentDidMount() {

        if (window.location.search && window.location.search === "?2" && window.innerWidth > 800 ){
            // @ts-ignore
            window.scrollTo(0, 3000)
        }else if(window.location.search && window.location.search === "?2" && window.innerWidth < 800) {
            // @ts-ignore
            window.scrollTo(0, 4500)
        }else {
            // @ts-ignore
            window.scrollTo(0, 0)
        }
    }


    render() {

        return (
            <>
                <main className="page__main">
                    <div className="container">
                        <ul className="breadcrumbs">
                            <li className="breadcrumbs__item"><a className="breadcrumbs__link" href="/">Главная</a></li>
                            <li className="breadcrumbs__item"> Программа лояльности для клиентов</li>
                        </ul>
                        <h1 className="page__title">ПРОГРАММА ЛОЯЛЬНОСТИ ДЛЯ КЛИЕНТОВ</h1>
                        <div className="page__content content">
                            <p>
                                Правила Программы Лояльности «Beauty House Club» (далее – Правила) с 01.02.2024
                            </p>
                            <br/>
                            <h2 className="page__title">ОБЩИЕ ПОЛОЖЕНИЯ</h2>
                            <p>
                                Настоящие Правила определяют условия участия в программе «Beauty House Club»
                                (далее –Программа) на территории Республики Беларусь.
                            </p>
                            <p>
                                Вступив в Программу, Участник подтверждает, что он ознакомлен с настоящими Правилами,
                                выражает свое безусловное согласие с настоящими Правилами и обязуется их выполнять.
                            </p>
                            <p>
                                Организатором Программы является ООО «Сэльвин», место нахождения: 220084, г. Минск, ул. Академика Купревича,
                                14, каб. 37, 4 этаж; УНП 100819821; ОКПО 28658965.
                                Срок проведения программы: с 01 февраля 2024 до тех пор, пока Программа не будет прекращена решением
                                Организатора в порядке, установленном настоящими Правилами.
                            </p>
                            <p>
                                Цели Программы:
                            </p>
                            <p>
                                1. Формирование и расширение круга постоянных покупателей интернет-магазина сайта https://beautyhouse.by/
                                - профессионалов, работающих в индустрии красоты;
                            </p>
                            <p>
                                2. поощрение лояльности покупателей, стимулирование повторных покупок;
                            </p>
                            <p>
                                3. продвижение ассортимента интернет-магазина https://beautyhouse.by/
                            </p>
                            <p>
                                Все действия лиц, желающих принять участие в Программе, участников Программы,
                                Организатора фиксируются по Минскому времени. Программа реализуется на
                                территории Республики Беларусь в соответствии с настоящими Правилами.
                            </p>
                            <h2 className="page__title">ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h2>
                            <p>
                                «Участник» - физическое лицо – сотрудник индустрии красоты (парикмахеры и стилисты,
                                работающие как в салонах, так и самостоятельно) соответствующее установленным
                                настоящими Правилами требованиям и выполнившее установленные настоящими Правила действия.
                                Обязательным Условием участия в Программе является наличие Beauty ID.
                            </p>
                            <p>
                                «Beauty ID» - Beauty ID e-academie.ru, единый аккаунт профессионала для сервисов на бьюти рынке.
                            </p>
                            <p>
                                «Товары» - парфюмерно-косметическая продукция, реализуемая посредством Сайта Официального
                                интернет-магазина BeautyHouse  https://beautyhouse.by/
                            </p>
                            <p>
                                «Сайт» - сайт в сети интернет по адресу  https://beautyhouse.by/ , посредством которого
                                осуществляется дистанционная розничная продажа Товаров.
                            </p>
                            <p>
                                «Бонусный Счет Участника» (Бонусный счет, Счет) – счет, открываемый Организатором в
                                информационной системе Организатора на имя Участника. Счет ведется в Бонусах. Бонусы
                                начисляются на счет Участника при осуществлении покупок на Сайте и списываются со Счета
                                Участника при заказе. Участник вправе управлять своим Бонусным Счетом самостоятельно
                                (накапливать, списывать Бонусы).
                            </p>
                            <p>
                                «Личный кабинет Участника Программы» - раздел Сайта, открываемый Организатором
                                Участнику Программы после регистрации и содержащий информацию о накопленных на Бонусном
                                Счете Участника Бонусах, информацию о Программе, доступных привилегиях, действиях
                                Участника в Программе (в том числе историю покупок), а также иную информацию,
                                размещаемую по усмотрению организатора.
                            </p>
                            <p>
                                «Товары-подарки» - парфюмерно-косметическая продукция, отмеченная соответствующим ярлыком,
                                реализуемая посредством Сайта Официального интернет-магазина BeautyHouse https://beautyhouse.by/ в т.ч. за бонусные баллы.
                            </p>
                            <h2 className="page__title">Бонусные баллы и их разновидности</h2>

                            <p>
                                «Базовые Бонусы» (баллы) – Бонусные единицы (баллы), зачисляемые на Бонусный счет
                                Участника за покупки, совершенные им на сайте  https://beautyhouse.by/ после авторизации.
                                Базовые бонусы обладают сроком действия 30 дней с момента активации
                                (через 14 дней после доставки заказа), по истечении которого они автоматически
                                списываются с Бонусного счет Участника.
                            </p>
                            <p>
                                «Замороженные бонусы» - это баллы, положенные к начислению за покупку, совершенную после
                                авторизации на сайте, и отображаемые на счете пользователя серым цветом сразу после
                                оформления заказа, но фактически не доступные для использования до истечения 14 дней
                                после даты доставки заказа.
                            </p>
                            <p>
                                «Уровни Участника» -  Уровень участника в программе лояльности определяет коэффициенты
                                при начислении баллов, возможность использовать баллы для оплаты подарков из раздела
                                «Каталог вознаграждений», и другие преимущества, доступные участнику в рамках Программы.
                            </p>
                            <p>
                                «Персональные данные» - данные, которые оставил Участник при регистрации в программе на
                                Сайте или заполнил в личном кабинете участника, подлежат обработке в соответствии с
                                Политикой обработки персональных данных ООО Сэльвин. Участник несет ответственность за
                                достоверность оставленных данных, при обнаружении некорректности данных Организатор
                                имеет право в одностороннем порядке прекратить участие недобросовестного участника в программе.
                            </p>
                            <h2 className="page__title">1. ТРЕБОВАНИЯ К УЧАСТНИКАМ. ПОРЯДОК И УСЛОВИЯ УЧАСТИЯ В ПРОГРАММЕ.</h2>
                            <p>
                                1.1. Участниками Программы автоматически становятся физические лица, достигшие 18 лет,
                                зарегистрировавшиеся на сайте  https://beautyhouse.by/ в качестве Мастера.
                            </p>
                            <p>
                                1.2 Beauty ID является основным идентификатором Участника Программы.
                            </p>
                            <p>
                                1.3. После авторизации в Личном кабинете на вкладке «Программа Лояльности» Участник
                                может получить информацию о своем статусе в Программе лояльности, о доступных
                                привилегиях, о движении баллов на Бонусном счете: размер изменения, основание
                                для списании или начисления, срок действия баллов (при начислении).
                            </p>
                            <p>
                                1.4. При достижении второго уровня Участнику открывается возможность одновременно с
                                заказом товаров из основного каталога https://beautyhouse.by/ заказывать подарки
                                в Разделе «Каталог вознаграждений», оплачивая их баллами.
                            </p>
                            <p>
                                1.5. Участник обязуется самостоятельно отслеживать информацию в Личном кабинете
                                Участника Программы на Сайте, содержащую информацию о балансе Бонусов, историю покупок
                                и прочую информацию Программы. Если участник выявляет несоответствие информации с
                                фактическими данными, Участнику следует сообщить об этом в письме на адрес электронной
                                почты info@bh.by с темой письма «Ошибка в работе Программы лояльности».
                            </p>
                            <p>
                                1.6. На одного участника программы с одним Beauty ID может быть оформлен только один
                                Бонусный счет. Бонусные счета, принадлежащие одному или нескольким Участникам, не объединяются.
                            </p>
                            <p>
                                1.6.1. Если Организатором Программы будет обнаружен факт регистрации более 1
                                Бонусного счета на одного Участника, то Участник вправе самостоятельно выбрать для
                                использования один из аккаунтов с бонусным счетом по своему выбору при этом перенос
                                Бонусов или ранее оформленных заказов из одного аккаунта на другой не предусмотрен.
                            </p>
                            <p>
                                1.6.2. Признаками регистрации аккаунта на одно и то же лицо считаются: совпадение
                                адреса электронной почты и\или номера телефона, привязанных к Beauty ID Участника,
                                с аналогичным параметром в другом аккаунте.
                            </p>
                            <p>
                                1.7. Организатор вправе прекратить участие в Программе любого Участника без
                                предупреждения в случаях, если участник в том числе, но не ограничиваясь:
                            </p>
                            <p>
                                Не соблюдает положения настоящих Правил;
                            </p>
                            <p>
                                Злоупотребляет правами, предоставляемыми участнику в рамках Программы;
                            </p>
                            <p>
                                Предоставляет информацию, вводящую в заблуждение, или недостоверные сведения Организатору;
                            </p>
                            <p>
                                Использует Программу в рамках осуществления предпринимательской деятельности;
                            </p>
                            <p>
                                Совершает мошеннические или иные незаконные действия в рамках Программы;
                            </p>
                            <p>
                                Распространяет недостоверную информацию о Программе, а равно распространяет
                                недостоверную информацию о товарах, организаторе или информацию порочащую деловую
                                репутацию Организатора, лиц, привлеченных Организатором к реализации положения
                                настоящей Программы.
                            </p>
                            <p>
                                В случаях прекращения участия в Программе по решению Организатора
                                (п. 1.9. настоящих Правил) Организатор аннулирует бонусы Участника без предоставления
                                взамен каких-либо компенсаций.
                            </p>
                            <p>
                                1.8. Участник вправе не пользоваться Привилегиями Программы лояльности.
                            </p>
                            <p>
                                1.9. Участие в программе подразумевает согласие на получение участником сообщений,
                                обеспечивающих выполнение настоящих Правил. При этом Участник сохраняет за собой
                                право отказаться от уведомлений рекламного характера одним из следующих способов:
                            </p>
                            <p>
                                1.10. Путем отказа от получения рекламной рассылки в Личном кабинете;
                            </p>
                            <p>
                                1.11. путем перехода по ссылке «Отписаться», в электронном письме, содержащем рекламную рассылку.
                            </p>
                            <h2 className="page__title">2. УРОВНИ УЧАСТНИКОВ ПРОГРАММЫ ЛОЯЛЬНОСТИ</h2>
                            <p>
                                2.1. Информация об уровнях Программы, требованиях для их получения и доступных привилегиях:
                            </p>

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Название</TableCell>
                                            <TableCell>Требования для получения</TableCell>
                                            <TableCell align="left">Доступные привилегии</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="left">Уровень 1 (Базовый)</TableCell>
                                                <TableCell component="th" scope="row">Регистрация на сайте с последующим присвоением Beauty ID.
                                                    Сумма доставленных покупок более 500 бел. руб. за последние 365 дней.
                                                </TableCell>
                                                <TableCell align="left">Накопление баллов на Бонусном счете в размере 3% от суммы заказа на сайте без учета стоимости доставки.
                                                    Использование баллов для оплаты покупок.
                                                </TableCell>
                                            </TableRow>
                                        <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="left">Уровень 2 (Знаток)</TableCell>
                                                <TableCell component="th" scope="row">Регистрация на сайте с последующим присвоением Beauty ID.
                                                    Сумма доставленных покупок 1000,00-1499,99 бел. руб. за последние 365 дней.

                                                </TableCell>
                                                <TableCell align="left">Накопление баллов в размере 6 % от суммы заказа
                                                    на сайте без учета стоимости доставки. Использование баллов для оплаты покупок.
                                                </TableCell>
                                            </TableRow>
                                        <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="left">Уровень 3 (Эксперт)</TableCell>
                                                <TableCell component="th" scope="row">Регистрация на сайте с последующим присвоением Beauty ID.
                                                    Сумма доставленных покупок больше 1500,00 бел. руб. за последние 365 дней.

                                                </TableCell>
                                                <TableCell align="left">Накопление баллов в размере 9% от суммы заказа
                                                    на сайте без учета стоимости доставки. Использование баллов для оплаты покупок.
                                                </TableCell>
                                            </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <p>
                                2.2. Повышение и понижение уровня.
                            </p>
                            <p>
                                1 уровень является постоянным.
                            </p>
                            <p>
                                Переход на уровень выше происходит сразу по достижении клиентом суммы покупок без учета
                                стоимости доставки. Под покупками понимаются доставленные и оплаченные Заказы. Возврат
                                Заказа приравнивается к отсутствию заказа.
                            </p>
                            <p>
                                2 и 3 уровни являются временными и подлежат понижению на один уровень вниз, если
                                Участник не совершил покупок на сумму, необходимую для поддержания уровня в течении 365 дней.
                            </p>
                            <p>
                                Пример:
                            </p>
                            <p>
                                Уровень 3 (эксперт) был присвоен Участнику 1 июня 2024 года. 2 июня 2025 года уровень
                                понизится до уровня 2, если сумма покупок, совершенных в последние 365 дней, составит
                                меньше 1500 бел. руб., и до уровня 1, если она составит менее 500 бел. руб.
                            </p>
                            <p>
                                Участнику начисляются Бонусы в соответствии с текущим уровнем за покупку любых товаров
                                на сайте, но только при условии, что он оформляет заказ, будучи авторизованным на сайте.
                            </p>
                            <p>
                                Накопление баллов в размере, соответствующему вновь присвоенному Уровню, возможно сразу
                                после доставки заказа.
                            </p>
                            <h2 className="page__title">3. НАЧИСЛЕНИЕ БОНУСОВ.</h2>

                            <p>
                                3.1. Бонусы начисляются авторизованным на сайте https://beautyhouse.by/ Участникам
                                Программы Лояльности при покупке Товаров в соответствии с Уровнем Участника.
                            </p>
                            <p>
                                Бонусные баллы начисляются только за фактически оплаченную Участником стоимость товаров.
                            </p>
                            <p>
                                Сумма начисления и срок действия баллов всегда указан в Личном кабинете – в комментариях
                                к начислениям.
                            </p>
                            <p>
                                Бонусные баллы не начисляются за покупки из раздела «Акции», а также за товары,
                                оплаченные бонусными баллами.
                            </p>
                            <p>
                                3.1.1. Условия начисления Бонусных баллов за совершение покупок для Участников с разными
                                уровнями.
                            </p>
                            <p>
                                Бонусы, подлежащие начислению за покупку Товаров, предоставляют собой процент от итоговой
                                стоимости товаров в заказе – стоимость доставки при этом не учитывается.
                            </p>
                            <p>
                                Участники, имеющие 1 уровень – «Базовый», имеют право на начисление 3% от итоговой
                                стоимости заказа.
                            </p>
                            <p>
                                Участники, имеющие 2 уровень – «Знаток», имеют право на начисление 6% от итоговой
                                стоимости заказа.
                            </p>
                            <p>
                                Участники, имеющие 3 уровень – «Эксперт», имеют право на начисление 9% от итоговой
                                стоимости заказа.
                            </p>
                            <p>
                                Пример:
                            </p>
                            <p>
                                Участник сделал заказ общей суммой на 500 бел. руб. В тот же день на балансе
                                отобразится и через 14 дней после изменения статуса заказа на «Доставлен», ему будет
                                доступно для использования:
                            </p>
                            <p>
                                15 баллов, если у него уровень 1 «Базовый»,
                            </p>
                            <p>
                                30 баллов, если у него уровень 2 «Знаток»,
                            </p>
                            <p>
                                45 баллов, если у него уровень 3 «Эксперт».
                            </p>
                            <p>
                                3.2. Бонусы отображаются на счете Участника в Личном кабинете
                                сразу после совершения покупки, при этом становится доступными
                                для использования через 14 дней после после изменения статуса заказа на «Доставлен».
                            </p>
                            <p>
                                Пример:
                            </p>
                            <p>
                                Заказ был доставлен 1 июля 2024 года. Бонусы станут доступны для использования ориентировочно 15 июля 2024 года.
                            </p>
                            <h2 className="page__title">4. ИСПОЛЬЗОВАНИЕ И СПИСАНИЕ БОНУСОВ.</h2>
                            <p>
                                4.1. накопленные Участником программы Бонусы могут быть использованы на следующих условиях:
                            </p>
                            <p>
                                4.1.1. Право на использование Бонусов возникает у Участника при достижении уровня 1
                                Программы лояльности.
                            </p>
                            <p>
                                4.1.2. Участник имеет право оплатить Бонусами до 99% стоимости Товаров-подарков из соответствующего
                                раздела каталога интернет-магазина.
                            </p>
                            <p>
                                4.2. При возврате Товаров и отмене заказов, бонусы, начисленные за их покупку, списываются.
                            </p>
                            <p>
                                4.3. Организатор списывает со Счета Участника любые Бонусы, начисленные по ошибке.
                            </p>
                            <p>
                                4.4. Бонусы, начисленные за покупки, подлежат списанию, через 365 дней со дня перехода
                                бонусов в категорию «Доступны», если они не были использованы Участником.
                            </p>
                            <p>
                                Пример:
                            </p>
                            <p>
                                Заказ был доставлен 1 июня 2024 года. Бонусы станут доступны для использования 15 июня
                                2024 года. Если Участник не использует их, они будут автоматически списаны 15 июля 2024 года.
                            </p>
                            <p>
                                4.5. Промо баллы сгорают по окончании срока действия, указанного Организатором.
                            </p>
                            <h2 className="page__title">5. ИНЫЕ УСЛОВИЯ.</h2>
                            <p>
                                5.1. Информация по накопленным Бонусам на счете Участника не отображается в основном
                                чеке. Информацию о начисленных Бонусах можно получить в Личном кабинете Участника
                                Программы на сайте https://beautyhouse.by/ или по телефону контактного центра +375 (44) 748-08-23.
                            </p>
                            <p>
                                5.2. Организатор по своему усмотрению вправе вносить любые изменения в настоящие
                                Правила в любое время. Измененные Правила публикуются на Сайте.
                            </p>
                            <p>
                                5.2.1. в случае, если в результате внесения изменений в настоящие Правила, меняется
                                порядок расчета, начисления и/или списания Бонусов, а также условия присвоения уровня
                                Участника, все ранее полученные Бонусы и уровни Участников подлежат корректировке
                                (списанию, уменьшению, начислению) в соответствии с новыми требованиями на дату
                                начала действия новой редакции Правил.
                            </p>
                            <p>
                                5.3. Организатор оставляет за собой право приостановить или прекратить Программу в
                                любое время с уведомлением Участников за 15 (пятнадцать) календарных дней. Уведомление
                                о приостановлении или прекращении Программы может быть направлено, в том числе,
                                путем публикации на Сайте. Организатор не несет ответственности за приостановку или
                                прекращение Программы в отношении любого Счета Участника, включая, но не ограничиваясь,
                                отнесенностью за Бонусы на Счете Участника в момент приостановки или прекращения Программы.
                            </p>
                            <p>
                                5.4. Ответственность Организатора в отношении Товаров, приобретаемых с помощью Бонусов,
                                ограничена стоимостью таких Товаров.
                            </p>
                            <p>
                                5.5. Персональные данные, предоставляемые лицом, желающим принять участие в Программе,
                                а равно Участником Программы в соответствии с настоящими Правилами (в том силе фамилия,
                                имя, отчество, номер мобильного телефона, адрес электронной почты, история покупок,
                                дата рождения, город), используется Организатором только в целях проведения Программы
                                и исключительно в течении срока, требуемого для принятия решения о допуске лица к
                                участию в Программе (в отношении лица, признанного Участником Программы).
                            </p>
                            <p>
                                5.6. Принимая участие в Программе, лицо, желающее принять участие в Программе, а равно
                                Участник, выражает согласие на обработку Организатором его персональных данных
                                указанных в п.5.5. Правил целях и на указанный в п. 5.5. срок.
                            </p>
                            <p>
                                Согласие предоставляется на совершение следующих действий (операций) с персональными
                                данными: сбор, запись, систематизация, накопление, хранение, уточнение (обновление,
                                изменение), использование, передача (в том числе распространение, предоставление
                                определенному кругу третьих лиц или определенному третьему лицу для достижения
                                вышеуказанных целей, доступ, а также трансграничная передача), обезличивание,
                                блокирование, удаление, уничтожение. Согласие на обработку персональных данных может
                                быть отозвано в любое время на основании письменного заявления Участника, составленного
                                в соответствии с требованиями действующего законодательства Республики Беларусь и
                                направленного по месту нахождения Организатора. В случае отзыва согласия на обработку
                                персональных данных Участник подлежит исключению из числа участников Программы,
                                неиспользованные бонусы оплате\обмену не подлежат.
                            </p>
                            <h2 className="page__title">6. РАЗРЕШЕНИЕ СПОРОВ ПО ПРОГРАММЕ.</h2>
                            <p>
                                6.1. Во всем ином, что не урегулировано положениями настоящих Правил, Организатор и
                                Участники должны руководствоваться положениями действующего законодательства
                                Республики Беларусь.
                            </p>
                            <p>
                                6.2. В случае если спор между Организатором и Участником не может быть разрешен в
                                соответствии с настоящими Правилами, он разрешается в соответствии с действующим
                                законодательством Республики Беларусь.
                            </p>
                        </div>
                    </div>
                    <AnyQuestions />
                </main>
            </>
        )
    }
}
