import {action, makeAutoObservable} from "mobx"
import {RootStore} from "../index";
import ElementModel from "../../model/ElementModel";
import BlockPropertyModel from "../../model/BlockPropertyModel";

export class PageProductStore {
    private rootStore: RootStore | undefined;
    BreadCrumbs = []
    activeTab = 'tabs-3';
    arRecommend = [];
    arPrevSawProduct = [];
    arRecommend2: any = [];
    isActiveDec = false;
    arFilterCatalogEmun = [];
    arElementNew = {};
    arElement = {
        id: '',
        name: '',
        code: '',
        img: {
            big: '',
            small: '',
            tone: '' //Серия
        },
        price: {},
        props: {
            brand: '',
            itemType: '',
            seriesLineGamma: '', //Серия
            subLine:'',
            subgamma:'',
            hairType: '',
            pallet: ''

        },
        propsNoFilter: {
            barcode: '',                //штрих-код
            cosmeticEffectName: '',     //Эффект
            hairType: '',               //Тип волос
            huePictureName: '',         //Оттенок в виде картинки (Color swatches)
            hueName: '',                //Оттенок в виде наименование
            shortDescription: '',       //Краткое описание
            productTextureName: '',     //Текстура продукта
            brandDescription: '',       //Описание бренда
            isNovelty: '',              //Новинка
            productDescription: '',     //Описание продукта
            subLine: '',           //Подлиния
            forWhomName: '',            //Для кого
            fixingDegree: '',       // Степень фиксации
            pallet: '',             //Палитра
            dyesType: '',               // Тип Красителя
            producerCountryName: '',    //Страна производитель
            subGamma: '',           //Подгамма
            isTopBeautyHouse: '',           //Подгамма
        },
    };

    public error: string = '';

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    init(code: string) {
        ElementModel.getElement(code, this.rootStore?.mainStore.getClientId(), this.setElement, this.setError);
        BlockPropertyModel.getFilter({
            json: {
                clientId: this.rootStore?.mainStore.getClientId(),
            }
        }, this.setCatalogFilter, this.setErrorFilter);

        if (localStorage.getItem("prevViewProduct")){
            let a = localStorage.getItem("prevViewProduct")
            if (a !== null) {
                this.arPrevSawProduct = JSON.parse(a)
            }
        }
    }

    @action
    setRecomend = (result: any) => {
        this.arRecommend = result.data.rows;
        // @ts-ignore
        this.arFilterCatalogEmun = this.rootStore?.appStore.arFilterCatalogEmun;
    }

    @action
    setRecomendCard = (result: any) => {
        this.arRecommend = result.data.rows;
        // @ts-ignore
        this.arFilterCatalogEmun = this.rootStore?.appStore.arFilterCatalogEmun;
    }

    @action
    setRecomend2 = (result: any) => {
        this.arRecommend2 = [];
        for (let i = 0 ; i < result.data.rows.length; i++){
            if (result.data.rows[i].propsNoFilter.hueNumber){
                this.arRecommend2.push(result.data.rows[i])
            }
        }

        this.arRecommend2.sort(function(a: any, b:any){
            let nameA=a.propsNoFilter.hueNumber.toLowerCase(), nameB=b.propsNoFilter.hueNumber.toLowerCase()
            if (nameA < nameB) //сортируем строки по возрастанию
                return -1
            if (nameA > nameB)
                return 1
            return 0 // Никакой сортировки
        })

    }
    @action
    changeArElement = (item: any) => {
        ElementModel.getElement(item, this.rootStore?.mainStore.getClientId(), this.setElement, this.setError);
    }

    @action
    setElement = (result: any) => {
        let userId = this.rootStore?.mainStore.getUserId();
        let clientId = this.rootStore?.mainStore.getClientId();
        this.arElement = result.data.row;
        this.BreadCrumbs = result.data.BreadCrumbs;
        this.addArrPrevSawProduct(this.arElement)
        ElementModel.geRelatedProducts({
                clientId: clientId,
                productId: this.arElement.id
        }, this.setRecomendCard, this.setErrorRecomend);

        ElementModel.getCatalog({
            json: {
                clientId: this.rootStore?.mainStore.getClientId(),
                limit: 40,
                filter:{
                    // @ts-ignore
                    id:'!'+this.arElement.id,
                    "props":{"seriesLineGamma": [this.arElement.props?.seriesLineGamma],
                        "subLine": [this.arElement.props?.subLine],
                        "itemType": [this.arElement.props?.itemType],
                        "brand": [this.arElement.props?.brand],
                        "pallet": [this.arElement.props?.pallet]
                    }
                }
            }, page: 1
        }, this.setRecomend2, this.setErrorRecomend);
    }

    getArrayForRecommendForBasket = ()=> {
        let clientId = this.rootStore?.mainStore.getClientId();
        ElementModel.getRecommendedCart({
                clientId: clientId,
        }, this.setRecomendCard, this.setErrorRecomend);
    }

    @action
    setErrorFilter = (error: any) => {
        console.log(error);
    }

    @action
    setCatalogFilter = (result: any) => {
        let listFilters: any;
        listFilters = result.data;
        for(let key in listFilters){
            if(listFilters[key]['code'] === 'beautyHouseCategory') {
                // @ts-ignore
                this.arFilterCatalogEmun = listFilters[key]['enum'];
            }
        }
    }

    @action
    setError = (error: any) => {
        console.log(error)
    }

    @action
    setErrorRecomend = (error: any) => {
        console.log(error)
    }

    @action
    setTabActive(tab: string){
        this.activeTab = tab;
    }

    @action
    setActiveDic(e: boolean){
        if(e){
            this.isActiveDec = false;
        }else{
            this.isActiveDec = true;
        }
    }

    @action
    addArrPrevSawProduct=(item: any)=>{
            let mainPrevObg = []
        if (localStorage.getItem("prevViewProduct")){
            let a = localStorage.getItem("prevViewProduct")
            if (a !== null) {
                let array = JSON.parse(a)
                array = array.filter((val: any) => val.id !== item.id);
                mainPrevObg = array
                mainPrevObg.push(item)
                localStorage.setItem("prevViewProduct", JSON.stringify(mainPrevObg))
            }
        }
        else {
            mainPrevObg.push(item)
            localStorage.setItem("prevViewProduct", JSON.stringify(mainPrevObg))
        }
    }

}

