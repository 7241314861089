import {RootStore} from '../index';
import {action, makeAutoObservable} from "mobx"
import Request1cModel from "../../model/Request1cModel";
import DeliveryModel from "../../model/DeliveryModel";
import {APP_SITE_ID} from "../../const/root";


// noinspection DuplicatedCode
export class PageDeliveryAddressStore {

    private rootStore: RootStore | undefined;

    Points: any= [];
    checkedPointId: number = 0

    anyItemPoints: number = 0

    selectedOption = {
        Client: null,
        DeliveryType: null,
        PaymentType: null,
        Dates: null,
        Points: null,
    }

    options = {
        Client: [],
        DeliveryType: [],
        PaymentType: [],
        Dates: [],
        Points: [],
    }
   public city: any = ''

    public inputs = {
        cityId: {
            code: 'cityId',
            type: "text",
            title: 'Город',
            tooltip: 'Город',
            option: [],
            error: '',
            value: '',
            server: true,

        },
        fullNameOrg: {
            code: 'fullNameOrg',
            type: "text",
            title: 'Название торговой точки',
            tooltip: 'Название торговой точки',
            error: '',
            value: '',
            server: true,
        },
        address: {
            code: 'address',
            type: "text",
            title: 'Адрес торговой точки',
            tooltip: 'Адрес торговой точки',
            error: '',
            value: '',
            server: true,
        },
        phone: {
            code: 'phone',
            type: "text",
            title: 'Телефон',
            tooltip: 'Телефон',
            error: '',
            value: '',
            server: true,
            isMask: true,
            mask: '+375 (99) 999-99-99'
        } ,
        contactPerson: {
            code: 'contactPerson',
            type: "text",
            title: 'ФИО контактного лица',
            tooltip: 'ФИО контактного лица',
            error: '',
            value: '',
            server: true,
        },
        houseNumber:{
            code: 'houseNumber',
            type: "text2",
            title: 'Дома',
            tooltip: 'Номер дома',
            error: '',
            value: '',
            server: true,
        },
        buildingNumber:{
            code: 'buildingNumber',
            type: "text2",
            title: 'Корпус',
            tooltip: 'Номер корпуса',
            error: '',
            value: '',
            server: true,
        },

        apartmentNumber:{
            code: 'apartmentNumber',
            type: "text2",
            title: 'Квартира',
            tooltip: 'Номер квартиры',
            error: '',
            value: '',
            server: true,
        },
        str:{
            code: 'str',
            type: "text2",
            title: 'Строение',
            tooltip: 'Строение',
            error: '',
            value: '',
            server: true,
        },
    };

    public error: string = '';
    public redirect: boolean = false;

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    init() {
        if(this.rootStore?.mainStore.isAuth) {

            DeliveryModel.getPoints(
                this.rootStore?.mainStore.getClientId(),
                this.getPoints, this.setDeliveryError);

            this.redirect = false;
            this.inputs.contactPerson.value = this.rootStore.mainStore.lastName + " " + this.rootStore.mainStore.firstName + " " + this.rootStore.mainStore.secondName ;
            this.inputs.phone.value = this.rootStore.mainStore.phone

        }

    }

    setData(result: any, keyData: string, keyName: string) {
        // @ts-ignore
        this.options[keyData] = [];
        if (result.data?.length) {
            result.data.map((item: any) => {
                // @ts-ignore
                this.options[keyData].push({
                    // @ts-ignore
                    value: item?.id,
                    // @ts-ignore
                    label: item[keyName],
                });
                return item;
            });
            // @ts-ignore
            this.selectedOption[keyData] = {
                // @ts-ignore
                value: result.data[0]?.id,
                // @ts-ignore
                label: result.data[0][keyName],
            }
        }
    }

    @action
    getPoints = (result: any) => {

        this.Points = result.data;

       for (let i=0; i< result.data.length; i++){
           if (result.data[i].isDefault === true){
               this.anyItemPoints = result.data[i].id
           }else {
               console.log()
           }
       }

    }

    @action
    changePoints = (item: any) => {
        console.log(item.isDefault)
        console.log(item.id)
        this.checkedPointId = item.id
        for (let i=0; i< this.Points.length; i++){
            if (this.Points[i].id === item.id && this.Points[i].isDefault === item.isDefault && item.isDefault === true ){
console.log()
            }else if (this.Points[i].id === item.id && this.Points[i].isDefault === item.isDefault && item.isDefault === false){

                let id = item.id
                let dt = {

                    isDefault: true
                }
                DeliveryModel.update(id,
                    dt,
                    this.init(),
                    this.setError
                );

                if (this.anyItemPoints > 0){

                    let id = this.anyItemPoints
                    let dt = {

                        isDefault: false
                    }
                    DeliveryModel.update(id,
                        dt,
                        this.init(),
                        this.setError
                    );

                }

            }
        }

    }


    @action
    checkCity(isMessage = false) {
        let result = '';
        let val = this.inputs.cityId.value;
        // @ts-ignore
        let data = this.inputs.cityId.option.filter(item => item.name === val);
        if (!data.length) {
            if(isMessage){
                this.inputs.cityId.error = 'Выберите город из списка!';
            }
            result = '';
        } else {
            // @ts-ignore
            result = data[0].id;
        }

        return result;
    }


    @action
    onSubmitForm(e: { preventDefault: () => void; }) {
        e.preventDefault();
        let data = [];
        for (let code in this.inputs) {
            // @ts-ignore
            let item = this.inputs[code];
            // @ts-ignore
            if (item.server) {
                data.push(item);
            }
        }
        let dt: any = {};
        data.map((item: any) => {
            dt[item.code] = item.value;
            return item;
        });

        let id = this.checkCity(true);
        if (id) {
            dt['cityId'] = id;
            dt['isProcessed'] = false;
            dt['type'] = 'tradepointReg';
            dt['siteId'] = APP_SITE_ID;

            Request1cModel.createTradePoint(
                dt,
                this.setSubmit,
                this.setError
            );
        }
        return false;
    }

    @action
    setSubmit = (result: any) => {
        this.redirect = true;
    }

    @action
    onChangeInput = (e: { target: { value: string; }; }, item: any) => {
        item.value = e.target.value;
    }

    @action
    onChangeInputCity = (e: { target: { value: string; }; }, item: any) => {
        item.value = e.target.value;
        if (item.value.length > 2) {
            if (!this.checkCity()) {
                this.getCity(item.value);
            }else{
                this.inputs.cityId.error='';
            }
        } else {
            item.option = [];
        }
    }
    @action
    onChangeCity= (item: any) => {
    this.inputs.cityId.value = item
    }
    @action getCity(q: string) {
        // GeoModel.getCity(q, this.setOptionCity, this.setError)
        Request1cModel.getCity(q, this.setOptionCity, this.setError)
    }


    @action setOptionCity = (result: any) => {

        this.inputs.cityId.option = result.data
        // @ts-ignore
        // this.inputs.cityId.option = result.data.Components
    }

    @action
    setError = (error: any) => {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            error.response.data.message !== undefined
        ) {
            this.error = String(error.response.data.message);
        } else if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            Array.isArray(error.response.data)
        ) {
            let el = {
                error: ''
            };
            error.response.data.map((item: any) => {
                // @ts-ignore
                el = this.inputs[item.code];
                if (el !== undefined) {
                    el.error = item.error;
                }
                return item;
            });
        } else if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            !Array.isArray(error.response.data)
        ) {
            return String(error.response.data);
        }
    }

    @action
    setDeliveryError = (error: any) => {
        console.log(error)
    }


}
