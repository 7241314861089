import {action, makeAutoObservable} from "mobx"
import {RootStore} from "../index";
import UserModel from "../../model/UserModel";
import ClientModel from "../../model/ClientModel";
import SiteModel from "../../model/SiteModel";


export class PagePersonalStore {
    private rootStore: RootStore | undefined;

    public arUsers = [];
    public arChecked: number[] = [];
    public isCheckedAll = false;
    public redirect: boolean = false;
    public error: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public secondName: string = '';
    public email: string | undefined = '';
    public phone: string = '';
    public user: {} | undefined= '';
    public clientId: any;
    public id: any;
    public nameForBitrix: any = '';
    public phoneForBitrix: any = '';
    public textForBitrix: any = '';
    public textBefore: any = 'Новое сообщение с сайта https://beautyhouse.by/ от ';
    public isModalOpen: boolean = false;
    public loyaltyProgramLevelId: number = 0;
    public loyaltyLevel: string = "";



    public arContracts = [];


    userInfo: any;
    clientIds: any;
    beautyID: any;
    public isEmailListSubscribed: boolean = false


    public inputs = {

        phone: {
            code: 'phone',
            value: '',
            valueId: 0,
            server: true,
        },

        FIO: {
            code: 'FIO',
            type: "text",
            title: 'ФИО',
            tooltip: 'ФИО',
            error: '',
            value: '',
            valueId: 0,
            required: true,
            server: true,
        },


    }

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;

    }

    init = () => {

        this.firstName = this.rootStore?.mainStore.firstName;
        this.lastName = this.rootStore?.mainStore.lastName;
        this.secondName = this.rootStore?.mainStore.secondName;
        this.email = this.rootStore?.authStore.email;
        this.clientId = this.rootStore?.mainStore.clientId;
        this.phone = this.rootStore?.mainStore.phone;
        this.id = this.rootStore?.mainStore.id;

        this.arUsers = [];
        this.arChecked = [];
        this.arChecked = [];
        this.isCheckedAll = false;

        UserModel.getInfoUser(this.id, this.getClientIds, this.setError);
        ClientModel.getInfo(this.clientId, this.getClientInfo, this.setError);
        // SiteModel.getMessageEmail(this.sideId, this.setError , this.getMessageEmail)

        this.reload();
        // @ts-ignore
        //this.rootStore?.pageRegEmployeeStore.falseRedirect();
        // @ts-ignore
        //this.rootStore?.pageRegTradePointStore.falseRedirect();

    }
    getClientIds = (result: any) => {

        if (result) {
            this.clientIds = result.data.clientIds[0];
        }
    }
    getInfoLoyaltyID = () => {
        ClientModel.getInfoLoyaltyId(this.rootStore?.mainStore.clientId, this.getLoyaltyIdLevel, this.setError);
    }

    getClientInfo = (result: any) => {
        if (result) {
            const regExp = /\*|_|\$/g;
            this.beautyID = result.data.beautyID.replace(regExp, '');
            this.isEmailListSubscribed = result.data.isEmailListSubscribed;
            if (result.data.contracts !== null){
                this.arContracts = result.data.contracts;
            }
        }
    }
    getLoyaltyIdLevel = (result: any) => {
        this.loyaltyProgramLevelId = result.data.loyaltyProgramLevelId
        if (this.loyaltyProgramLevelId){
            this.getLoyaltyLevel()
        }
    }

    @action
    getLoyaltyLevel() {
       ClientModel.getInfoLoyaltylevel(this.loyaltyProgramLevelId, this.setInfoUserLoyaltyLevel, this.setError)
    }
    @action
    setInfoUserLoyaltyLevel = (result: any) => {
        this.loyaltyLevel = result.data.loyaltyProgramLevelName
    }

    @action
    reload() {
        UserModel.getAllChildren(this.setData, this.setError);
    }

    @action
    onActionBlocked = () => {
        if (this.arChecked.length) {
            UserModel.actionChildrenBlocked(this.arChecked, this.init, this.setError);
        } else {
            alert('Выберите записи');
        }
    }

    @action
    onActionActivate = () => {
        if (this.arChecked.length) {
            UserModel.actionChildrenActivate(this.arChecked, this.init, this.setError);
        } else {
            alert('Выберите записи');
        }
    }


    @action
    onActionDeleted = () => {
        if (this.arChecked.length) {
            UserModel.actionChildrenDelete(this.arChecked, this.init, this.setError);
        } else {
            alert('Выберите записи');
        }
    }

    @action
    onCheckboxClick = (id: number) => {
        if (this.arChecked.includes(id)) {
            this.isCheckedAll = false;
            this.arChecked = this.arChecked.filter(f => f !== id);
        } else {
            this.arChecked.push(id);
        }
    }


    @action
    onCheckboxAll = () => {
        this.isCheckedAll = !this.isCheckedAll;
        this.arChecked = [];
        if (this.isCheckedAll) {
            this.arUsers.map((user: any) => {
                this.arChecked.push(user.id);
                return user;
            });
        }
    }


    @action
    setData = (result: any) => {
        result.data.row.map((item: any) => {
            item.updatedAt = new Date(item.updatedAt);
            return item;
        });
        this.arUsers = result.data.row;
    }


    @action onNewPassword=() =>{
        UserModel.getLinkNewPassword(this.redirectPassword, this.setError);
    }

    @action
    redirectPassword=(result:any)=>{
        if (result && result.data !== undefined && result.data.url!==undefined) {
            this.rootStore?.mainStore.setRedirectUrl(String(result.data.url));
            // @ts-ignore
            window.location=result.data.url;
        }
    }
    @action setError = (error: any) => {
        console.log(error);
        // this.error = error.response.data.massage;
    }
    @action setSubmit = (result: any) => {
        this.redirect = true;
    }

    @action setOk = () => {
        alert('Ваше сообщение отправлено')
    }

    @action
    updateUserData = (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        if (!this.inputs.phone.value){
            this.inputs.phone.value = this.rootStore?.mainStore.phone;
        }
        this.inputs.FIO.value = [this.lastName,this.firstName, this.secondName,  ].join(' ');

        let data = [];
        for (let code in this.inputs) {
            // @ts-ignore
            let item = this.inputs[code];
            if (item.server) {
                data.push(item);
            }
        }
        let dt: any = {};
        data.map((item: any) => {
            dt[item.code] = item.value;

            return item;
        });
        UserModel.update(this.id,
            dt,
            this.setSubmit,
            this.setError
        );

        this.updateClientData();

        this.rootStore?.mainStore.init()
        this.init()
        UserModel.getInfo(this.clientId, this.setInfoUser, this.setError)

        alert('Спасибо, Ваши данные изменены !')
        this.rootStore?.mainStore.init()
        this.rootStore?.appStore.init()
        this.init()
        UserModel.getInfo(this.clientId, this.setInfoUser, this.setError)
    }

    updateClientData = () => {

        let dt: any = {
            'beautyID': this.beautyID,
            'isEmailListSubscribed': this.isEmailListSubscribed
        };

        ClientModel.updateClientData(this.clientId,
            dt,
            this.setSubmit,
            this.setError
        );

    }


    setInfoUser = (result: any) => {
        if (result) {

            this.userInfo = result?.data[0];


            this.phone = this.userInfo?.userphone;
            const arName = (this.userInfo?.username).split(" ");
            this.firstName = arName[1];
            this.lastName = arName[0];
            this.secondName = arName[2];

            localStorage.setItem('setInfoUser', JSON.stringify(result));
        }

    }

    @action
    onChangeisEmailListSubscribed = (e: { target: { checked: boolean }; }) => {
        this.isEmailListSubscribed = !this.isEmailListSubscribed;
    }

    @action
    onChangeBeautyID = (e: { target: { value: string; }; }) => {
        this.beautyID = e.target.value;

    }

    @action
    onChangeFirstName = (e: { target: { value: string; }; }) => {
        this.firstName = e.target.value;
    }
    @action
    onChangePhoneForBitrix = (e: { target: { value: string; }; }) => {
        this.phoneForBitrix = e.target.value;
    }

    @action
    onChangeNameForBitrix = (e: { target: { value: string; }; }) => {
        this.nameForBitrix = e.target.value;
    }

    @action
    onChangeTestForBitrix = (e: { target: { value: string; }; }) => {
        this.textForBitrix = e.target.value;
    }

    @action
    sendMessage = () => {
        if (this.nameForBitrix === '' || this.phoneForBitrix === '' || this.textForBitrix === '' ){
            this.isModalOpen = true
        }else {
            let dt: any = {
                'name': this.textBefore + this.nameForBitrix,
                'phone': this.phoneForBitrix,
                'message': this.textForBitrix,
                'siteId': 4,
            };
            SiteModel.postMessageEmail(
                dt,
                this.setOk,
                this.setError
            );
            this.nameForBitrix = ''
            this.phoneForBitrix = ''
            this.textForBitrix = ''
        }
    }

    onCloseModal = () => {
        this.isModalOpen = false
    }

    @action
    onChangeLastName= (e: { target: { value: string; }; }) => {
        this.lastName = e.target.value;
    }

    @action
    onChangeSecondName= (e: { target: { value: string; }; }) => {
        this.secondName = e.target.value;
    }

    @action
    onChangePhoneNumber = (e: { target: { value: string; }; }) => {
        this.phone = e.target.value;
        this.inputs.phone.value = this.phone
    }
}
