import React from 'react';
import {Link} from "react-router-dom";
import {withStore} from "../../hocs";
import {observer} from "mobx-react";

// @ts-ignore
@withStore
@observer
export class PersonalMenu extends React.Component {
    render() {
        // @ts-ignore
        const store = this.props.store;
        const mainStore = store.mainStore;
        let menu = store.appStore.menuPersonal;
        let url = store.mainStore.url;
        if(url === ''){
            url = document.location.pathname;
        }
        //console.log(url);

        return (
            <>
                <ul className="user-account__menu">
                    {menu.map((item: any, index: any) =>
                        <p key={index}>
                            {item.type === 'personal' ?
                                <li className="user-account__menu-item" >
                                    {item.url === url?
                                        <Link className="user-account__menu-link is-active" to={item.url} replace  onClick={()=>mainStore.setUrl(item.url)}>{item.name}</Link>
                                        :
                                        <Link className="user-account__menu-link" to={item.url} onClick={()=>mainStore.setUrl(item.url)}>{item.name}</Link>
                                    }
                                </li>
                                :
                                null
                            }
                        </p>
                    )}
                </ul>
            </>
        )
    }
}
