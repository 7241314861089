import React from 'react';
import {withStore} from "../hocs";
import {observer} from "mobx-react";
import {MainAktsii} from "../components/main/MainAktsii";
import {MainOurAdvantages} from "../components/main/MainOurAdvantages";
import {MainWorkBrands} from "../components/main/MainWorkBrands";
import {MainSlider} from "../components/main/MainSlider";
import {MainWeInstagram} from "../components/main/MainWeInstagram";

import PopupConfirmConsent from "../components/modals/PopupConfirmConsent";
import {YOUR_TRACKING_ID} from "../stores/AppStore";
import ReactGA from "react-ga"
import {GoodsItem} from "../components/catalog/GoodsItem";
// @ts-ignore
import Slider from "react-slick";
import {BannerLoyalty} from "../components/modals/BannerLoyalty";

// @ts-ignore
@withStore
@observer
export class MainPage extends React.Component {
    setGA = () => {
        ReactGA.initialize(YOUR_TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
    };
    componentDidMount(){
        this.setGA();  
        // @ts-ignore
        const {store} = this.props;
        store.pageMainStore.init();
    }

    render() {
        // @ts-ignore
        const {store} = this.props;
        const {arItemsSpecials} = store?.pageMainStore;
        const {isAuth, isPopupShowBonus} = store.mainStore;

        const {
            onPageMainClickSlider,
        } = store?.componentFilterCatalogStore;

        let Width = window.innerWidth
        let slidesToShow = 4

        if (Width < 1023.99 && Width > 767.99){
            slidesToShow = 3
        }else if (Width < 767.99 ){
            slidesToShow = 2
        }
        let settings = {
            dots: true,
            speed: 500,
            slidesToShow: slidesToShow,
            slidesToScroll: slidesToShow,
            infinite: true,
        };
        let arPrevSawProduct = []
            if (localStorage.getItem("prevViewProduct")){
            let a = localStorage.getItem("prevViewProduct")
            if (a !== null) {
                arPrevSawProduct = JSON.parse(a)
            }
        }

        return (
            <>
                <main className="page__main">
                    <div className="container">
                        {/* Слайдер */}
                        <MainSlider  onPageMainClickSlider={onPageMainClickSlider}/>
                    </div>
                    {isAuth ?
                        <>
                        <div className="container">
                            {arItemsSpecials && arItemsSpecials.length > 4 ?
                                <div style={{width: "100%"}}>
                                    <div className="single-product__recommended" style={{marginTop: 20, paddingTop: 20}}>
                                        <h2 className="section__title">Бестселлеры</h2>
                                        <Slider {...settings}>
                                            {arItemsSpecials.map((item: any, index: number) => (
                                                <div className="goods-catalog"  >
                                                    <div className="goods-catalog__list goods-catalog__list--grid">
                                                        <GoodsItem key={index} item={item} width={arItemsSpecials.length < 4 ? "1" : "450px"} type={"Бестселлеры"} />
                                                    </div>
                                                </div>
                                            ))}

                                        </Slider>
                                    </div>
                                </div> : null}

                            {arItemsSpecials && arItemsSpecials.length > 0 && arItemsSpecials.length <= 4  ?
                                <div style={{width: "100%"}}>
                                    <div className="single-product__recommended" style={{marginTop: 20, paddingTop: 20}}>
                                        <h2 className="section__title">Бестселлеры</h2>
                                        <div style={{display: "flex", alignItems: "center", margin: "auto", justifyContent: "space-around"}}>
                                            {arItemsSpecials.map((item: any, index: number) => (
                                                        <GoodsItem key={index} item={item} width={arItemsSpecials.length < 4 ? "1" : "450px"} type={"Бестселлеры"} />
                                            ))}
                                        </div>
                                    </div>
                                </div>: null }
                        </div>
                            <div className="container">
                            {arPrevSawProduct && arPrevSawProduct.length > 3   ?
                                <div style={{width: "100%"}}>
                                    <div className="single-product__recommended">
                                        <p className="single-product__sub-title">Недавно просмотренные товары:</p>
                                        <Slider {...settings}>
                                            {arPrevSawProduct.map((item: any, index: number) => (
                                                <div className="goods-catalog"  >
                                                    <div className="goods-catalog__list goods-catalog__list--grid">
                                                        <GoodsItem key={index} item={item} width={arPrevSawProduct.length < 4 ? "1" : "450px"} type={"Недавно просмотренные товары"} />
                                                    </div>
                                                </div>
                                            ))}

                                        </Slider>
                                    </div>
                                </div> : null}
                        </div>

                            {/* Акции */}
                            <div>
                                <MainAktsii />
                            </div>

                        </>
                        :
                        null
                    }
                    {/*  Наши преимущества */}
                    <MainOurAdvantages />
                    {/*  Работаем с брендами */}
                    <MainWorkBrands />
                    {/*  Мы в instagram */}
                    <MainWeInstagram/>

                    <PopupConfirmConsent />
                    <BannerLoyalty store={store} isPopupShowBonus={isPopupShowBonus}/>
                </main>
            </>
        )
    } 
}
