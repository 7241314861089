import {RootStore} from '../index';
import {action, makeAutoObservable} from "mobx";
import axios from "axios";



export class NewsStore {


    rowsNews = [];
    rowsDetail = []


    private rootStore: RootStore | undefined;

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;

    }

    init(val: boolean, code: string) {
        if (val){

            axios.get(`https://nest.sellwin.by/content/news`)
                .then(res => {
                    this.rowsNews = res.data.data
                    this.filterNewsForDetailPage(code)
                })
        }else {
            axios.get(`https://nest.sellwin.by/content/news`)
                .then(res => {
                    this.rowsNews = res.data.data
                })
        }


    }

    @action
    filterNewsForDetailPage = (code: any)=> {
        this.rowsDetail = []
        if (this.rowsNews.length){
            for (let i =0 ; i < this.rowsNews.length; i++){
                // @ts-ignore
                if (this.rowsNews[i].name === code){
                    return this.rowsDetail = this.rowsNews[i]
                }
            }
        }

    }
}
