import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";



const SceletonForm = () => {


        return (
            <Grid container wrap="nowrap">

                <Box sx={{ width: 210, marginRight: 5, my: 5 }}>
                    <Box sx={{ pb: 1}}>
                        <Skeleton height={30}/>
                    </Box>
                    <Skeleton width={210} height={20} />
                    <Skeleton width={210} height={20} />
                    <Skeleton width={210} height={20} />
                    <Skeleton width={210} height={20} />
                    <Skeleton width={210} height={20} />
                    <Skeleton width={210} height={20} />
                    <Skeleton width={210} height={20} />
                </Box>
                <Box sx={{ width: 210, marginRight: 5, my: 5  }}>
                    <Box sx={{ pb: 1}}>
                        <Skeleton height={30}/>
                    </Box>
                    <Skeleton width={210} height={20} />
                    <Skeleton width={210} height={20} />
                    <Skeleton width={210} height={20} />
                    <Skeleton width={210} height={20} />
                    <Skeleton width={210} height={20} />
                    <Skeleton width={210} height={20} />
                    <Skeleton width={210} height={20} />
                </Box>
                <Box sx={{ width: 210, marginRight: 0.5, my: 5 }}>
                    <Box sx={{ pb: 1}}>
                        <Skeleton height={30}/>
                    </Box>
                    <Skeleton width={210} height={20} />
                    <Skeleton width={210} height={20} />
                    <Skeleton width={210} height={20} />
                    <Skeleton width={210} height={20} />
                    <Skeleton width={210} height={20} />
                    <Skeleton width={210} height={20} />
                    <Skeleton width={210} height={20} />
                </Box>
            </Grid>
        );
    }


export default SceletonForm;
