import {RootStore} from '../index';
import { makeAutoObservable} from "mobx"




export class ComUrlStore {
    private rootStore: RootStore | undefined;


    public mainUrl = {
        beautyHouseCategory: {
            codeId: 0,
            text: 'beautyHouseCategory',
            value: false,
        },
        brand: {
            codeId: 0,
            value: false,
            text: 'brand',
        },
        seriesLineGamma: {
            codeId: 0,
            value: false,
            text: 'seriesLineGamma',
        },
        subLine: {
            codeId: 0,
            value: false,
            text: 'subLine',
        },
        pallet: {
            codeId: 0,
            value: false,
            text: 'pallet',
        },
        itemType: {
            codeId: 0,
            value: false,
            text: 'itemType',
        },
        dyesType: {
            codeId: 0,
            value: false,
            text: 'dyesType',
        },
        hairType: {
            codeId: 0,
            value: false,
            text: 'hairType',
        },
        fixingDegree:{
            codeId: 0,
            value: false,
            text: 'fixingDegree',
        },
        coloring:{
            codeId: 0,
            value: false,
            text: 'coloring',
        },
        additionalService:{
            codeId: 0,
            value: false,
            text: 'additionalService',
        },
        technicalFormat:{
            codeId: 0,
            value: false,
            text: 'technicalFormat',
        },
        isSellout:{
            codeId: 0,
            value: false,
            text: 'isSellout',
        },
        isNovelty:{
            codeId: 0,
            value: false,
            text: 'isNovelty',
        },
        isIncludeLoyaltyProgram:{
            codeId: 0,
            value: false,
            text: 'isIncludeLoyaltyProgram',
        },
        isTopBeautyHouse:{
            codeId: 0,
            value: false,
            text: 'isTopBeautyHouse',
        },
    };


    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }



}
