import React from 'react';


interface Props {
    isPopupThankYouApplication: boolean;
}

export class ThankYouApplication extends React.Component<Props, {}> {
    render() {

        let isPopupThankYouApplication = this.props.isPopupThankYouApplication;

        return (
            <>
                <div className={isPopupThankYouApplication ? "modal modal--success is-open" : "modal modal--success"}  data-modal="ThankYouApplication">
                    <a href="/">
                        <button className="modal__close js--modal-close" type="button">
                            <span className="visually-hidden">Закрыть модальное окно</span>
                            <span className="modal__close-icon" />
                        </button>
                    </a>
                    <div className="modal__body modal__body--clear">
                        <svg className="modal__icon" width="31" height="27">
                            <use xlinkHref="/images/sprite.svg#icon--comment"/>
                        </svg>
                        <h2 className="modal__title modal__title--order-success accent-text">Спасибо за заявку!</h2>
                        <p className="modal__body-text">Вам отправлено письмо со ссылкой для подтверждения e-mail. Перейдите по ссылке,
                            чтобы завершить отправку заявки. После этого специалист свяжется с вами по телефону в течение 48 часов.</p>
                        <a className="button button--accent-dark modal__button js--modal-close" href="/">Закрыть окно</a>
                    </div>
                </div>
            </>
        )
    }
}
