import React from 'react';
import {Link} from "react-router-dom";

interface Props {
    store:any
    menu: [];
    type: string;
    title: string;
    url:string;
}

export class RenderFooterMenu extends React.Component<Props, {}> {
    render() {
        const store = this.props.store;
        let menu = this.props.menu;
        let type = this.props.type;
        let title = this.props.title;
        let url = this.props.url;
        return (
            <>
                <div className="page-footer__item" key={'footer-menu-'+type}>
                    <p className="page-footer__title">{title}</p>
                    <ul className="footer-menu">

                        {menu.map((item: any, index: any) =>
                            <div key={index}>
                                {item.type === type ?

                                    <li className="footer-menu__item" key={index}>
                                        {item.url === url?
                                            <Link className="footer-menu__link is-current" to={item.url} replace  onClick={()=>store.setUrl(item.url)}>{item.name}</Link>
                                            :
                                            <Link className="footer-menu__link" to={item.url} onClick={()=>store.setUrl(item.url)}>{item.name}</Link>
                                        }
                                    </li>
                                    :
                                    null
                                }
                            </div>
                        )}

                    </ul>
                </div>
            </>
        )
    }
}
