import {RootStore} from '../index';
import { makeAutoObservable} from "mobx";
import axios from "axios";



export class MainSliderStore {

    rowsMainSliderImage = [];

    private rootStore: RootStore | undefined;

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

  init() {
      axios.get(`https://nest.sellwin.by/content/banners`)
          .then(res => {
              this.rowsMainSliderImage = res.data.data
          })
    }

}
