import {Model} from "./";
import {Api} from "../api";

class Request1cModel extends Model {

    constructor() {
        super(new Api("/request-1c"));
    }

    createRegAll(data, thenFunction, errorFunction) {
        this.q.setDopUrl('/user');
        this.q.create(data, thenFunction, errorFunction);
    }
    createTradePoint(data, thenFunction, errorFunction) {
        this.q.setDopUrl('/trade-point');
        this.q.create(data, thenFunction, errorFunction);
    }

    getCity(q, thenFunction, errorFunction) {
        this.q.setDopUrl('/city');
        this.q.read({q: q}, thenFunction, errorFunction);
    }

    getCheckEmail(code, thenFunction, errorFunction) {
        this.q.setDopUrl('/email/' + code);
        this.q.read({}, thenFunction, errorFunction);
    }

}

export default new Request1cModel();

