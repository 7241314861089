import {RootStore} from '../index';
import {action, makeAutoObservable} from "mobx"


export class PagePaymentStore {
    private rootStore: RootStore | undefined;

    modalWindow1 = false;
    modalWindow2 = false;

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    @action
    showModal1 = (item: boolean) => {
        if (item){
            this.modalWindow1 = true
        }else {
            this.modalWindow1 = false
        }
    }

    @action
    showModal2 = (item: boolean) => {
        if (item){
            this.modalWindow2 = true
        }else {
            this.modalWindow2 = false
        }
    }

}
