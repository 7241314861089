import {action, makeAutoObservable} from "mobx"
import {RootStore} from "../index";
import NewsModel from "../../model/NewsModel";

export class PageMassageStore {

    error = '';
    countShowTop = 0;
    showNotView = false;
    pagination = {
        countPage: 1,
        countRow: 1,
        currentPage: 1
    }
    orderModel: string = '';
    orderField: string = 'id';
    orderBy: string = 'DESC';
    arItems = [];

    private rootStore: RootStore | undefined;

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    @action init() {
        this.reload(true);
    }

    @action reload = (init: boolean = false) => {
        if (init) {
            this.pagination.currentPage = 1;
        }
        let userId = this.rootStore?.mainStore.id;
        NewsModel.getRows(this.rootStore?.mainStore.getClientId(), (result: any) => {
            result.data.rows.map((item: any) => {
                item['isOpen'] = false;
                item['isRead'] = item.props.UserViews.includes(userId);
                return item;
            });
            this.arItems = result.data.rows;
            this.pagination = result.data.pagination;
            this.setCountShowTop(this.arItems)
        }, this.setError);
    }

    setFilter(showNotView = false) {
        let filter = {};
        // @ts-ignore
        filter['props'] = {
            ['Clients-contains']: this.rootStore?.mainStore.getClientId(),
        };
        if (this.showNotView || showNotView) {
            // @ts-ignore
            filter['props']['UserViews-containsNot'] = this.rootStore?.mainStore.user.id;
        }
        return filter;
    }

    @action
    setError = (error: any) => {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            error.response.data.message !== undefined
        ) {
            this.error = String(error.response.data.message);
        }
    }

    private static setSort() {
        return {
            orderModel: '',
            orderField: 'id',
            orderBy: 'DESC',
        }
    }

    @action setOpen = (item: any) => {
        item.isOpen = !item.isOpen;
        if (!item.isRead) {
            this.setRead(item);
        }
    }

    @action setRead(item: any) {
        item.isRead = true;
        NewsModel.setView(item.id, (res: any) => {
            this.setCountShowTop(this.arItems);
        }, this.setError);
    }

    @action allRead = () => {
        this.arItems.map((item: any) => {
            if (!item.isRead) {
                NewsModel.setView(item.id, (res: any) => {
                    this.setCountShowTop(this.arItems);
                }, this.setError);
            }
            item['isRead'] = true;
            return item;
        });
    }

    @action filterNoRead = () => {
        this.showNotView = !this.showNotView;
        this.init();
    }

    @action
    setCountShowTop = (value: any) => {
        this.countShowTop = 0
        for (let i =0; i< value.length; i++){
            if (!value[i].isRead && value[i].props.Sites[0] === 4){
                this.countShowTop +=1
            }
        }
    }

    onCurrentPage = (page: number) => {
        this.pagination.currentPage = page;
        this.reload();
    }

}
