import React from 'react';
import {RenderFooterMenu} from "./RenderFooterMenu";

interface Props {
    store: any;
    menu: [];
    url: string
}

export class FooterMenu  extends React.Component<Props, {}> {
    render() {
        const store = this.props.store.mainStore;
        let menu = this.props.menu;
        let url = this.props.url;
        let isAuth = store.isAuth;

        return (
            <>
                <RenderFooterMenu store={store}  menu={menu} url={url}  type='company'      title='Компания' />
                <RenderFooterMenu store={store}  menu={menu} url={url}  type='service'      title='Сервис' />
                {isAuth ?
                    <RenderFooterMenu store={store}  menu={menu} url={url}  type='information'  title='Информация' />
                    :
                    <RenderFooterMenu store={store}  menu={menu} url={url}  type='policy'  title='Информация' />
                }

            </>
        );
    }
}


