import {MainStore} from './MainStore';
import {UserStore} from './UserStore';
import {AuthStore} from './AuthStore';
import {AppStore} from './AppStore';
import {PageCatalogStore} from './page/PageCatalogStore';
import {PageProductStore} from './page/PageProductStore';
import {PageMainStore} from "./page/PageMainStore";
import {PageCooperationStore} from "./page/PageCooperationStore";
import {ComponentFilterCatalogStore} from "./component/ComponentFilterCatalogStore";
import {PageChangeStore} from "./page/PageChangeStore";
import {ComTopSearchStore} from "./component/ComTopSearchStore";
import {ComCatalogSectionStore} from "./component/ComCatalogSectionStore";
import {PageBasketStore} from "./page/PageBasketStore";

import {PageOrderDetailStore} from "./page/PageOrderDetailStore";

import {PageDeliveryAddressStore} from "./personal/PageDeliveryAddressStore";
import {PagePersonalStore} from "./personal/PagePersonalStore";
import {PageOrderStore} from "./page/PageOrderStore";
import {ComUrlStore} from "./component/ComUrlStore";
import {PageAdminAuthStore} from "./page/PageAdminAuthStore";
import {PageConfirmStore} from "./page/PageConfirmStore";
import {MainSliderStore} from "./component/MainSliderStore";
import {NewsStore} from "./component/NewsStore";
import {WebPayStore} from "./component/WebPayStore";
import {LoyaltyStore} from "./component/LoyaltyStore";
import {BrandsStore} from "./component/BrandsStore";
import {PalletStore} from "./component/PalletStore";
import {PagePaymentStore} from "./page/PagePaymentStore";
import {PageMassageStore} from "./page/PageMassageStore";




export class RootStore {
    mainStore: MainStore;
    userStore: UserStore;
    authStore: AuthStore;
    appStore: AppStore;
    pageCatalogStore: PageCatalogStore;
    pageProductStore: PageProductStore;
    pageMainStore: PageMainStore;
    pageCooperationStore: PageCooperationStore;
    pageChangeStore: PageChangeStore;
    pageBasketStore: PageBasketStore;
    pageOrderStore: PageOrderStore;
    pageOrderDetailStore: PageOrderDetailStore;
    pagePersonalStore: PagePersonalStore;
    pageConfirmStore: PageConfirmStore;
    mainSliderStore: MainSliderStore;
    newsStore: NewsStore;
    webPayStore: WebPayStore;
    loyaltyStore: LoyaltyStore;
    pagePaymentStore: PagePaymentStore;
    pageMassageStore: PageMassageStore;
    brandsStore: BrandsStore;
    palletStore: PalletStore;



    pageDeliveryAddressStore: PageDeliveryAddressStore;

    componentFilterCatalogStore: ComponentFilterCatalogStore;
    componentCatalogSectionStore: ComCatalogSectionStore;
    componentTopSearchStore: ComTopSearchStore;
    componentUrlStore: ComUrlStore;
    pageAdminAuthStore: PageAdminAuthStore;


    constructor() {
        this.mainStore = new MainStore(this);
        this.userStore = new UserStore(this);
        this.authStore = new AuthStore(this);
        this.appStore = new AppStore(this);
        // PAGE
        this.pageCatalogStore = new PageCatalogStore(this);
        this.pageProductStore = new PageProductStore(this);
        this.pageMainStore = new PageMainStore(this);
        this.pageChangeStore = new PageChangeStore(this);
        this.pageCooperationStore = new PageCooperationStore(this);
        this.pageBasketStore = new PageBasketStore(this);
        this.pageConfirmStore = new PageConfirmStore(this)
        this.pagePaymentStore = new PagePaymentStore(this)
        this.pageMassageStore = new PageMassageStore(this)

        this.pageOrderStore = new PageOrderStore(this);
        this.pageOrderDetailStore = new PageOrderDetailStore(this);
        //component
        this.componentFilterCatalogStore = new ComponentFilterCatalogStore(this);
        this.componentCatalogSectionStore = new ComCatalogSectionStore(this);
        this.componentTopSearchStore = new ComTopSearchStore(this);
        this.componentUrlStore = new ComUrlStore(this);
        this.mainSliderStore= new MainSliderStore(this);
        this.newsStore = new NewsStore(this);
        this.webPayStore = new WebPayStore(this);
        this.loyaltyStore = new LoyaltyStore(this);
        this.brandsStore = new BrandsStore(this);
        this.palletStore = new PalletStore(this);
        //personal
        this.pageDeliveryAddressStore = new PageDeliveryAddressStore(this);
        this.pagePersonalStore = new PagePersonalStore(this);
        //admin
        this.pageAdminAuthStore = new PageAdminAuthStore(this);


        this.inits();
    }

    private inits() {
        // this.mainStore.init();
        // this.pageBasketStore.init();
        this.pageBasketStore.getFaforiteAndBasketCountForIconOnTheTopPage()
    }


}

