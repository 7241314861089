import {Model} from "./";
import {Api} from "../api";

class UserModel extends Model {

    constructor() {
        super(new Api("/users"));
    }

    create(data, thenFunction, errorFunction) {
        this.q.setDopUrl('');
        this.q.create(data, thenFunction, errorFunction);
    }

    getAllTradePoint( thenFunction, errorFunction) {
        this.q.setDopUrl('/trade-point');
        this.q.read({}, thenFunction, errorFunction);
    }

    getAllChildren( thenFunction, errorFunction) {
        this.q.setDopUrl('/children');
        this.q.read({}, thenFunction, errorFunction);
    }

    actionChildrenDelete(ids,thenFunction, errorFunction){
        this.q.setDopUrl('/children');
        this.q.delete({ids:ids},thenFunction, errorFunction);
    }

    actionChildrenBlocked(ids,thenFunction, errorFunction){
        this.q.setDopUrl('/children/blocked');
        this.q.update({ids:ids},thenFunction, errorFunction);
    }

    actionChildrenActivate(ids,thenFunction, errorFunction){
        this.q.setDopUrl('/children/activate');
        this.q.update({ids:ids},thenFunction, errorFunction);
    }

    getInfo(clientId,thenFunction, errorFunction){
        this.q.setDopUrl('/info');
        this.q.read({clientId:clientId}, thenFunction, errorFunction);
    }


    getLinkNewPassword(thenFunction, errorFunction){
        this.q.setDopUrl('/password-link');
        this.q.read({}, thenFunction, errorFunction);
    }

    update(id, ob, thenFunction, errorFunction) {
        this.q.setDopUrl('/update/' + id);
        this.q.update(ob, thenFunction, errorFunction);
    }
    // updateUser(id, ob, thenFunction, errorFunction) {
    //     this.q.setDopUrl('/updateUser/' + id);
    //     this.q.update(ob, thenFunction, errorFunction);
    // }
    getInfoUser(id, thenFunction, errorFunction) {
        this.q.setDopUrl('/user/' + id);
        this.q.read(id, thenFunction, errorFunction);
    }

    getAdminAll(thenFunction, errorFunction) {
        this.q.setDopUrl('/admin-all');
        this.q.read({}, thenFunction, errorFunction);
    }
    getInfoLoyalty(clientId, thenFunction, errorFunction) {
        this.q.setDopUrl('/infoLoyalty');
        this.q.read({clientId: clientId}, thenFunction, errorFunction);
    }

    getInfoAboutLoyaltyTotal(clientId, thenFunction, errorFunction) {
        this.q.setDopUrl('/infoLoyaltyTotal');
        this.q.read({clientId: clientId}, thenFunction, errorFunction);
    }

}



export default new UserModel();
