import {RootStore} from '../index';
import {action, makeAutoObservable} from "mobx"
import Request1cModel from "../../model/Request1cModel";


export class PageConfirmStore {
    private rootStore: RootStore | undefined;
    public email = '';
    public code = '';
    public error: string = '';
    public redirect: boolean = false;
    public redirect404: boolean = false;

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    @action
    init(code: string) {
        this.code = code;
        Request1cModel.getCheckEmail(code, (result: any) => {
            if (result.data.email) {
                this.email = result.data.email;
                setTimeout(()=>{
                    this.redirect=true;
                },2000);
            } else {
                this.redirect404 = true;
            }
        }, this.setError);
        this.redirect = false;
        this.redirect404 = false;
    }

    @action
    setError = (error: any) => {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            error.response.data.message !== undefined
        ) {
            this.error = String(error.response.data.message);
        } else if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            Array.isArray(error.response.data)
        ) {
            let el = {
                error: ''
            };
            error.response.data.map((item: any) => {
                // @ts-ignore
                el = this.getCodeItemInputs(item.code);
                if (el !== undefined) {
                    el.error = item.error;
                }
                return item;
            });
        } else if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            !Array.isArray(error.response.data)
        ) {
            return String(error.response.data);
        }

    }

}