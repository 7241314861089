import {Model} from "./";
import {Api} from "../api";
class ClientModel extends Model {
    constructor() {
        super(new Api("/clients"));
    }
    getClient(id, thenFunction, errorFunction) {
        this.q.setDopUrl('/detail/' + id);
        this.q.read({}, thenFunction, errorFunction);
    }

    getClients( thenFunction, errorFunction){
        this.q.setDopUrl('/my');
        this.q.read({}, thenFunction, errorFunction);
    }

    getInfo(id,thenFunction, errorFunction){
        this.q.setDopUrl('/detail/' + id);
        this.q.read({id: id}, thenFunction, errorFunction);
    }

    updateClientData(id, ob, thenFunction, errorFunction) {
        this.q.setDopUrl('/update/' + id);
        this.q.update(ob, thenFunction, errorFunction);
    }
    getInfoLoyaltylevel(id, thenFunction, errorFunction) {
        this.q.setDopUrl('/detail-loyalty-level/'+ id);
        this.q.read({}, thenFunction, errorFunction);
    }
    getInfoLoyaltyId(id, thenFunction, errorFunction) {
        this.q.setDopUrl('/detail-loyalty-id/'+ id);
        this.q.read({}, thenFunction, errorFunction);
    }
}


export default new ClientModel();
