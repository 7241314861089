import React from 'react';
import {withStore} from "../../../hocs";
import {observer} from "mobx-react";
import {Chip} from "@mui/material";

interface Props {
    store: any,
    itemFilter:[]
    filterActive: any
    onFilterChecked: any
    onChecks: any
    onBrandChecked: any
    type: string
}

// @ts-ignore
@withStore
@observer
export class FilterItem extends React.Component<Props, {}> {

    render() {

        const {itemFilter, filterActive, onFilterChecked, onChecks,   onBrandChecked, type}=this.props;
        // @ts-ignore
        const {name, code}= itemFilter;
        // @ts-ignore
        const enums = itemFilter.enum;

        let new_name = name.replace('BeautyHouse', '');
        if(code === 'seriesLineGamma'){
            new_name = name.replace('Серия', 'Линейка бренда');
        }
        if(code === 'beautyHouseCategory'){
            new_name = name.replace('Категории BeautyHouse', 'Категория');
        }

        // @ts-ignore
        return (
            <>
                {type === "catalog" ? <>
                    {enums.map((e: any, index: any) => (
                        <div key={index} onClick={() => onBrandChecked(e.id, code)} style={{cursor: "pointer"}}>
                            {onChecks.includes(e.id) ? <Chip style={{margin: 5, cursor: "pointer"}} label={e.value} /> : <Chip style={{margin: 5, cursor: "pointer"}} label={e.value} variant="outlined"  />}
                        </div>
                    ))}
                </> :
                    <li className="filter__list-item" key={'filter-'+code}>

                    <button className={filterActive[code] ? 'filter__list-title js--accordion-toggle is-active' : 'filter__list-title js--accordion-toggle'}
                            onClick={() => onFilterChecked(code)}
                            data-filter_but_cpde={code}
                            type="button">{new_name}
                        <svg className="filter__list-icon" width="7" height="12">
                            <use xlinkHref="/images/sprite.svg#icon--arrow-left" />
                        </svg>
                    </button>

                    <div data-filter_cont_cpde={code}
                         className={filterActive[code]  ? 'filter__list-content js--accordion-content is-open' : 'filter__list-content js--accordion-content'}
                    >
                        <ul className="filter__options">
                            {enums.map((e: any, index: any) => (
                                <div key={index}>
                                    <li className="filter__options-item"
                                        onClick={() => onBrandChecked(e.id, code)}
                                        key={e.id}>
                                        <input className="visually-hidden"
                                               type="checkbox"
                                               defaultValue = {e.id}
                                               readOnly
                                               checked={onChecks.includes(e.id)}
                                               onChange={() => {}} // Добавлен пустой обработчик для избежания предупреждения
                                               name={code+'-'+e.id}
                                        />
                                        <label className="filter__options-label checkbox-field"
                                               htmlFor={'opt-'+e.id}>{e.value}
                                        </label>
                                    </li>
                                </div>
                            ))}
                        </ul>
                    </div>
                </li>}

            </>
        );
    }
}
