import React from 'react';
// @ts-ignore
import Slider from "react-slick";
import {APP_NO_IMG} from "../../const/root";
import {withStore} from "../../hocs";
import {observer} from "mobx-react";
import {WIDTH} from "../../const/root";



interface Props {
    onPageMainClickSlider: any;
}

// @ts-ignore
@withStore
@observer
export class MainSlider extends React.Component<Props, {}>{

    slider1: Slider | undefined;
    slider2: Slider | undefined;

    state = {
        photoIndex: 0,
        isOpen: false,
        nav1: undefined,
        nav2: undefined
    };

    constructor(props: any) {
        super(props);
        this.state = {
            photoIndex: 0,
            isOpen: false,
            nav1: undefined,
            nav2: undefined
        };
    }

    componentDidMount() {

        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
        // @ts-ignore
        const {store} = this.props;
        store?.mainSliderStore.init()

    }

    // @ts-ignore
    addDefaultSrc = ev => {
        ev.target.src = APP_NO_IMG;
    }


    render() {
        // @ts-ignore
        const {store} = this.props;
        const {rowsMainSliderImage} = store.mainSliderStore;



        let slickPrev =()=> this.slider1?.slickPrev()
        let slickNext =()=> this.slider1?.slickNext()
        function changeBackground(e: any) {
            e.target.style.transition = '0.5s ease';
            e.target.style.filter = 'brightness(70%)';
        }
        function changeBackground2(e: any) {
            e.target.style.opacity = '1';
            e.target.style.transition = '0.5s ease';
            e.target.style.filter = 'brightness(100%)';
        }
        let ScreenWidth = window.screen.width;
        let settings = {
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            vertical: true,
            verticalSwiping: true,
            swipeToSlide: true,
        };

        let setting1 = {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
            fade: false,
        }

         console.log(rowsMainSliderImage)
        return (
            <>
                <section className="welcome">
                    <h2 className="visually-hidden">Слайдер</h2>

                        <div style={{height: 'auto'}} className="welcome__main-slider ">
                            <Slider
                                {...setting1}
                                asNavFor={this.state.nav2}
                                className="swiper-wrapper"
                                // @ts-ignore
                                ref={slider => (this.slider1 = slider)}
                            >
                                {rowsMainSliderImage && rowsMainSliderImage.length  ? rowsMainSliderImage.map((e:any, index: number) =>
                                    <div  className="swiper-slide welcome__item welcome-item" key={index}>
                                        <a href={e.preview_text}>
                                            <div>
                                                <img style={{paddingBottom: '50px'}} onMouseOver={changeBackground} onMouseLeave={changeBackground2} alt={e.name} src={e.detail_img}  />
                                            </div>
                                        </a>
                                    </div>
                                ): null}
                            </Slider>
                        </div>


                    <div className="welcome__slider  welcome__slider_my" >
                            {ScreenWidth < WIDTH ?
                                <Slider
                                    asNavFor={this.state.nav2}
                                    className="swiper-wrapper"
                                    // @ts-ignore
                                    ref={slider => (this.slider2 = slider)}
                                    slidesToShow={1}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                >

                                    {rowsMainSliderImage && rowsMainSliderImage.length  ? rowsMainSliderImage.map((e:any, index: number) =>
                                        <div  className="swiper-slide welcome__item welcome-item" key={index}>

                                            <a href={e.preview_text}>
                                                <div style={{width: '100%', paddingLeft: '3%', paddingRight: '3%'}}>
                                                    <img onMouseOver={changeBackground} onMouseLeave={changeBackground2} alt={e.name} src={e.preview_img}  />
                                                </div>
                                            </a>

                                        </div>
                                    ): null}

                                </Slider>
                            :
                                <Slider
                                    {...settings}
                                    className="swiper-wrapper"
                                    asNavFor={this.state.nav1}
                                    // @ts-ignore
                                    ref={slider => (this.slider2 = slider)}
                                    slidesToShow={2}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                >
                                    { rowsMainSliderImage && rowsMainSliderImage.length  ? rowsMainSliderImage.map((e:any, index: number) =>
                                        <div  className="swiper-slide welcome__item welcome-item" key={index}>
                                            <a href={e.preview_text} >
                                                <div style={{width: '100%'}} >
                                                    <img style={{paddingBottom: '46px'}} onMouseOver={changeBackground} onMouseLeave={changeBackground2} alt={e.name} src={e.detail_img}/>
                                                </div>
                                            </a>

                                        </div>
                                    ): null}

                                </Slider>
                            }
                    </div>
                    <div className="welcome__controls">
                        <div onClick={()=>slickPrev()}
                             className="navigation-control welcome__control">
                            <span className="visually-hidden">Назад</span>
                            <svg className="navigation-control__icon" width="7" height="12">
                                <use xlinkHref="images/sprite.svg#icon--arrow-left" />
                            </svg>
                        </div>
                        <div onClick={()=>slickNext()}
                             className="navigation-control welcome__control">
                            <span className="visually-hidden">Вперед</span>
                            <svg className="navigation-control__icon" width="7" height="12">
                                <use xlinkHref="images/sprite.svg#icon--arrow-right" />
                            </svg>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
