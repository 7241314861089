import {Model} from "./";
import {Api} from "../api";

class ContentModel extends Model {

    constructor() {
        super(new Api("/content"));
    }

    getBrandsFromAdmin(thenFunction, errorFunction) {
        this.q.setDopUrl('/brands');
        this.q.read({}, thenFunction, errorFunction);
    }

    getDetailBrands(id, thenFunction, errorFunction) {
        this.q.setDopUrl('/detail-brand/'+id);
        this.q.read({}, thenFunction, errorFunction);
    }



}

export default new ContentModel();
