import React from 'react';
import {observer} from "mobx-react";
import {withStore} from "../../hocs";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {WIDTH} from "../../const/root";

// @ts-ignore
@withStore
@observer
export class SearchTopForm extends React.Component {

    componentDidMount() {
        // @ts-ignore
        const {store} = this.props;
        // store?.componentCatalogSectionStore.init();

    }

    // @ts-ignore
    addDefaultSrc = ev => {
        ev.target.src = '/img/no-image.webp'
    }

    render() {

        // @ts-ignore
        const {store} = this.props;

        const {onChangeInput,onClickSearchButton, onClickSearchButton2, clearInputField, onClearAndRemoveSearchInput} = store?.pageCatalogStore;
        const {search} = store?.pageCatalogStore;
        let Width = window.innerWidth
        return (
            <>
                <Paper
                    elevation={0}
                    style={{ borderRadius: '20px', background: "#f0f2f6", margin: "auto" , }}
                    sx={Width < WIDTH ?{ p: '0px 4px', display: 'flex',  width: 320 } : { p: '0px 4px', display: 'flex',  width: 250 }}
                >
                    <IconButton onClick={onClickSearchButton2}  sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon style={{color: '#b17882'}}/>
                    </IconButton>
                    <InputBase
                        onChange={(e) => onChangeInput(e)} name="q" id="search__field" value={search}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Поиск"
                        style={{fontStyle: 'italic', color: "#000000", }}
                        autoComplete= "off"
                        // onKeyUp={onClickSearchButton}
                    />

                    <IconButton onClick={onClearAndRemoveSearchInput} sx={{ p: '10px' }} aria-label="delete">
                        <ClearIcon style={{color: '#b17882'}} />
                    </IconButton>
                </Paper>
            </>
        );
    }
}

