import React from 'react';
import {AnyQuestions} from "../components/block/AnyQuestions";
import {withRouter} from "react-router";
import {withStore} from "../hocs";
import {observer} from "mobx-react";
import {Paper} from "@mui/material";
import {WIDTH} from "../const/root";
import ReactGA from "react-ga";
import {YOUR_TRACKING_ID} from "../stores/AppStore";

// @ts-ignore
@withStore
@observer
class NewsMainDetailPage extends React.Component {
    setGA = () => {
        ReactGA.initialize(YOUR_TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
    };
    componentDidMount() {
        // @ts-ignore
        const {store} = this.props;
        // @ts-ignore
        const code = this.props.match.params.code;
        store?.newsStore.init(true, code)
        this.setGA();
    }

    // @ts-ignore
    addDefaultSrc = ev => {
        ev.target.src = '/images/pic--goods-0.jpg'
    }
    render() {

        // @ts-ignore
        const {store} = this.props;
        const {rowsDetail} = store.newsStore;
        let Width = window.innerWidth;

        return (
            <>
                <main className="page__main">
                    <div className="container">
                        <ul className="breadcrumbs">
                            <li className="breadcrumbs__item"><a className="breadcrumbs__link" href="/">Главная</a></li>
                            <li className="breadcrumbs__item"><a className="breadcrumbs__link" href="/news"> Новости</a></li>
                            <li className="breadcrumbs__item"> Подробнее</li>
                        </ul>
                        {rowsDetail ? <>
                            <h1 className="page__title">{rowsDetail.detail_text}</h1>
                            <div className="sale">
                                {Width > WIDTH ?   <iframe id="pdf-js-viewer"
                                                          title={rowsDetail.detail_text}
                                                          style={{width: "100%", height: 700}}
                                                          loading="lazy"
                                                          src={rowsDetail.file}>
                                    Ваш браузер не поддерживает pdf формат
                                </iframe> : <>
                                {rowsDetail.file_mobile ? rowsDetail.file_mobile.map((img: any)=>
                                    <Paper elevation={3}>
                                        <img style={rowsDetail.location_img === 'V' ? {height: 650, marginBottom: 15 }: {height: 250, marginBottom: 15}}  className="sale-item__thumbnail"
                                             src={img} alt='' />
                                    </Paper>

                                ): null}
                                </>}
                            </div>
                        </> : null}
                    </div>
                    <AnyQuestions />
                </main>
            </>
        )
    }
}

// @ts-ignore
export default withRouter(NewsMainDetailPage);